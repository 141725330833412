import firebase from "firebase";
import { message } from "antd";
import { AppDispatch } from "../redux/store";
import Cookies from "js-cookie";
import { getUser, setLoginLoading } from "../redux/actions/usersActions";
import { acceptEmployeeInvite } from "../redux/actions/employeeActions";
import {
  sendConsignerVerificationEmail,
  updateConsignerPassword,
} from "../redux/actions/consignerActions";
//interfaces
export interface Auth {
  login(email: string, password: string): void;
  signup(email: string, password: string): Promise<any>;
  logout(): void;
  onAuthStateChanged(
    user: firebase.User | null,
    setUserAuthState: (newLoginState: boolean) => void
  ): void;
  resetPassword(email: string): void;
  sendEmailVerfication(dispatch: AppDispatch): void;
  updateUserPassword(
    dispatch: AppDispatch,
    email: string,
    password: string
  ): Promise<void>;
}

const AuthService = (): Auth => {
  const login = async (email: string, password: string) => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (e) {
      message.error(String(e));
      console.log(e);
    }
  };

  const signup = async (email: string, password: string): Promise<boolean> => {
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      return true; // Signup was successful
    } catch (e) {
      message.error(String(e));
      console.log(e);
      return false; // Signup failed
    }
  };

  const logout = async () => {
    try {
      await firebase.auth().signOut();
      Cookies.remove("loadedUser");
      window.location.reload();
    } catch (e) {
      message.error(String(e));
      console.log(e);
    }
  };

  const resetPassword = async (email: string) => {
    try {
      await firebase.auth().sendPasswordResetEmail(email);
      message.success(`Email instructions sent to ${email}`);
    } catch (e) {
      message.error(String(e));
      console.log(e);
    }
  };

  const sendEmailVerfication = async (dispatch: AppDispatch) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser) {
        dispatch(await sendConsignerVerificationEmail(currentUser.email || ""));
        message.success(`Email instructions sent to ${currentUser.email}`);
      }
    } catch (e) {
      message.error(String(e));
      console.log(e);
    }
  };

  const updateUserPassword = async (
    dispatch: AppDispatch,
    email: string,
    password: string
  ) => {
    try {
      await dispatch(updateConsignerPassword(email, password));
      message.success("Password has been updated");
    } catch (e: any) {
      message.error(e.message);
    }
  };

  const onAuthStateChanged = async (
    user: firebase.User | null,
    dispatch: AppDispatch
  ) => {
    if (user) {
      const employeeSignupId = Cookies.get("employeeSignupId");
      if (employeeSignupId) {
        await dispatch(await acceptEmployeeInvite(employeeSignupId, user.uid));
        Cookies.remove("employeeSignupId");
        console.log("=== first ====");

        dispatch(await getUser(user));
      } else {
        console.log("=== second ====");
        dispatch(await getUser(user));
      }
    } else {
      dispatch(setLoginLoading(false));
    }
  };

  return {
    login,
    signup,
    onAuthStateChanged,
    logout,
    resetPassword,
    sendEmailVerfication,
    updateUserPassword,
  };
};

export default AuthService;
