import { Form, Input as AntdInput, Typography, Select } from "antd";
import React from "react";

interface InputProps {
  placeholder?: string;
  titleStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  titleLevel?: 1 | 2 | 3 | 4 | 5;
  title?: string;
  name?: string;
  rules?: Array<any>;
  password?: boolean;
  addonBefore?: boolean;
  onChange?: (value: any) => void;
}
const { Title } = Typography;
const { Option } = Select;

export default function InputField({
  placeholder,
  titleLevel,
  titleStyle,
  inputStyle,
  title,
  name,
  rules,
  password,
  addonBefore,
  onChange,
  ...rest
}: InputProps & { [key: string]: any }) {
  // Accept all additional props
  const InputComponent = password ? AntdInput.Password : AntdInput;
  const prefixSelector = (
    <Select defaultValue="+1" style={{ width: "64px" }}>
      <Option value="1">+1</Option>
      <Option value="86">+86</Option>
      <Option value="87">+87</Option>
    </Select>
  );
  return (
    <>
      {title && (
        <Title
          level={titleLevel}
          style={{ ...titleStyle, color: "#333333", height: "18px" }}
        >
          {title}
        </Title>
      )}
      <Form.Item name={name} rules={rules} {...rest}>
        <InputComponent
          addonBefore={addonBefore && prefixSelector}
          placeholder={placeholder}
          style={{ ...inputStyle, borderRadius: "8px" }}
          onChange={onChange}
        />
      </Form.Item>
    </>
  );
}
