import styled from "styled-components";
import { Button, Select, Modal } from "antd";
interface ModalButtonDivProps {
  centerContent?: boolean;
}

const Box = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.2);
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
  z-index: 9999;
`;
const BoxButton = styled.a`
  background-color: #685ff9;
  color: white;
  padding: 1rem;
  border-radius: 8px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
  z-index: 9999;
`;

const OverlayBox = styled.div`
  position: fixed;
  bottom: 6rem;
  right: 2.1rem;
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 999;

  &:target {
    visibility: visible;
    opacity: 1;
  }

  .overlay {
    margin-left: 2rem;
  }
`;

const PopDiv = styled.div`
  position: absolute;
  bottom: 0;
  padding: 20px;
  width: 385px;
  background: #fff;
  border-radius: 5px;
  height: 40%;
  position: relative;
  transition: all 5s ease-in-out;
  z-index: 99999;
`;

const PopupClose = styled.a`
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;

  &:hover {
    color: #06d85f;
  }
`;

const PopupContent = styled.div`
  max-height: 30%;
  overflow: auto;
  color: #989898;
  font-family: "Inter", sans-serif;
  font-size: 14px;
`;

const BoxContentP = styled.h3`
  padding-top: 1rem;
  font-size: 20px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  color: #2e2e2e;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
`;

const PercentDiv = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
`;
const StepsDiv = styled.div`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
`;
const SelectDiv = styled.div`
  background: rgba(221, 221, 221, 0.6);
  height: auto;
  padding: 1rem 1rem 4.5rem 1rem;
`;
const CustomCard = styled.div`
  border-radius: 8px;
  width: 337px;
  height: 48px;
  background: white;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  padding-left: 0.5rem;
`;
const CustomButton = styled(Button)`
  border-radius: 8px;
  width: 126px;
  height: 48px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 18px;
  background-color: #a49ffb;
  color: white;
  float: right;
  margin-top: 1rem;
`;
const RadiusDiv = styled.span`
  width: 37px;
  height: 21px;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, 0.1);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding: 0.5rem;
  margin: 0px 10px;
`;

const StyledSelect = styled(Select)`
  && {
    width: 614px;
    border-radius: 8px;

    .ant-select-selector {
      height: 50px;
      border-radius: 10px;
      display: flex;
      align-items: center;
    }
    .ant-select-selection-item {
      line-height: 50px;
    }
  }
`;

const StyledModalTitle = styled.span`
  font-family: "Inter", sans-serif;
  font-size: 16px;
  color: #989898;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 1rem;
`;
const CircleSpan = styled.span`
  width: 80px;
  height: 31px;
  border-radius: 22px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  color: #989898;
  font-weight: 500;
`;

const ModalMainTitle = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 24px;
`;
const ModalSideTtle = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #989898;
`;
const ModalButtonDiv = styled.div<ModalButtonDivProps>`
  display: flex;
  justify-content: ${({ centerContent }) =>
    centerContent ? "center" : "space-between"};
  margin-top: 2rem;
`;

const FooterButtonFirst = styled(Button)`
  width: 135px;
  height: 52px;
  border-radius: 8px;
  background-color: #685ff9;
  color: white;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  cursor: pointer;
`;

const FooterSettingsButtonFirst = styled(Button)`
  width: 152px;
  height: 52px;
  border-radius: 8px;
  background-color: #685ff9;
  color: white;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  cursor: pointer;
`;
const FooterButtonSecond = styled(Button)`
  width: 112px;
  height: 52px;
  border-radius: 8px;
  background-color: #f4f4f4;
  color: #747474;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
  }

  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }

  .ant-modal-footer {
    border-radius: 0 0 8px 8px;
  }
`;
export {
  StyledModal,
  FooterButtonSecond,
  FooterButtonFirst,
  ModalButtonDiv,
  ModalSideTtle,
  ModalMainTitle,
  CircleSpan,
  StyledModalTitle,
  Box,
  BoxButton,
  OverlayBox,
  PopDiv,
  PopupClose,
  PopupContent,
  BoxContentP,
  FlexDiv,
  PercentDiv,
  SelectDiv,
  StepsDiv,
  CustomCard,
  CustomButton,
  RadiusDiv,
  StyledSelect,
  FooterSettingsButtonFirst,
};
