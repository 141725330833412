import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { UserState } from "../redux/reducers/UserReducer";
import { useAppSelector } from "../redux/hooks";
import {
  createMembershipPaymentFromAPI,
  getConsignerType,
  getConsignerTypeById,
} from "../redux/actions/consignerTypeActions";
import { Button, Result } from "antd";
import { useDispatch } from "react-redux";
import { updateConsigner } from "../redux/actions/consignerActions";

const MembershipPaymentSuccess = () => {
  const { id, status }: any = useParams();
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { consignerType, consignerTypeLoading } = useAppSelector(
    (state) => state.ConsignerTypeReducer
  );
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getConsignerTypeById(Number(id)));
  }, []);

  useEffect(() => {
    if (consignerType) createMembershipPayment();
  }, [consignerType]);
  const createMembershipPayment = async () => {
    console.log(id, "id-----------------------X");
    console.log(status, "status-----------------------X");
    if (status === "success") {
      const consigner = {
        ...dbUser,
        consignerTypeId: Number(id),
        accountType: consignerType.accountType,
      };
      dispatch(updateConsigner(dbUser?.id, consigner, true));
    }
    const body = {
      consignerId: dbUser?.id,
      status,
      consignerTypeId: Number(id),
    };
    const response = await createMembershipPaymentFromAPI(body);
  };
  return (
    <div>
      <Result
        status={status === "success" ? "success" : "error"}
        title={
          status === "success"
            ? "Successfully purchased the membership!"
            : "Failed to purchase the consiner membership!"
        }
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => history.push("/")}
          >
            Go Console
          </Button>,
        ]}
      />
    </div>
  );
};

export default MembershipPaymentSuccess;
