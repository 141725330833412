import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom";
import { AppState } from "../redux/reducers/AppReducer";
import { InventoryState } from "../redux/reducers/InventoryReducer";
import { StoreState } from "../redux/reducers/StoreReducer";
import { UserState } from "../redux/reducers/UserReducer";
import { PageContainer } from "./ConsignerInventory";
import { getPayoutFilterOptions } from "../redux/actions/payoutActions";
import { Payout } from "../redux/reducers/PayoutReducer";
import { Order } from "../redux/reducers/OrderReducer";
import {
  DatePicker,
  Spin,
  Table,
  Image,
  Statistic,
  Button,
  Input,
  message,
  Tooltip,
  Popconfirm,
} from "antd";
const { RangePicker } = DatePicker;
import moment from "moment";
import { ColumnsType } from "antd/lib/table";
import getSymbolFromCurrency from "currency-symbol-map";
import {
  getPayoutInventories,
  getSupplyInventories,
  updateInventory,
} from "../redux/actions/inventoryActions";
import _ from "lodash";
import {
  ConsignerTable,
  CustomSpan,
  DetailsPanel,
  Listing,
  MobilePayout,
  ReviewSpan,
  StatusSelectorButton,
  StatusSelectorDiv,
} from "../styles/ConsignerPayout";
import Colors from "../constants/Colors";
import styled from "styled-components";
import { TrackingCodeModal } from "../styles/ConsignerInventory";

const PrintManifest = styled.div`
  .printable {
    display: none;
  }

  @media print {
    @page {
      size: landscape;
    }
    * {
      visibility: hidden;
    }

    .printable {
      display: block;
    }

    .printable .ant-table-content,
    .printable .ant-table-content * {
      visibility: visible;
    }
  }
`;

const goToPayoutPage = (
  history: RouteComponentProps["history"],
  payout: Order | Payout
) => {
  history.push(`/payouts/${payout.id}`);
};

type EditableTableProps = Parameters<typeof Table>[0];

type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const visibleColumns: ColumnTypes[number][] = [
  {
    title: "ID",
    key: "id",
    dataIndex: "id",
    render: (_: any, record: any) => record.code,
  },
  {
    title: "Product",
    key: "productImage",
    dataIndex: "product.image",
    render: (_: any, record: any) => (
      <Image
        src={record.product.image}
        alt=""
        style={{ width: 100, height: "auto" }}
      ></Image>
    ),
  },
  {
    title: "Name",
    key: "productName",
    dataIndex: "product.title",
    render: (_: any, record: any) => (
      <span>
        {record.product.title}
        <br />
        {record.code}
      </span>
    ),
  },
  {
    title: "SKU",
    key: "sku",
    dataIndex: "product.sku",
    render: (_: any, record: any) => skuController(record.product.sku),
  },
  {
    title: "Location",
    key: "option3Value",
    dataIndex: "option3Value",
    render: (_: any, record: any) => record.location || record?.option3Value,
  },
  {
    title: "Size",
    key: "option1Value",
    dataIndex: "option1Value",
    render: (_: any, record: any) => record?.option1Value ?? null,
  },
  {
    title: "Condition",
    key: "option2Value",
    dataIndex: "option2Value",
    render: (_: any, record: any) => record?.option2Value ?? null,
  },
  {
    title: "Active Date",
    key: "acceptedOn",
    dataIndex: "acceptedOn",
    render: (_: any, record: any) =>
      moment(record.acceptedOn ?? record.updatedAt).format("YY/MM/DD"),
  },
  {
    title: "Price",
    key: "price",
    dataIndex: "price",
    render: (_: any, record: any) =>
      `${getSymbolFromCurrency(record.currency)}${record.price}`,
  },
  {
    title: "Payout",
    key: "total",
    dataIndex: "total",
    render: (_: any, record: any) =>
      `${getSymbolFromCurrency(record.currency)}${Number(
        record.payoutAmount
      ).toFixed(2)}`,
  },
];

const skuController = (sku: any) => {
  if (sku.length > 10) {
    return sku.slice(0, 10) + "...";
  } else {
    return sku;
  }
};

export const ExpandedRowRenderForPayout = (
  record,
  store,
  cols = visibleColumns
) => {
  const { inventories } = record;
  const dataSource = inventories.map((inventory) => ({
    ...inventory,
    currency: store.currency,
    checkId: record.name,
  }));

  return (
    <Table
      dataSource={dataSource}
      columns={cols as ColumnTypes}
      scroll={{ x: "max-content" }}
      // rowKey="id"
      showHeader={false} //process.env.REACT_APP_TYPE === "employee"
      pagination={false}
    />
  );
};

export const PayoutListingView = (allProducts, store, loading) => {
  if (loading) return <Spin />;
  return (
    <MobilePayout>
      {allProducts?.map((record) => (
        <Listing key={record.id}>
          <Image src={record?.product?.image}></Image>
          <DetailsPanel>
            <ReviewSpan>
              <b>{record?.product?.title}</b>
            </ReviewSpan>
            <ReviewSpan>
              <div className="product-features">
                <Statistic
                  title="SKU"
                  value={skuController(record?.product?.sku)}
                />
                <Statistic title="Size" value={record.option1Value} />
                <Statistic
                  title="Location"
                  value={record.location || record?.option3Value}
                />
                <Statistic
                  title="Price"
                  value={
                    getSymbolFromCurrency(store?.currency) + " " + record?.price
                  }
                />
                <Statistic
                  title="Payout"
                  value={
                    getSymbolFromCurrency(store?.currency) +
                    " " +
                    record?.payoutAmount
                  }
                />
                <Statistic
                  title="Date Sold"
                  value={moment(record.soldOn).format("LLL") || ""}
                />
              </div>
            </ReviewSpan>
          </DetailsPanel>
        </Listing>
      ))}
    </MobilePayout>
  );
};

export const AddTrackingBtn = (props: any) => {
  const { record } = props;
  const [trackingCodePopup, setTrackingCodePopup] = useState(false);
  const [trackingCode, setTrackingCode] = useState("");
  const AddTrackingBtn = styled.a`
    text-decoration: underline;
    color: rgba(104, 95, 249, 1);
  `;

  const inventories = record.inventories.map((inv) => ({
    ...inv,
    trackingCode,
  }));

  const dispatch = useAppDispatch();

  const onUpdate = async () => {
    inventories.map(async (inventory) =>
      dispatch(await updateInventory(inventory.id, inventory))
    );
    message.success("Tracking Code Added Successfully");
    setTrackingCodePopup(false);
    // dispatch(await getInventory(id));
  };

  return (
    <>
      {!record.trackingCode ? (
        <>
          <TrackingCodeModal
            visible={trackingCodePopup}
            title="Add Tracking Code"
            width={400}
            okText="Save"
            onCancel={() => setTrackingCodePopup(false)}
            onOk={onUpdate}
            style={{ borderRadius: "10px" }}
          >
            <p>Enter tracking code</p>
            <Input onChange={(e) => setTrackingCode(e.target.value)} />
          </TrackingCodeModal>
          <AddTrackingBtn onClick={() => setTrackingCodePopup(true)}>
            ADD TRACKING
          </AddTrackingBtn>
        </>
      ) : (
        <AddTrackingBtn style={{ paddingRight: "10px" }}>TRACK</AddTrackingBtn>
      )}
    </>
  );
};

const PrintComponent = (props: any): JSX.Element => {
  const { inventories } = props;
  const cols = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      render: (_: any, record: any) => `${record.code}`,
    },
    {
      title: "PRODUCT",
      key: "productImage",
      dataIndex: "product.image",
      render: (_: any, record: any) => (
        <Image
          src={record.product.image}
          alt=""
          style={{ width: 100, height: "auto" }}
        ></Image>
      ),
    },
    {
      title: "NAME",
      key: "productName",
      dataIndex: "product.title",
      render: (_: any, record: any) => record.product.title,
    },
    {
      title: "SKU",
      key: "sku",
      dataIndex: "product.sku",
      render: (_: any, record: any) => (
        <span style={{ fontSize: 11 }}>{record.product.sku}</span>
      ),
    },
    {
      title: "LOCATION",
      key: "option3Value",
      dataIndex: "option3Value",
      render: (_: any, record: any) => record?.option3Value ?? null,
    },
    {
      title: "SIZE",
      key: "option1Value",
      dataIndex: "option1Value",
      render: (_: any, record: any) => record?.option1Value ?? null,
    },
    {
      title: "CONDITION",
      key: "option2Value",
      dataIndex: "option2Value",
      render: (_: any, record: any) => record?.option2Value ?? null,
    },
    {
      title: "PRICE",
      key: "price",
      dataIndex: "price",
      render: (_: any, record: any) =>
        `${getSymbolFromCurrency(record.currency)}${record.price}`,
    },
    {
      title: "PAYOUT",
      key: "total",
      dataIndex: "total",
      render: (_: any, record: any) =>
        `${getSymbolFromCurrency(record.currency)}${Number(
          record.payoutAmount
        ).toFixed(2)}`,
    },
  ];
  return (
    <Table
      className="printable"
      columns={cols}
      dataSource={inventories}
      rowKey="id"
      pagination={false}
    />
  );
};

export const StatusSelector = (props: any) => {
  const { filters, setFilters, payoutFilterOptions, isMobile } = props;
  const { statuses } = payoutFilterOptions;
  const consignerFilterStatuses = statuses
    .filter(
      (payoutFilter) =>
        payoutFilter.value !== "Failed" &&
        payoutFilter.value !== "Pending" &&
        payoutFilter.value !== "Paid"
    )
    .map((payoutFilter) => {
      if (payoutFilter.value === "Buying") {
        return {
          ...payoutFilter,
          value: "Ready to Pay",
          label: "Order",
        };
      }
      return payoutFilter;
    });
  consignerFilterStatuses.push({ value: "Completed", label: "Completed" });
  const [status, setStatus] = useState(filters.status);

  useEffect(() => {
    setFilters({ ...filters, status });
  }, [status]);

  return (
    <StatusSelectorDiv>
      {consignerFilterStatuses.map((stat, key) => (
        <StatusSelectorButton
          key={key}
          type={status.includes(stat.value) ? "primary" : "ghost"}
          onClick={() => setStatus(stat.value)}
        >
          {stat.label}
        </StatusSelectorButton>
      ))}
    </StatusSelectorDiv>
  );
};

const ConsignerOrder = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const [columnSrc, setColumnSrc] = useState<any[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [printInventories, setPrintInventories] = useState<any[]>([]);
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const [search, setSearch] = useState("");
  const [filters, setFilters] = useState({
    printed: "",
    status: "Completed",
    option1Value: "",
    option2Value: "",
    option3Value: "",
    category: "",
    location: "SUPPLY",
    consigner: dbUser && dbUser.id ? dbUser.id : "",
    orderDateRange: undefined,
  });
  const {
    payouts,
    payoutsLoading,
    payoutFilterOptions,
    payoutFilterOptionsLoading,
  } = useAppSelector((state) => state.PayoutReducer);

  const { inventoriesLoading, supplyInventories }: InventoryState =
    useAppSelector((state) => state.InventoryReducer);

  useEffect(() => {
    dispatch(getPayoutFilterOptions());
  }, []);

  const AddTrackingBtn = (props: any) => {
    const { record } = props;
    const [trackingCodePopup, setTrackingCodePopup] = useState(false);
    const [trackingCode, setTrackingCode] = useState("");
    const AddTrackingBtn = styled.a`
      text-decoration: underline;
      color: rgba(104, 95, 249, 1);
    `;

    const inventories = record.inventories.map((inv) => ({
      ...inv,
      trackingCode,
    }));

    const dispatch = useAppDispatch();

    const onUpdate = async () => {
      supplyInventories.map(async (inventory) =>
        dispatch(await updateInventory(inventory.id, inventory))
      );
      reloadInventories();
      message.success("Tracking Code Added Successfully");
      setTrackingCodePopup(false);
      // dispatch(await getInventory(id));
    };

    return (
      <>
        {!record.trackingCode ? (
          <>
            <TrackingCodeModal
              visible={trackingCodePopup}
              title="Add Tracking Code"
              width={400}
              okText="Save"
              onCancel={() => setTrackingCodePopup(false)}
              onOk={onUpdate}
              style={{ borderRadius: "10px" }}
            >
              <p>Enter tracking code</p>
              <Input onChange={(e) => setTrackingCode(e.target.value)} />
            </TrackingCodeModal>
            <AddTrackingBtn onClick={() => setTrackingCodePopup(true)}>
              ADD TRACKING
            </AddTrackingBtn>
          </>
        ) : (
          <Tooltip
            placement="right"
            title={`Tracking Code: ${record.trackingCode}`}
          >
            <AddTrackingBtn style={{ paddingRight: "10px" }}>
              TRACK
            </AddTrackingBtn>
          </Tooltip>
        )}
      </>
    );
  };

  const reloadInventories = () => {
    const inventoryFilters = {
      ...filters,
      status:
        filters.status === "Ready to Pay" ? "Supply Pending" : filters.status,
      // consigner: filters.consigner,
      // orderDateRange: [undefined, undefined],
      payoutsId: null,
    };
    dispatch(getSupplyInventories(search, inventoryFilters));
  };

  useEffect(() => {
    reloadInventories();
  }, [search, filters]);

  const groupColumns = [
    {
      title: "Product",
      dataIndex: "productAddress",
      key: "product.supplyDetails",
      render: (text: string, record: any) => (
        <Statistic
          title="Address"
          value={record?.supplyDetails?.address_line_1}
        />
      ),
    },
    {
      title: "Units",
      dataIndex: "quantity",
      key: "product.quantity",
      render: (text: string, record: any) => (
        <Statistic title="Units" value={record?.quantity} />
      ),
    },
    {
      title: "Revenue",
      dataIndex: "revenue",
      key: "product.revenue",
      render: (text: string, record: any) => (
        <Statistic
          title="Revenue"
          value={getSymbolFromCurrency(store.currency) + record?.revenue}
        />
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: string, record: any) => <AddTrackingBtn record={record} />,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: string, record: any) => (
        <Button
          onClick={() => {
            setTimeout(() => {
              setPrintInventories(record.inventories);
              window.print();
            }, 1000);
          }}
          type="primary"
        >
          Print Manifest
        </Button>
      ),
    },
  ];

  const ExpandedRowRender = (record, inventories) => {
    return (
      <Table
        dataSource={inventories}
        style={{ borderLeft: `4px solid ${Colors.CONSIGNER_BLUE}` }}
        columns={getColumns((payout) => goToPayoutPage(history, inventories))}
        pagination={false}
        showHeader={false}
      />
    );
  };

  useEffect(() => {
    const groupedData = supplyInventories.reduce((groups, item) => {
      const groupId = item.orderId;
      if (!groups[groupId]) {
        groups[groupId] = {
          orderId: groupId,
          key: 0,
          supplyDetails: item.supplyDetails,
          revenue: 0,
          quantity: 0,
          inventories: [],
          trackingCode: item.trackingCode,
        };
      }
      groups[groupId].key++;
      groups[groupId].quantity++;
      groups[groupId].revenue += Number(item.price);
      groups[groupId].inventories.push(item);
      return groups;
    }, {});
    setDataSource(Object.values(groupedData));
    setColumnSrc(groupColumns);
    console.log(groupedData, "groupedData");
  }, [supplyInventories]);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Orders")
  ) {
    return <Redirect to="/" />;
  }
  if (!payoutFilterOptions || payoutFilterOptionsLoading)
    return (
      <PageContainer className="ConsignerPayoutPage">
        <Spin />
      </PageContainer>
    );

  const getColumns = (
    onItemAction: (product: Payout) => void
  ): ColumnsType<any> => [
    {
      title: "Product Image",
      dataIndex: "record?.product?.image",
      key: "image",
      render: (text: string, record: any) => (
        <Image src={record?.product?.image}></Image>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "title",
      render: (text: string, record: any) => (
        <Statistic title="Product Name" value={record.product.title} />
      ),
    },
    {
      title: "SKU",
      dataIndex: "payout.inventories.length",
      render: (_: any, record: any) => (
        <Statistic title="SKU" value={skuController(record.product.sku)} />
      ),
    },
    {
      title: "Size",
      dataIndex: "payout.inventories.length",
      render: (_: any, record: any) => (
        <Statistic title="Size" value={record.option1Value} />
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      render: (_: any, record: any) => (
        <Statistic
          title="Location"
          value={record.location || record.option3Value}
        />
      ),
    },
    {
      title: "Price",
      dataIndex: "Price",
      key: "Price",
      sorter: (a: any, b: any) => a.total - b.total,
      render: (text: string, record: any) => (
        <Statistic
          title="Price"
          value={getSymbolFromCurrency(store?.currency) + record.price}
        />
      ),
    },
    {
      title: "Payout",
      dataIndex: "Payout",
      key: "Payout",
      render: (text: string, record: any) => (
        <Statistic
          title="Payout"
          value={getSymbolFromCurrency(store?.currency) + record.payoutAmount}
        />
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (text: string, record: any) => (
        <Statistic
          title="Date Sold"
          value={moment(record.soldOn).format("LLL") || ""}
        />
      ),
    },
  ];

  return (
    <PageContainer className="ConsignerPayoutPage">
      <CustomSpan>
        <h3>Payout</h3>
      </CustomSpan>
      <StatusSelector
        filters={filters}
        setFilters={setFilters}
        payoutFilterOptions={payoutFilterOptions}
        isMobile={isMobile}
      />
      <>
        {isMobile ? (
          <ConsignerTable
            data-testid="payoutTable"
            dataSource={dataSource}
            columns={columnSrc}
            rowClassName={"bordered-row"}
            showHeader={false}
            rowKey={"orderId"}
            pagination={false}
            loading={payoutsLoading}
            expandable={{
              expandedRowRender: (record: any) =>
                PayoutListingView(
                  record.inventories.map((inventory: any) => ({
                    ...inventory,
                    key: inventory.id,
                  })),
                  store,
                  payoutsLoading
                ),
            }}
          />
        ) : (
          <ConsignerTable
            data-testid="payoutTable"
            dataSource={dataSource}
            columns={columnSrc}
            rowClassName={"bordered-row"}
            rowKey={"orderId"}
            showHeader={false}
            loading={payoutsLoading}
            pagination={false}
            expandable={{
              expandedRowRender: (record: any) =>
                ExpandedRowRender(
                  record,
                  record.inventories.map((inventory: any) => ({
                    ...inventory,
                    key: inventory.id,
                    currency: store.currency,
                  }))
                ),
            }}
          />
        )}
      </>
      <PrintManifest>
        <PrintComponent
          inventories={printInventories?.map((inventory) => ({
            ...inventory,
            currency: store.currency,
          }))}
        />
      </PrintManifest>
    </PageContainer>
  );
};

export default ConsignerOrder;
