/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { Breadcrumb, Button, Image, Input, Spin, Table, message } from "antd";
import { UserState } from "../redux/reducers/UserReducer";
import {
  DownOutlined,
  MinusOutlined,
  PlusOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { ColumnTypes } from "../consignerScreens/ConsignerInventory";
import SupplyStepThree from "./SupplyStepThree";
import {
  Container,
  CustomBreadcrumb,
  ExpendedTable,
  StepTwoTable,
} from "../styles/SupplyStepTwo";
import { getInventories } from "../redux/actions/inventoryActions";
import { useDispatch } from "react-redux";
import { InventoryState } from "../redux/reducers/InventoryReducer";

const { Search } = Input;

const customExpandIcon = ({ expanded, onExpand, record }) =>
  record.items &&
  record.items?.length !== 1 &&
  (expanded ? (
    <DownOutlined onClick={(e) => onExpand(record, e)} />
  ) : (
    <UpOutlined onClick={(e) => onExpand(record, e)} />
  ));

const SupplyStepTwo = () => {
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const [paymentPage, setPaymentPage] = useState(false);
  const [inventoriesQty, setInventoriesQty] = useState<any[]>([]);
  const [totalPrice, setTotalPrice] = useState();
  const [supplyInventories, setSupplyInventories] = useState<any[]>([]);
  const [product, setProduct] = useState<any>({});
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { inventories, inventoriesLoading }: InventoryState = useAppSelector(
    (state) => state.InventoryReducer
  );

  const [filters, setFilters] = useState<any>({
    printed: "",
    status: "Active", // only display Pending inventories
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: "",
    consigner: "",
    sortOption: "",
    location: "SUPPLY",
  });

  const QuantityControl = ({ record }: { record: any }) => {
    const [inventoryCount, setInventoryCount] = useState(0);
    const recordItem = record?.items?.length === 1 ? record.items[0] : record;
    const countMatchingItems = supplyInventories.filter(
      (inv) =>
        inv.price === recordItem.price &&
        inv.option1Value === recordItem.option1Value
    ).length;

    const handleIncrement = () => {
      if (
        supplyInventories.filter(
          (inv) =>
            inv.price === recordItem.price &&
            inv.option1Value === recordItem.option1Value
        ).length < recordItem.quantity
      ) {
        setInventoryCount(inventoryCount + 1);
        // const recordItem =
        //   record?.items?.length === 1 ? record.items[0] : record;
        const supply: any = inventories.find(
          (inven) =>
            inven.price === recordItem.price &&
            inven.option1Value === recordItem.option1Value &&
            !supplyInventories.some((item: any) => item.id === inven.id)
        );

        if (supply) {
          setSupplyInventories((prevSupplyInventories) => {
            return [...prevSupplyInventories, supply];
          });
        }
      }
    };

    const handleDecrement = () => {
      if (
        supplyInventories.filter(
          (inv) =>
            inv.price === recordItem.price &&
            inv.option1Value === recordItem.option1Value
        ).length > 0
      ) {
        setInventoryCount((prevQuantity) => prevQuantity - 1);

        const supply = inventories.find(
          (inven) =>
            inven.price === recordItem.price &&
            inven.option1Value === recordItem.option1Value &&
            supplyInventories.some((item: any) => item.id === inven.id)
        );

        setSupplyInventories((prevSupplyInventories: any) => {
          const index = prevSupplyInventories.findIndex(
            (item) => item.id === supply.id
          );
          if (index > -1) {
            const updatedSupplyInventories = [...prevSupplyInventories];
            updatedSupplyInventories.splice(index, 1);
            return updatedSupplyInventories;
          }
          return prevSupplyInventories;
        });
      }
    };

    return (
      <div className="number">
        {(!record.items || record.items.length === 1) && (
          <span className="minus" onClick={handleDecrement}>
            <MinusOutlined width="12px" style={{ width: "12px" }} />
          </span>
        )}
        <input
          type="text"
          value={countMatchingItems}
          readOnly
          style={{ fontSize: "16px" }}
        />
        {(!record.items || record.items.length === 1) && (
          <span className="plus" onClick={handleIncrement}>
            <PlusOutlined width="12px" style={{ width: "12px" }} />
          </span>
        )}
      </div>
    );
  };

  const visibleColumns: ColumnTypes[number][] = [
    {
      title: "Size",
      key: "option1Value",
      dataIndex: "option1Value",
      render: (_: any, record: any) => (
        <div style={{ display: "flex", gap: "7px" }}>
          {record?.option1Value}
          {record.items && record.items?.length === 1 ? (
            <p className="in-stock">{record?.quantity}</p>
          ) : (
            <>&nbsp;&nbsp;</>
          )}
        </div>
      ),
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      render: (_: any, record: any) =>
        record.items.length > 1
          ? "$" + record.avg_price.toFixed(1) + " Avg."
          : "$" + record.avg_price.toFixed(1),
    },
    {
      title: "Quantity",
      key: "option2Value",
      dataIndex: "option2Value",
      render: (_: any, record: any) => <QuantityControl record={record} />,
    },
  ];

  const visibleExpendedColumns: ColumnTypes[number][] = [
    {
      title: "",
      key: "",
      dataIndex: "",
      render: (_: any, record: any) => null,
    },
    {
      title: "Size",
      key: "option1Value",
      dataIndex: "option1Value",
      render: (_: any, record: any) => (
        <div style={{ display: "flex", gap: "7px" }}>
          {record?.option1Value}
          <p className="in-stock">{record?.quantity}</p>
        </div>
      ),
    },
    {
      title: "Price",
      key: "price",
      dataIndex: "price",
      render: (_: any, record: any) => (
        <>${record.price}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
      ),
    },
    {
      title: "Quantity",
      key: "option2Value",
      dataIndex: "option2Value",
      render: (_: any, record: any) => <QuantityControl record={record} />,
    },
  ];

  const ExpandedRowRender = (record, inventories) => {
    // const expandedDataSrc = inventories.filter(
    //   (inventory) => inventory.productId === record.productId
    // );
    return (
      <ExpendedTable
        dataSource={inventories}
        columns={visibleExpendedColumns as ColumnTypes}
        rowKey="id"
        pagination={{
          pageSize: 10,
          hideOnSinglePage: true,
        }}
        showHeader={false}
      />
    );
  };

  useEffect(() => {
    if (inventories.length) {
      setProduct(inventories[0].product);
      const inventoryMap = inventories.reduce((acc, item) => {
        // Group by option1Value
        const key = item.option1Value;

        if (!acc[key]) {
          acc[key] = {
            option1Value: item.option1Value,
            avg_price: 0,
            quantity: 0,
            items: [],
          };
        }

        // Check if the price already exists within the items array
        const priceIndex = acc[key].items.findIndex(
          (i) => i.price === item.price
        );

        if (priceIndex > -1) {
          acc[key].items[priceIndex].quantity += 1;
        } else {
          acc[key].items.push({ ...item, quantity: 1 });
        }

        // Update the overall quantity
        acc[key].quantity += 1;

        return acc;
      }, {});

      // Calculate the average price for each group
      Object.values(inventoryMap).forEach((group: any) => {
        const totalPrices = group.items.reduce(
          (sum, item) => sum + item.price * item.quantity,
          0
        );
        group.avg_price = totalPrices / group.quantity;
      });

      setInventoriesQty(Object.values(inventoryMap));
    }
  }, [inventories]);

  useEffect(() => {
    const sum = supplyInventories.reduce(
      (acc, item) => acc + Number(item.price),
      0
    );
    setTotalPrice(sum);
  }, [supplyInventories]);

  useEffect(() => {
    dispatch(getInventories("", { ...filters, productId: id }));
  }, [id]);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Employees")
  ) {
    return <Redirect to="/" />;
  }

  return (
    <Container>
      {inventoriesLoading ? (
        <Spin />
      ) : (
        <>
          <div>
            <CustomBreadcrumb separator=">" style={{ marginBottom: "20px" }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item onClick={() => history.push("/supply")}>
                Products
              </Breadcrumb.Item>
              <Breadcrumb.Item>{product.title}</Breadcrumb.Item>
            </CustomBreadcrumb>
          </div>
          {!paymentPage && (
            <div className="product">
              <div className="product-image">
                <Image
                  src={product.image}
                  alt=""
                  style={{ height: "auto" }}
                  preview={false}
                ></Image>
              </div>
              <div className="product-detail">
                <div>
                  <h3>{product.title}</h3>
                  <p>{product.sku}</p>
                  <StepTwoTable
                    dataSource={inventoriesQty}
                    columns={visibleColumns}
                    // footer={() => "this is the footer"}
                    pagination={false}
                    loading={inventoriesLoading}
                    expandable={{
                      expandIcon: customExpandIcon,
                      expandedRowRender: (record: any) =>
                        record.items.length > 1 &&
                        ExpandedRowRender(
                          record,
                          record.items.map((inventory: any) => ({
                            ...inventory,
                            key: inventory.id,
                          }))
                        ),
                      // rowExpandable: (data) => nestedView,
                      // expandRowByClick: true,
                    }}
                    summary={(pageData: any) => {
                      // const total = pageData.reduce(
                      //   (accumulator, currentValue) =>
                      //     accumulator +
                      //     currentValue.quantity * Number(currentValue.price),
                      //   0
                      // );
                      // setTotalPrice(total);
                      // const units = pageData.reduce(
                      //   (accumulator, currentValue) =>
                      //     accumulator + currentValue.quantity,
                      //   0
                      // );
                      return (
                        <>
                          <Table.Summary.Row>
                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                            <Table.Summary.Cell index={1}>
                              Total
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2}>
                              ${totalPrice}
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={3}>
                              {supplyInventories.length}
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                    }}
                  />
                </div>
                <Button
                  className="checkout-btn"
                  onClick={() => {
                    if (!supplyInventories.length) {
                      message.error("Quantity cannot be 0");
                    } else setPaymentPage(true);
                  }}
                >
                  Payment After Drop Off - ${totalPrice}
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      {paymentPage && (
        <SupplyStepThree
          inventory={supplyInventories}
          totalPrice={totalPrice}
        />
      )}
    </Container>
  );
};

export default SupplyStepTwo;
