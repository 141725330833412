import styled from "styled-components";
import Colors from "../constants/Colors";
import { Button, Card, Table } from "antd";

export const CustomSpan = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 16px;
  justify-content: space-between;
  h3 {
    font-size: 24px;
    color: #2e2e2e;
  }
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .ant-picker-range {
      background: transparent;
      width: 225px;
      border: 0;
      color: ${Colors.CONSIGNER_GREY};
    }
    .ant-picker-input > input {
      color: #9ca3af;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 8px;
    span {
      .ant-picker-input > input {
        font-size: 12px;
      }

      .ant-picker-range {
        width: 200px;
      }
    }
  }
`;

export const ReviewListingContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    order: 5;
    padding-bottom: 24px;
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          // padding: 0;
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            padding: 0;
          }
        }
      }
    }
  }
`;

export const Listing = styled.div`
  padding: 8px 12px;
  display: flex;
  border: 1px solid var(--stroke-color, #e4e4e7);
  border-radius: 10px;
  background: #fff;
  align-items: center;
  .ant-image {
    flex: 0 0 85px;
    background: #dddddd66;
    padding: 10px;
    border-radius: 5px;
    img {
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  label {
    flex: 0 0 35px;
  }
  @media (max-width: 768px) {
    flex-wrap: nowrap;
  }
`;

export const ReviewSpan = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: start;
  font-weight: 400;
  position: relative;
  font-size: 11px;
  button {
    border: 0;
    color: ${Colors.CONSIGNER_BLUE};
  }
  b {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
  }
  div {
    color: ${Colors.CONSIGNER_GREY};
    text-transform: uppercase;
    align-self: end;
  }
`;

export const DetailsPanel = styled.div`
  display: flex;
  flex: 5 1 auto;
  padding: 0 0 0 20px;
  flex-direction: column;
`;

export const ConsignerTable = styled(Table)`
  width: 100%;
  padding: 8px 16px;
  order: 5;
  .bordered-row {
    border: 1px solid #e4e4e7;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .ant-spin-dot {
    display: none;
  }
  //   .ant-table .ant-table-thead .ant-table-cell {
  //     background: var(--neutral-100, #f3f4f6);
  //     text-transform: uppercase;
  //     color: #2e2e2e;
  //     padding: 10px 8px;
  //     border: 1px solid #e4e4e7;
  //   }
  .ant-table {
    table {
      table-layout: fixed; /* Ensures equal width for each cell */
      border-collapse: seperate !important;
      border-spacing: 0 15px;
    }
    .ant-table-tbody {
      //   display: flex;
      //   flex-direction: column;
      .ant-table-row {
        border: 1px solid #e4e4e7 !important; /* Apply border to table rows */
        border-radius: 10px;
        margin-bottom: 10px;
      }
      .ant-table-cell {
        border-top: 1px solid #e4e4e7; /* Border at the top of each cell */
        border-bottom: 1px solid #e4e4e7; /* Border at the bottom of each cell */
        padding: 18px; /* Adjust padding as needed */
        box-sizing: border-box; /* Ensure padding is included in cell width */
      }

      .ant-table-cell:first-child {
        border-left: 1px solid #e4e4e7; /* Border at the left of the first cell */
        border-top-left-radius: 10px; /* Border radius at the top left of the first cell */
        border-bottom-left-radius: 10px;
      }

      .ant-table-cell:last-child {
        border-right: 1px solid #e4e4e7; /* Border at the right of the last cell */
        border-top-right-radius: 10px; /* Border radius at the top right of the last cell */
        border-bottom-right-radius: 10px;
      }
      .ant-table-cell {
        .ant-statistic-content {
          font-size: 14px;
          color: black;
          font-weight: 500;
        }
        .ant-statistic-title {
          color: #989898;
          font-zize: 13px;
        }
        .ant-image {
          width: 60px;
          padding: 10px;
          background-color: #f4f4f4;
          border-radius: 8px;
        }
        .ant-table {
          margin: 0;
        }
      }
    }
  }
`;

export const CustomCard = styled(Card)`
  padding: 0;
  .ant-card-head {
    padding: 8px 12px;
    border: 1px solid var(--stroke-color, #e4e4e7);
    background: #fff;
    .ant-card-head-title {
      padding: 0;
    }
  }
  .ant-card-body {
    padding: 0;
    border: 1px solid var(--stroke-color, #e4e4e7);
    background: #fff;
  }
`;

export const MobilePayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  .ant-statistic-content {
    font-size: 12px;
    color: black;
    font-weight: 500;
  }
  .product-features {
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    row-gap: 10px;
    gap: 20px;
  }
  .ant-statistic-title {
    color: #989898;
    font-size: 12px;
  }
`;

export const StatusSelectorDiv = styled.div`
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #e4e4e7;
  margin: 25px 0;
  @media (max-width: 768px) {
    // order: 2;
  }
`;

export const StatusSelectorButton = styled(Button)`
  border: 0;
  &.ant-btn-ghost {
    color: ${Colors.CONSIGNER_GREY};
  }
  &.ant-btn-primary {
    color: ${Colors.CONSIGNER_BLUE};
    border-bottom: 2px solid ${Colors.CONSIGNER_BLUE};
    background: none;
  }
`;
