import firebase from "firebase";
import { NewConsignerFormValues } from "../../consignerScreens/Signup";
import { FormValues } from "../../components/Common/ConsignerForm";
import { ConsignerFormValues } from "../../screens/ConsignerItem";
import { Consigner, ConsignerReport } from "../reducers/ConsignerReducer";
import { FormValues as InventoryFilterValues } from "../../components/Common/InventoryFilter";
import { AppDispatch } from "../store";
import {
  CREATE_CONSIGNER,
  DELETE_CONSIGNER,
  GET_CONSIGNER,
  GET_CONSIGNER_REPORT,
  GET_CONSIGNERS,
  RESET_UPDATED_CONSIGNER,
  SEND_CONSIGNER_VERIFICATION_EMAIL,
  UPDATE_CONSIGNER,
  UPDATE_CONSIGNER_PASSWORD,
} from "./types";
import { message } from "antd";
import { api } from "../../api";

const getConsignersLoading = () => ({
  type: GET_CONSIGNERS,
  consigners: [],
  consignersLoading: true,
});

const getConsignersError = () => ({
  type: GET_CONSIGNERS,
  consigners: [],
  consignersLoading: false,
});

const getConsignersSuccess = (consigners: Consigner[]) => ({
  type: GET_CONSIGNERS,
  consigners,
  consignersLoading: false,
});

const updateConsignerLoading = () => ({
  type: UPDATE_CONSIGNER,
  updatedConsigner: null,
  updateConsignerLoading: true,
});

const updateConsignerError = () => ({
  type: UPDATE_CONSIGNER,
  updatedConsigner: null,
  updateConsignerLoading: false,
});

const updateConsignerSuccess = (updatedConsigner: Consigner) => ({
  type: UPDATE_CONSIGNER,
  updatedConsigner,
  updateConsignerLoading: false,
});
const getConsignerReportLoading = () => ({
  type: GET_CONSIGNER_REPORT,
  consignerReport: {
    inventoriesByAge: [],
    inventoriesByStatus: [],
    inventoriesByCategory: [],
    inventoriesByLocation: [],
    bestSellingProducts: [],
    lowStockProducts: [],
    sales: 0,
    dailySales: [0, 0, 0, 0],
    pendingPayouts: 0,
    dailyPendingPayouts: [0, 0, 0, 0],
    activeListings: 0,
    dailyActiveListings: [0, 0, 0, 0],
    soldListings: 0,
    dailySoldListings: [0, 0, 0, 0],
    salesGrowth: 0,
    pendingPayoutsGrowth: 0,
    activeListingsGrowth: 0,
    soldListingsGrowth: 0,
  },
  consignerReportLoading: true,
});

const getConsignerReportError = () => ({
  type: GET_CONSIGNER_REPORT,
  consignerReport: {
    inventoriesByAge: [],
    inventoriesByStatus: [],
    inventoriesByCategory: [],
    inventoriesByLocation: [],
    bestSellingProducts: [],
    lowStockProducts: [],
    sales: 0,
    dailySales: [0, 0, 0, 0],
    pendingPayouts: 0,
    dailyPendingPayouts: [0, 0, 0, 0],
    activeListings: 0,
    dailyActiveListings: [0, 0, 0, 0],
    soldListings: 0,
    dailySoldListings: [0, 0, 0, 0],
    salesGrowth: 0,
    pendingPayoutsGrowth: 0,
    activeListingsGrowth: 0,
    soldListingsGrowth: 0,
  },
  consignerReportLoading: false,
});

const getConsignerReportSuccess = (consignerReport: ConsignerReport) => ({
  type: GET_CONSIGNER_REPORT,
  consignerReport,
  consignerReportLoading: false,
});

const createConsignerLoading = () => ({
  type: CREATE_CONSIGNER,
  createdConsigner: null,
  createConsignerLoading: true,
});

const createConsignerError = () => ({
  type: CREATE_CONSIGNER,
  createdConsigner: null,
  createConsignerLoading: false,
});

const createConsignerSuccess = (createdConsigner: Consigner) => ({
  type: CREATE_CONSIGNER,
  createdConsigner,
  createConsignerLoading: false,
});

const updateConsignerPasswordLoading = () => ({
  type: UPDATE_CONSIGNER_PASSWORD,
  updateConsignerPasswordLoading: true,
});

const updateConsignerPasswordError = () => ({
  type: UPDATE_CONSIGNER_PASSWORD,
  updateConsignerPasswordLoading: false,
});

const updateConsignerPasswordSuccess = () => ({
  type: UPDATE_CONSIGNER_PASSWORD,
  updateConsignerPasswordLoading: false,
});

const sendConsignerVerificationEmailLoading = () => ({
  type: SEND_CONSIGNER_VERIFICATION_EMAIL,
  sendConsignerVerificationEmailLoading: true,
});

const sendConsignerVerificationEmailError = () => ({
  type: SEND_CONSIGNER_VERIFICATION_EMAIL,
  sendConsignerVerificationEmailLoading: false,
});

const sendConsignerVerificationEmailSuccess = () => ({
  type: SEND_CONSIGNER_VERIFICATION_EMAIL,
  sendConsignerVerificationEmailLoading: false,
});

const getConsignerLoading = () => ({
  type: GET_CONSIGNER,
  consigner: null,
  consignerLoading: true,
});

const getConsignerError = () => ({
  type: GET_CONSIGNER,
  consigner: null,
  consignerLoading: false,
});

const getConsignerSuccess = (consigner: Consigner) => ({
  type: GET_CONSIGNER,
  consigner,
  consignerLoading: false,
});
const deleteConsignerLoading = () => ({
  type: DELETE_CONSIGNER,
  consigner: null,
  deleteConsignerLoading: true,
});
const deleteConsignerSuccess = (consigner: Consigner) => ({
  type: DELETE_CONSIGNER,
  consigner,
  deleteConsignerLoading: false,
});
const deleteConsignerError = () => ({
  type: DELETE_CONSIGNER,
  consigner: null,
  deleteConsignerLoading: false,
});

//api
const getConsignersFromAPI = async (search: string) => {
  const { data, error } = await api.provide("get", "/api/consigners", {
    search,
  });
  if (error) throw error;
  return (data?.consigners || []) as any;
};
const getConsignerFromAPI = async (id: string) => {
  const { data, error } = await api.provide("get", "/api/consigners/:id", {
    id,
    type: "item",
  });
  if (error) throw error;
  return data;
};
const updateConsignerFromAPI = async (
  id: string,
  consignerFormValues: ConsignerFormValues,
  isRecalculateInventoryPayout: boolean
) => {
  const payload = {
    firstName: consignerFormValues.firstName,
    lastName: consignerFormValues.lastName,
    email: consignerFormValues.email,
    phone: consignerFormValues.phone,
    address: consignerFormValues.address,
    idNumber: consignerFormValues.idNumber,
    accountName: consignerFormValues.accountName,
    accountNumber: consignerFormValues.accountNumber,
    bank: consignerFormValues.bank,
    bankType: consignerFormValues.bankType,
    branchCode: consignerFormValues.branchCode,
    bankData: consignerFormValues.bankData,
    accountType: consignerFormValues.accountType,
    payoutData: consignerFormValues.payoutData,
    payoutType: consignerFormValues.payoutType,
    marketplaceLocation: consignerFormValues.marketplaceLocation,
    ...(consignerFormValues.acceptedTOS !== undefined && {
      acceptedTOS: consignerFormValues.acceptedTOS,
    }),
    ...(consignerFormValues.isActive !== undefined && {
      isActive: consignerFormValues.isActive,
    }),
    emailNotifications: consignerFormValues.emailNotifications,
    chargeTax: consignerFormValues.chargeTax,
    consignerTypeId: consignerFormValues.consignerTypeId,
    isRecalculateInventoryPayout,
  };
  const { data, error } = await api.provide("put", "/api/consigners/:id", {
    id,
    ...payload,
  });
  if (error) throw error;
  return data;
};
const getConsignerReportFromAPI = async (
  inventoryFilter: InventoryFilterValues,
  dateRange?: string[]
) => {
  const date = new Date().toISOString();

  const payload: any = {
    consigner: inventoryFilter.consigner,
    status: inventoryFilter.status,
    category: inventoryFilter.category,
    location: inventoryFilter.location || "",
  };

  if (dateRange && dateRange?.[0] && dateRange?.[1]) {
    payload.dateRangeStart = dateRange[0];
    if (date.substring(0, 10) === dateRange[1]) {
      var dateOneDayAhead = new Date(
        new Date().setDate(new Date().getDate() + 1)
      ).toISOString();
      payload.dateRangeEnd = dateOneDayAhead.substring(0, 10);
    } else {
      payload.dateRangeEnd = dateRange[1];
    }
  }

  const { data, error } = await api.provide(
    "get",
    "/api/consigners/consignerReport",
    payload
  );
  if (error) throw error;
  return data as any;
};

const createConsignerFromAPI = async (newConsigner: NewConsignerFormValues) => {
  const payload = {
    firstName: newConsigner.firstName,
    lastName: newConsigner.lastName,
    email: newConsigner.email,
    phone: newConsigner.phone,
    address: newConsigner.address,
    idNumber: newConsigner.idNumber,
    accountName: newConsigner.accountName,
    accountNumber: newConsigner.accountNumber,
    bank: newConsigner.bank,
    bankType: newConsigner.bankType,
    branchCode: newConsigner.branchCode,
    fbUserId: await firebase.auth().currentUser?.uid,
    accountType: newConsigner.accountType,
    payoutData: newConsigner.payoutData,
    payoutType: newConsigner.payoutType,
    storeId: newConsigner.storeId,
    acceptedTOS: false,
  };

  const { data, error } = await api.provide("post", "/api/consigners", payload);
  if (error) throw error;
  return data;
};

const createConsignerFromAdminAPI = async (newConsigner: FormValues) => {
  const payload: any = {
    firstName: newConsigner.firstName,
    lastName: newConsigner.lastName,
    email: newConsigner.email,
    phone: newConsigner.phone,
    address: newConsigner.address,
    accountName: newConsigner.accountName,
    accountNumber: newConsigner.accountNumber,
    bank: newConsigner.bank,
    bankType: newConsigner.bankType,
    branchCode: newConsigner.branchCode,
    bankData: newConsigner.bankData,
    accountType: newConsigner.accountType,
    storeId: newConsigner.storeId,
  };

  const { data, error } = await api.provide(
    "post",
    "/api/consigners/createFromAdmin",
    payload
  );
  if (error) throw error;
  return data;
};
const deleteConsignerFromAPI = async (id: string) => {
  const { data, error } = await api.provide(
    "post",
    "/api/consigners/deleteConsigner/:id",
    { id }
  );
  if (error) throw error;
  return data;
};

const sendConsignerVerificationEmailFromAPI = async (email: string) => {
  const { data, error } = await api.provide("post", "/api/consigners/verify", {
    email,
  });

  if (error) {
    message.error(error.message);
    throw error;
  }

  return data;
};

const updateConsignerPasswordFromAPI = async (
  email: string,
  password: string
) => {
  const { data, error } = await api.provide(
    "put",
    "/api/consigners/updatePassword",
    {
      email,
      password,
    }
  );

  if (error) {
    throw error;
  }

  return data;
};

//actions
export const getConsigners = (search: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getConsignersLoading());
    try {
      dispatch(getConsignersSuccess(await getConsignersFromAPI(search)));
    } catch (e) {
      dispatch(getConsignersError());
    }
  };
};

export const getConsigner = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getConsignerLoading());
    try {
      dispatch(getConsignerSuccess(await getConsignerFromAPI(id)));
    } catch (e) {
      dispatch(getConsignerError());
    }
  };
};

export const createConsigner = (consigner: NewConsignerFormValues) => {
  return async (dispatch: AppDispatch) => {
    dispatch(createConsignerLoading());
    try {
      dispatch(createConsignerSuccess(await createConsignerFromAPI(consigner)));
    } catch (e) {
      dispatch(createConsignerError());
    }
  };
};

export const createConsignerFromAdmin = (consigner: NewConsignerFormValues) => {
  return async (dispatch: AppDispatch) => {
    dispatch(createConsignerLoading());
    try {
      dispatch(
        createConsignerSuccess(await createConsignerFromAdminAPI(consigner))
      );
    } catch (e) {
      dispatch(createConsignerError());
    }
  };
};

export const resetConsignerUpdate = () => {
  return {
    type: RESET_UPDATED_CONSIGNER,
    updatedConsigner: null,
  };
};

export const updateConsigner = (
  id: string,
  consigner: ConsignerFormValues,
  isRecalculateInventoryPayout: boolean
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateConsignerLoading());
    try {
      dispatch(
        updateConsignerSuccess(
          await updateConsignerFromAPI(
            id,
            consigner,
            isRecalculateInventoryPayout
          )
        )
      );
    } catch (e) {
      dispatch(updateConsignerError());
    }
  };
};

export const deleteConsigner = (id: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(deleteConsignerLoading());
    try {
      dispatch(deleteConsignerSuccess(await deleteConsignerFromAPI(id)));
    } catch (e) {
      dispatch(deleteConsignerError());
    }
  };
};

export const getConsignerReport = (
  inventoryFilter: InventoryFilterValues,
  dateRange?: string[]
) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getConsignerReportLoading());
    try {
      dispatch(
        getConsignerReportSuccess(
          await getConsignerReportFromAPI(inventoryFilter, dateRange)
        )
      );
    } catch (e) {
      dispatch(getConsignerReportError());
    }
  };
};

export const sendConsignerVerificationEmail = (email: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(sendConsignerVerificationEmailLoading());
    try {
      await sendConsignerVerificationEmailFromAPI(email);
      dispatch(sendConsignerVerificationEmailSuccess());
    } catch (e) {
      dispatch(sendConsignerVerificationEmailError());
    }
  };
};

export const updateConsignerPassword = (email: string, password: string) => {
  return async (dispatch: AppDispatch) => {
    dispatch(updateConsignerPasswordLoading());
    // try {
    await updateConsignerPasswordFromAPI(email, password);
    dispatch(updateConsignerPasswordSuccess());
    // } catch (e) {
    //   dispatch(sendConsignerVerificationEmailError());
    // }
  };
};
