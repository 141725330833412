import {
  Form,
  Card,
  Input,
  Button,
  Radio,
  Select,
  Cascader,
  Checkbox,
  DatePicker,
  Typography,
  InputNumber,
  TreeSelect,
  Switch,
  Descriptions,
  message,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Content from "../../constants/Content";
import ImageUpload from "./ImageUpload";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { AppDispatch } from "../../redux/store";
import {
  createStore,
  updateStore,
  getStore,
  resyncAllInventory,
} from "../../redux/actions/storeActions";
import TextArea from "antd/lib/input/TextArea";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons/lib/icons";
/**
 * Employee Access Control
 */

const Container = styled(Card)`
  margin-top: 10px;
`;
const { Title } = Typography;
const SIZES = ["US M", "US W", "UK", "EU", "JP", "KR"];
const PAYOUT = ["PayPal", "Zelle", "Bank"];
const FIELDS = ["Email", "Full Name", "Full Address", "Username", "Phone"];
const LABEL_OPTIONS = [
  "Item Description",
  "Item Id",
  "Size",
  "Price",
  "SKU",
  "Consigner Id",
  "Consigner",
  "Logo",
  "QR Code",
  "Shopify QR Code",
  "Sublocation",
  "Scan To Shop",
];
const SHOE_OPTIONS = ["Condition", "Size", "Box Condition"];
const PRODUCT_KINDS = [
  { label: "Shoes", value: "Shoes" },
  { label: "Clothing", value: "Clothing" },
  { label: "Accessories", value: "Accessories" },
  { label: "Other", value: "Other" },
];

export interface StoreFormValues {
  name: string;
  supportEmail: string;
  domain: string;
  currency: string;
  logo: string;
  signupInfo: [];
  payoutOptions: [];
  prioritizeStore: boolean;
  livePriceChanges: boolean;
  labelOptions: [];
  labelSize: {};
  consignerUrl: string;
  consignerCname: string;
  shipInstructions: string;
  emailMsgs: string;
  marketplaceEmailMsgs: string;
  termsOfService: string;
  absorbDiscount: boolean;
  safeInventoryStatusMode: boolean;
  redirectURL: string;
  customBarcode: string;
  shopifyTrackingParams: {};
  customShopifyUrl: string;
  maxApproval: number;
  minApproval: number;
  payoutFee: number;
  autoPreapproval: boolean;
  warnAmount: number;
  warnText: string;
  chargeTax: boolean;
  upPrice: number;
  inStoreUrl: string;
  isBuyingStore: boolean;
  onlineMarketplace: boolean;
  isSupplyEnabled: boolean;
}

const onUpdate = async (
  dispatch: AppDispatch,
  StoreFormValues: StoreFormValues,
  id: string
) => {
  dispatch(await updateStore(id, StoreFormValues));
};

const onResync = async (dispatch: AppDispatch) => {
  dispatch(await resyncAllInventory());
};

const SetupSettings = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();
  const [formUpload] = Form.useForm();

  const [selectedSizes, setSelectedSizes] = useState<any[]>([]);
  const [selectedPayouts, setSelectedPayouts] = useState<any[]>([]);
  const [selectedFields, setSelectedFields] = useState<any[]>([]);
  const [shoeOptions, setShoeOptions] = useState<any[]>([]);
  const [selectedLabelOptions, SetSelectedLabelOptions] = useState<any[]>([]);
  const [currency, setCurrency] = useState("");
  const [domain, setDomain] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [supportEmail, setSupportEmail] = useState("");
  const [signupInfo, setSignupInfo] = useState(["Email"]);
  const [payoutOptions, setPayoutOptions] = useState<any[]>([]);
  const [prioritizeStore, setPrioritizeStore] = useState(false);
  const [labelOptions, setLabelOptions] = useState<any[]>([]);
  const [labelSize, setLabelSize] = useState({});
  const [logo, setLogo] = useState("");
  const [consignerUrl, setConsignerUrl] = useState("");
  const [inStoreUrl, setInStoreUrl] = useState("");
  const [consignerCname, setConsignerCname] = useState("");
  const [shipInstructions, setShipInstructions] = useState("");
  const [emailMsgs, setEmailMsgs] = useState("");
  const [marketplaceEmailMsgs, setMarketplaceEmailMsgs] = useState("");
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [absorbDiscount, setAbsorbDiscount] = useState(false);
  const [safeInventoryStatusMode, setSafeInventoryStatusMode] = useState(false);
  const [livePriceChanges, setlivePriceChanges] = useState(false);
  const [redirectURL, setRedirectURL] = useState("");
  const [customBarcode, setCustomBarcode] = useState("");
  const [shopifyTrackingParams, setShopifyTrackingParams] = useState<any[]>([]);
  const [customShopifyUrl, setCustomShopifyUrl] = useState("");
  const [maxApproval, setMaxApproval] = useState(0);
  const [minApproval, setMinApproval] = useState(0);
  const [warnAmount, setWarnAmount] = useState(0);
  const [warnText, setWarnText] = useState("");
  const [payoutFee, setPayoutFee] = useState(0);
  const [autoPreapproval, setAutoPreapproval] = useState(false);
  const [isBuyingStore, setIsBuyingStore] = useState(false);
  const [onlineMarketplace, setOnlineMarketplace] = useState(false);
  const [isSupplyEnabled, setIsSupplyEnabled] = useState(false);
  const [chargeTax, setChargeTax] = useState(true);
  const [upPrice, setUpPrice] = useState(0);

  const { store, updateStore, resyncAllInventoryLoading, updateStoreLoading } =
    useAppSelector((state) => state.StoreReducer);

  useEffect(() => {
    if (store) {
      setId(store.id);
      setCurrency(store.currency);
      setDomain(store.domain);
      setName(store.name);
      setSupportEmail(store.supportEmail);
      setSignupInfo(store.signupInfo);
      setPayoutOptions(store.payoutOptions);
      setPrioritizeStore(store.prioritizeStore);
      setLabelOptions(store.labelOptions);
      setLabelSize(store.labelSize);
      setHeight(store.labelSize?.height);
      setWidth(store.labelSize?.width);
      setLogo(store.logo);
      setConsignerUrl(store.consignerUrl);
      setInStoreUrl(store.inStoreUrl);
      setConsignerCname(store.consignerCname);
      setShipInstructions(store.shipInstructions);
      setEmailMsgs(store.emailMsgs);
      setMarketplaceEmailMsgs(store.marketplaceEmailMsgs);
      setAbsorbDiscount(store.absorbDiscount);
      setSafeInventoryStatusMode(store.safeInventoryStatusMode);
      setlivePriceChanges(store.livePriceChanges);
      setRedirectURL(store.redirectURL);
      setCustomBarcode(store.customBarcode);
      setShopifyTrackingParams(store.shopifyTrackingParams);
      setCustomShopifyUrl(store.customShopifyUrl);
      setMaxApproval(store.maxApproval);
      setMinApproval(store.minApproval);
      setPayoutFee(store.payoutFee);
      setAutoPreapproval(store.autoPreapproval);
      setIsBuyingStore(store.isBuyingStore);
      setOnlineMarketplace(store.onlineMarketplace);
      setIsSupplyEnabled(store.isSupplyEnabled);
      setChargeTax(store.chargeTax);
      setUpPrice(store.upPrice);
      setWarnAmount(store.warnAmount);
      setWarnText(store.warnText);
    }
  }, [store]);

  useEffect(() => {
    if (updateStore) {
      setCurrency(updateStore.currency);
      setDomain(updateStore.domain);
      setName(updateStore.name);
      setSupportEmail(updateStore.supportEmail);
      setSignupInfo(updateStore.signupInfo);
      setPayoutOptions(updateStore.payoutOptions);
      setPrioritizeStore(updateStore.prioritizeStore);
      setLabelOptions(updateStore.labelOptions);
      setLabelSize(updateStore.labelSize);
      setHeight(updateStore.labelSize?.height);
      setWidth(updateStore.labelSize?.width);
      setLogo(updateStore.logo);
      setConsignerUrl(updateStore.consignerUrl);
      setInStoreUrl(updateStore.inStoreUrl);
      setConsignerCname(updateStore.consignerCname);
      setShipInstructions(updateStore.shipInstructions);
      setEmailMsgs(updateStore.emailMsgs);
      setMarketplaceEmailMsgs(updateStore.marketplaceEmailMsgs);
      setAbsorbDiscount(updateStore.absorbDiscount);
      setSafeInventoryStatusMode(updateStore.safeInventoryStatusMode);
      setlivePriceChanges(updateStore.livePriceChanges);
      setRedirectURL(updateStore.redirectURL);
      setCustomBarcode(updateStore.customBarcode);
      setShopifyTrackingParams(updateStore.shopifyTrackingParams);
      setCustomShopifyUrl(updateStore.customShopifyUrl);
      setMaxApproval(updateStore.maxApproval);
      setMinApproval(updateStore.minApproval);
      setPayoutFee(updateStore.payoutFee);
      setAutoPreapproval(updateStore.autoPreapproval);
      setIsBuyingStore(updateStore.isBuyingStore);
      setOnlineMarketplace(updateStore.onlineMarketplace);
      setIsSupplyEnabled(updateStore.isSupplyEnabled);
      setChargeTax(updateStore.chargeTax);
      setUpPrice(updateStore.upPrice);
      setWarnAmount(updateStore.warnAmount);
      setWarnText(updateStore.warnText);
    }
  }, [updateStore, updateStoreLoading]);

  useEffect(() => {
    form.setFieldsValue({
      id,
      currency,
      domain,
      name,
      supportEmail,
      signupInfo,
      payoutOptions,
      labelOptions,
      prioritizeStore,
      consignerUrl,
      inStoreUrl,
      consignerCname,
      shipInstructions,
      emailMsgs,
      marketplaceEmailMsgs,
      labelSize,
      height,
      width,
      absorbDiscount,
      safeInventoryStatusMode,
      livePriceChanges,
      redirectURL,
      customBarcode,
      shopifyTrackingParams,
      customShopifyUrl,
      maxApproval,
      minApproval,
      payoutFee,
      autoPreapproval,
      warnAmount,
      warnText,
      chargeTax,
      upPrice,
      isBuyingStore,
      onlineMarketplace,
      isSupplyEnabled,
    });
  }, [store, id]);

  const uploadedLogo = (value: any) => {
    setLogo(value);
  };

  const handleChangeSizing = (selectedSizes: any) => {
    setSelectedSizes(selectedSizes);
  };
  const filteredOptionsSizing = SIZES.filter(
    (o) => !selectedSizes?.includes(o)
  );
  const handleChangeShoeOptions = (shoeOptions: any) => {
    setShoeOptions(shoeOptions);
  };
  // const filteredShoeOptions = SHOE_OPTIONS.filter(
  //   (o) => !shoeOptions?.includes(o)
  // );

  const handleChangePayout = (selectedPayouts: any) => {
    setSelectedPayouts(selectedPayouts);
  };
  const filteredOptionsPayout = PAYOUT.filter(
    (o) => !selectedPayouts?.includes(o)
  );

  const handleChangeField = (selectedFields: any) => {
    setSelectedFields(selectedFields);
  };
  const filteredOptionsField = FIELDS.filter(
    (o) => !selectedFields?.includes(o)
  );

  const handleChangeLabelOptions = (selectedLabelOptions: any) => {
    SetSelectedLabelOptions(selectedLabelOptions);
  };

  const filteredLabelOptions = LABEL_OPTIONS.filter(
    (o) => !selectedLabelOptions?.includes(o)
  );

  const onFinish = (values: any) => {
    const savedItem = {
      name: values.name,
      supportEmail: values.supportEmail,
      domain: values.domain,
      currency: values.currency,
      signupInfo: values.signupInfo,
      payoutOptions: values.payoutOptions,
      prioritizeStore: values.prioritizeStore,
      labelOptions: values.labelOptions,
      labelSize: { width: values.width, height: values.height },
      logo,
      consignerUrl: values.consignerUrl,
      inStoreUrl: values.inStoreUrl,
      consignerCname: values.consignerCname,
      shipInstructions: values.shipInstructions,
      emailMsgs: values.emailMsgs,
      marketplaceEmailMsgs: values.marketplaceEmailMsgs,
      termsOfService: values.termsOfService,
      absorbDiscount: values.absorbDiscount,
      safeInventoryStatusMode: values.safeInventoryStatusMode,
      livePriceChanges: values.livePriceChanges,
      redirectURL: values.redirectURL,
      customBarcode: values.customBarcode,
      shopifyTrackingParams: values.shopifyTrackingParams,
      customShopifyUrl: values.customShopifyUrl,
      maxApproval: values.maxApproval,
      minApproval: values.minApproval,
      payoutFee: values.payoutFee,
      autoPreapproval: values.autoPreapproval,
      warnAmount: values.warnAmount,
      warnText: values.warnText,
      chargeTax: values.chargeTax,
      upPrice: values.upPrice,
      isBuyingStore: values.isBuyingStore,
      onlineMarketplace: values.onlineMarketplace,
      isSupplyEnabled: values.isSupplyEnabled,
    };

    onUpdate(dispatch, savedItem, values.id);
    message.success("Settings saved!");
    //wait for callback from save/update then dispatch getStore
    // if (updateStoreLoading === false) {
    //   //need logic to figure out which store to reload on save/
    //   //if update, same id as before
    //   form.resetFields();
    //   setTimeout(() => dispatch(getStore("3")), 600);
    // }
  };

  return (
    <Container>
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
        labelCol={{ span: 4 }}
      >
        <Title level={4}>Store Settings</Title>
        <Form.Item name="id" hidden={true}></Form.Item>
        <Form.Item
          name="name"
          label="Store Name"
          rules={[{ required: true, message: "Name is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="supportEmail"
          label="Support email"
          rules={[{ required: true, message: "Support email is required" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="redirectURL"
          label="Redirect URL"
          rules={[
            { required: true, message: "Redirect URL email is required" },
          ]}
        >
          <Input addonBefore="https://" />
        </Form.Item>
        <Form.Item
          name="consignerUrl"
          label="Consigner Domain"
          rules={[{ required: true, message: "Domain is required" }]}
        >
          <Input
            placeholder="Where your consigners will login"
            addonBefore="https://"
          />
        </Form.Item>
        <Form.Item
          name="inStoreUrl"
          label="In-Store App Domain"
          rules={[{ required: true, message: "Domain is required" }]}
        >
          <Input
            placeholder="Where your in-store app will be accessible"
            addonBefore="https://"
          />
        </Form.Item>
        <Form.Item name="customShopifyUrl" label="Custom Shopify Domain">
          <Input
            placeholder="Used in the Shopify QR Code in Label Settings"
            addonBefore="https://"
          />
        </Form.Item>
        <Form.Item name="consignerCname">
          <Descriptions bordered>
            <Descriptions.Item label="CNAME">
              {consignerCname}
            </Descriptions.Item>
          </Descriptions>
        </Form.Item>
        <Form.Item
          name="currency"
          label="Store Currency"
          rules={[{ required: true, message: "Currency is required" }]}
        >
          <Select showSearch allowClear>
            <Select.Option value="AED">AED</Select.Option>
            <Select.Option value="ALL">ALL</Select.Option>
            <Select.Option value="AMD">AMD</Select.Option>
            <Select.Option value="AOA">AOA</Select.Option>
            <Select.Option value="ARS">ARS</Select.Option>
            <Select.Option value="AUD">AUD</Select.Option>
            <Select.Option value="AZN">AZN</Select.Option>
            <Select.Option value="BAM">BAM</Select.Option>
            <Select.Option value="BBD">BBD</Select.Option>
            <Select.Option value="BGN">BGN</Select.Option>
            <Select.Option value="BHD">BHD</Select.Option>
            <Select.Option value="BMD">BMD</Select.Option>
            <Select.Option value="BOB">BOB</Select.Option>
            <Select.Option value="BRL">BRL</Select.Option>
            <Select.Option value="BWP">BWP</Select.Option>
            <Select.Option value="BYN">BYN</Select.Option>
            <Select.Option value="CAD">CAD</Select.Option>
            <Select.Option value="CDF">CDF</Select.Option>
            <Select.Option value="CHF">CHF</Select.Option>
            <Select.Option value="CLP">CLP</Select.Option>
            <Select.Option value="CNY">CNY</Select.Option>
            <Select.Option value="COP">COP</Select.Option>
            <Select.Option value="CRC">CRC</Select.Option>
            <Select.Option value="CVE">CVE</Select.Option>
            <Select.Option value="CZK">CZK</Select.Option>
            <Select.Option value="DKK">DKK</Select.Option>
            <Select.Option value="DOP">DOP</Select.Option>
            <Select.Option value="DZD">DZD</Select.Option>
            <Select.Option value="EGP">EGP</Select.Option>
            <Select.Option value="EUR">EUR</Select.Option>
            <Select.Option value="FJD">FJD</Select.Option>
            <Select.Option value="GBP">GBP</Select.Option>
            <Select.Option value="GEL">GEL</Select.Option>
            <Select.Option value="GHS">GHS</Select.Option>
            <Select.Option value="GIP">GIP</Select.Option>
            <Select.Option value="GTQ">GTQ</Select.Option>
            <Select.Option value="GYD">GYD</Select.Option>
            <Select.Option value="HKD">HKD</Select.Option>
            <Select.Option value="HNL">HNL</Select.Option>
            <Select.Option value="HRK">HRK</Select.Option>
            <Select.Option value="HUF">HUF</Select.Option>
            <Select.Option value="IDR">IDR</Select.Option>
            <Select.Option value="ILS">ILS</Select.Option>
            <Select.Option value="IMP">IMP</Select.Option>
            <Select.Option value="INR">INR</Select.Option>
            <Select.Option value="IQD">IQD</Select.Option>
            <Select.Option value="ISK">ISK</Select.Option>
            <Select.Option value="JMD">JMD</Select.Option>
            <Select.Option value="JOD">JOD</Select.Option>
            <Select.Option value="JPY">JPY</Select.Option>
            <Select.Option value="KES">KES</Select.Option>
            <Select.Option value="KGS">KGS</Select.Option>
            <Select.Option value="KHR">KHR</Select.Option>
            <Select.Option value="KRW">KRW</Select.Option>
            <Select.Option value="KWD">KWD</Select.Option>
            <Select.Option value="KYD">KYD</Select.Option>
            <Select.Option value="KZT">KZT</Select.Option>
            <Select.Option value="LAK">LAK</Select.Option>
            <Select.Option value="LBP">LBP</Select.Option>
            <Select.Option value="LKR">LKR</Select.Option>
            <Select.Option value="LYD">LYD</Select.Option>
            <Select.Option value="MAD">MAD</Select.Option>
            <Select.Option value="MDL">MDL</Select.Option>
            <Select.Option value="MGA">MGA</Select.Option>
            <Select.Option value="MKD">MKD</Select.Option>
            <Select.Option value="MMK">MMK</Select.Option>
            <Select.Option value="MNT">MNT</Select.Option>
            <Select.Option value="MOP">MOP</Select.Option>
            <Select.Option value="MRU">MRU</Select.Option>
            <Select.Option value="MUR">MUR</Select.Option>
            <Select.Option value="MVR">MVR</Select.Option>
            <Select.Option value="MWK">MWK</Select.Option>
            <Select.Option value="MXN">MXN</Select.Option>
            <Select.Option value="MYR">MYR</Select.Option>
            <Select.Option value="MZN">MZN</Select.Option>
            <Select.Option value="NAD">NAD</Select.Option>
            <Select.Option value="NGN">NGN</Select.Option>
            <Select.Option value="NIO">NIO</Select.Option>
            <Select.Option value="NOK">NOK</Select.Option>
            <Select.Option value="NZD">NZD</Select.Option>
            <Select.Option value="OMR">OMR</Select.Option>
            <Select.Option value="PAB">PAB</Select.Option>
            <Select.Option value="PEN">PEN</Select.Option>
            <Select.Option value="PGK">PGK</Select.Option>
            <Select.Option value="PHP">PHP</Select.Option>
            <Select.Option value="PKR">PKR</Select.Option>
            <Select.Option value="PLN">PLN</Select.Option>
            <Select.Option value="PYG">PYG</Select.Option>
            <Select.Option value="QAR">QAR</Select.Option>
            <Select.Option value="RON">RON</Select.Option>
            <Select.Option value="RSD">RSD</Select.Option>
            <Select.Option value="RUB">RUB</Select.Option>
            <Select.Option value="RWF">RWF</Select.Option>
            <Select.Option value="SAR">SAR</Select.Option>
            <Select.Option value="SEK">SEK</Select.Option>
            <Select.Option value="SGD">SGD</Select.Option>
            <Select.Option value="SZL">SZL</Select.Option>
            <Select.Option value="THB">THB</Select.Option>
            <Select.Option value="TJS">TJS</Select.Option>
            <Select.Option value="TMT">TMT</Select.Option>
            <Select.Option value="TND">TND</Select.Option>
            <Select.Option value="TRY">TRY</Select.Option>
            <Select.Option value="TTD">TTD</Select.Option>
            <Select.Option value="TWD">TWD</Select.Option>
            <Select.Option value="TZS">TZS</Select.Option>
            <Select.Option value="UAH">UAH</Select.Option>
            <Select.Option value="UGX">UGX</Select.Option>
            <Select.Option value="USD">USD</Select.Option>
            <Select.Option value="UYU">UYU</Select.Option>
            <Select.Option value="UZS">UZS</Select.Option>
            <Select.Option value="VEF">VEF</Select.Option>
            <Select.Option value="VND">VND</Select.Option>
            <Select.Option value="XAF">XAF</Select.Option>
            <Select.Option value="XCD">XCD</Select.Option>
            <Select.Option value="XOF">XOF</Select.Option>
            <Select.Option value="ZAR">ZAR</Select.Option>
            <Select.Option value="ZMW">ZMW</Select.Option>
            <Select.Option value="ZWD">ZWD</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Terms of service" name="termsOfService">
          <TextArea
            rows={4}
            placeholder={"A generic TOS will be used if you don't add yours..."}
          />
        </Form.Item>
        <Form.Item label="Minimum Preapproval Value" name="minApproval">
          <Input />
        </Form.Item>
        <Form.Item label="Maximum Preapproval Value" name="maxApproval">
          <Input />
        </Form.Item>
        <Form.Item label="Store Payout Fee" name="payoutFee">
          <Input />
        </Form.Item>
        <Form.Item label="Price Alert Amount" name="warnAmount">
          <Input />
        </Form.Item>
        <Form.Item label="Price Alert Message" name="warnText">
          <TextArea rows={4} placeholder={"Input message here..."} />
        </Form.Item>
        <Title level={5}>Logo</Title>
        <ImageUpload
          form={formUpload}
          logo={logo}
          uploadedLogo={uploadedLogo}
        />
        <br />
        <br />
        <Title level={4}>Inventory Settings</Title>
        Prioritize store inventory over consigners?
        <Form.Item name="prioritizeStore" valuePropName="checked">
          <Switch />
        </Form.Item>
        Store absorbs discount?
        <Form.Item name="absorbDiscount" valuePropName="checked">
          <Switch />
        </Form.Item>
        Auto-Accept Price Change Requests
        <Form.Item name="livePriceChanges" valuePropName="checked">
          <Switch />
        </Form.Item>
        Inventory Safe Mode
        <Form.Item name="safeInventoryStatusMode" valuePropName="checked">
          <Switch />
        </Form.Item>
        Allow Preapproval
        <Form.Item name="autoPreapproval" valuePropName="checked">
          <Switch />
        </Form.Item>
        Charge VAT
        <Form.Item name="chargeTax" valuePropName="checked">
          <Switch />
        </Form.Item>
        Buying
        <Form.Item name="isBuyingStore" valuePropName="checked">
          <Switch />
        </Form.Item>
        Enable Online Marketplace
        <Form.Item name="onlineMarketplace" valuePropName="checked">
          <Switch />
        </Form.Item>
        {store.handle === "supply" && (
          <>
            Enable Supply
            <Form.Item name="isSupplyEnabled" valuePropName="checked">
              <Switch />
            </Form.Item>
          </>
        )}
        <Form.Item name="upPrice" label="Up Price">
          <InputNumber />
        </Form.Item>
        <Form.Item name="customBarcode" label="Custom barcode">
          <Select showSearch allowClear>
            <Select.Option value="SKU">SKU</Select.Option>
            <Select.Option value="CODE">Code</Select.Option>
            <Select.Option value="GTIN">GTIN</Select.Option>
            <Select.Option value={null}>None</Select.Option>
          </Select>
        </Form.Item>
        <Title level={4}>Label Settings</Title>
        <Form.Item
          label="Shopify QR Code Analytics"
          style={{ marginBottom: 0 }}
        >
          <Form.List name="shopifyTrackingParams">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item {...restField} name={[name, "param"]}>
                      <Input placeholder="Ex. utm_source" />
                    </Form.Item>
                    <Form.Item {...restField} name={[name, "value"]}>
                      <Input placeholder="Value" />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    // block
                    icon={<PlusOutlined />}
                  >
                    Add tracking parameter
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
        <Form.Item
          name="labelOptions"
          label="Label Options"
          rules={[{ required: true, message: "Label option is required" }]}
        >
          <Select
            mode="multiple"
            value={labelOptions}
            placeholder="Items to display on label"
            onChange={handleChangeLabelOptions}
            style={{ width: "100%" }}
          >
            {filteredLabelOptions.map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Label Size" style={{ marginBottom: 0 }}>
          <Form.Item
            name="width"
            rules={[{ required: true, message: "Width is required" }]}
            style={{ display: "inline-block", width: "calc(50% - 8px)" }}
          >
            <Input addonBefore="Width" addonAfter="mm" />
          </Form.Item>
          <Form.Item
            name="height"
            rules={[{ required: true, message: "Height is required" }]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input addonBefore="Height" addonAfter="mm" />
          </Form.Item>
        </Form.Item>
        <Title level={4}>Consigner Settings</Title>
        <Form.Item
          name="signupInfo"
          label="Signup info"
          rules={[{ required: true }]}
        >
          <Select
            mode="multiple"
            placeholder="Your required data to collect from consigners"
            value={selectedFields}
            onChange={handleChangeField}
            style={{ width: "100%" }}
          >
            {filteredOptionsField.map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="payoutOptions"
          label="Payout options"
          rules={[{ required: true, message: "Payout option is required" }]}
        >
          <Select
            mode="multiple"
            placeholder="How will you pay consigners?"
            value={selectedPayouts}
            onChange={handleChangePayout}
            style={{ width: "100%" }}
          >
            {filteredOptionsPayout.map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Ship instructions"
          name="shipInstructions"
          rules={[
            { required: true, message: "Ship instructions values required" },
          ]}
        >
          <TextArea rows={4} placeholder={"Ship instructions"} />
        </Form.Item>
        {/* Store Email Messages  */}
        <Title level={4}>Custom Email Settings</Title>
        <Form.Item
          label="Sold Email Messages"
          name="emailMsgs"
          rules={[{ required: false }]}
        >
          <TextArea
            rows={4}
            placeholder={
              "Eg: Please allow up to 7 business days to receive payment "
            }
          />
        </Form.Item>
        <Form.Item
          label="Marketplace Sold Email: "
          name="marketplaceEmailMsgs"
          rules={[{ required: false }]}
        >
          <TextArea
            rows={4}
            placeholder={
              "Eg: Please allow up to 7 business days to receive payment "
            }
          />
        </Form.Item>
        <Title level={4}>Resync All Inventory</Title>
        <Form.Item name="resyncTitle" label="Resyncing  all inventory">
          <Button
            onClick={() => onResync(dispatch)}
            loading={resyncAllInventoryLoading}
            type="primary"
            style={{ float: "right" }}
          >
            Resync
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ float: "right" }}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default SetupSettings;
