import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Redirect, useHistory } from "react-router-dom";
import Content from "../constants/Content";
import {
  Button,
  Card,
  Carousel,
  Col,
  Dropdown,
  Image,
  Input,
  List,
  Menu,
  Row,
  Statistic,
} from "antd";
import { UserState } from "../redux/reducers/UserReducer";
import { getSupplyInventories } from "../redux/actions/inventoryActions";
import { InventoryState } from "../redux/reducers/InventoryReducer";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import SupplyStepTwo from "./SupplyStepTwo";
import {
  EnableMarketplaceBtn,
  EnableMarketplaceModal,
} from "../styles/ConsignerInventory";
import AddedToCart from "../assets/images/AddedtoCart.png";
import EmailedProduct from "../assets/images/EmailedProduct.png";
import TrackingCodeAdded from "../assets/images/TrackingCodeAdded.png";
import {
  Container,
  InventoryCards,
  ProductCard,
  SizePriceCards,
} from "../styles/Supply";
import { AppState } from "../redux/reducers/AppReducer";
import OptimizedImageUrl from "../helperFunctions/optimizedImageUrl";
import styled from "styled-components";

const { Search } = Input;

const ListContainer = styled(List)`
  // overflow-y: scroll;
  padding: 15px 0;
  width: 100%;
  .ant-col {
    height: 100%;
    & > .ant-list-item {
      height: calc(100% - 15px);
      .ant-card {
        height: 100%;
      }
    }
  }
`;

const Supply = () => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const [uniqueInventories, setUniqueInventories] = useState<any[]>([]);
  const { supplyInventories, inventoriesLoading }: InventoryState =
    useAppSelector((state) => state.InventoryReducer);
  const [singleProduct, setSingleProduct] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [howItWorkPopup, setHowItWorkPopup] = useState(false);
  const history = useHistory();
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);

  const [filters, setFilters] = useState<any>({
    status: "Active",
    // printed: "false",
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: undefined,
    location: "SUPPLY",
    // sortOption: "newestUpdated",
    consigner:
      dbUser && dbUser.id && process.env.REACT_APP_TYPE === "consigner"
        ? dbUser.id
        : undefined,
  });

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Employees")
  ) {
    return <Redirect to="/" />;
  }

  useEffect(() => {
    //refresh inventory list on filter or search change
    reloadInventories();
  }, [search, filters, currentPage, pageSize, currentStep]);

  useEffect(() => {
    if (!supplyInventories) return;

    if (!inventoriesLoading) {
      const groupedData = supplyInventories?.reduce((groups, item) => {
        const groupId = item.productId;
        if (!groups[groupId]) {
          groups[groupId] = {
            productId: item.productId,
            key: groupId,
            productTitle: item.product.title,
            productImage: item.product.image,
            productSku: item.product.sku,
            consigner: item.consigner,
            quantity: 0,
            inventories: [],
          };
        }
        groups[groupId].quantity++;
        groups[groupId].inventories.push(item);
        return groups;
      }, {});
      setUniqueInventories(Object.values(groupedData));
    }
  }, [supplyInventories, inventoriesLoading === false]);

  const reloadInventories = async () => {
    setSelectedRowKeys([]);
    await dispatch(getSupplyInventories(search, filters));
  };

  const handleCardClick = (unique: any) => {
    setSingleProduct(unique);
    history.push(`/supply/${unique.productId}`);
  };

  const groupItems = (items: any, itemsPerGroup: any) => {
    const groups: any = [];
    for (let i = 0; i < items.length; i += itemsPerGroup) {
      groups.push(items.slice(i, i + itemsPerGroup));
    }
    return groups;
  };

  // const CustomRightOutlined = () => {
  //   return
  //   <div onClick={(e) => e.stopPropagation()}>
  //     <RightOutlined />
  //   </div>;
  // };

  const SupplyInventoryCard = (props: any) => {
    const { product } = props;
    const { store } = useAppSelector((state) => state.StoreReducer);
    const [showDropdown, setShowDropdown] = useState(false);
    const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);

    const handleDropdownClick = () => {
      setShowDropdown(!showDropdown);
    };

    const groupedInventories: any = groupItems(
      product.inventories,
      isMobile ? 3 : 2
    );

    return (
      <ProductCard
        className="product-card"
        // onClick={() => handleCardClick(product)}
      >
        <Image
          src={OptimizedImageUrl(product.productImage)}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = product.image;
          }}
          alt=""
          style={{ height: "auto" }}
          preview={false}
          onClick={() => handleCardClick(product)}
        />
        <div onClick={() => handleCardClick(product)}>
          <h2>{product.productTitle}</h2>
          <p>SKU: {product.productSku}</p>
        </div>
      </ProductCard>
    );
  };

  return (
    <>
      {currentStep === 0 && (
        <Container>
          <Search
            data-testid="search"
            placeholder={Content.SUPPLY_INVENTORY_SEARCH_PLACEHOLDER}
            allowClear
            size="large"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <h2 style={{ margin: 0, textAlign: "left", padding: "0 10px" }}>
            All items are coming from the USA
          </h2>
          <ListContainer>
            <Row gutter={[16, 16]}>
              {uniqueInventories?.map((unique) => (
                <Col
                  key={unique.key}
                  xs={24} // 1 column on extra small screens
                  sm={12} // 2 columns on small screens
                  md={6} // 2 columns on medium screens
                  lg={6} // 4 columns on large screens
                  xl={6} // 4 columns on extra large screens
                  xxl={6} // 4 columns on extra extra large screens
                  style={{ height: "auto" }}
                  onClick={() => handleCardClick(unique)}
                >
                  <SupplyInventoryCard product={unique} />
                </Col>
              ))}
            </Row>
          </ListContainer>
          <EnableMarketplaceBtn onClick={() => setHowItWorkPopup(true)}>
            {/* <ShopOutlined /> */}
            How it works?
          </EnableMarketplaceBtn>
          <EnableMarketplaceModal
            visible={howItWorkPopup}
            footer={false}
            width={900}
            onCancel={() => setHowItWorkPopup(false)}
            style={{ borderRadius: "10px" }}
          >
            <div className="modal-content">
              <div className="center-text">
                <h3>How it works?</h3>
                <p>Buy from stores + vetted wholesalers</p>
              </div>
              <div className="card-container">
                <div className="card-container-item">
                  <div style={{ textAlign: "center" }}>
                    <img className="card-item" src={AddedToCart} />
                  </div>
                  <h3>Step 1</h3>
                  <p>
                    View all the inventory - from our vetted wholesales + stores
                  </p>
                </div>
                <div className="card-container-item">
                  <div style={{ textAlign: "center" }}>
                    <img className="card-item" src={EmailedProduct} />
                  </div>
                  <h3>Step 2</h3>
                  <p>
                    Payment - Trace will charge your card on file or you can
                    request by emailing archy@jointrace.co for another payment
                    method
                  </p>
                </div>
                <div className="card-container-item">
                  <div style={{ textAlign: "center" }}>
                    <img className="card-item" src={TrackingCodeAdded} />
                  </div>
                  <h3>Step 3</h3>
                  <p>
                    Legit check - after you receive it, accept on the drop off
                    pending and the payment is processed
                  </p>
                </div>
              </div>
              <div className="button-container">
                <Button onClick={() => setHowItWorkPopup(false)}>
                  Confirm
                </Button>
              </div>
            </div>
          </EnableMarketplaceModal>
        </Container>
      )}
      {/* {currentStep === 1 && (
        <SupplyStepTwo
          inventory={singleProduct}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
        />
      )} */}
    </>
  );
};

export default Supply;
