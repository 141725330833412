import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Redirect } from "react-router";
import {
  Button,
  Collapse,
  Input,
  Select,
  Spin,
  Image,
  Pagination,
  Checkbox,
  Statistic,
  Switch,
} from "antd";
import Content from "../constants/Content";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  getOrders,
  exportOrders,
  resyncAllOrders,
} from "../redux/actions/orderActions";
import OrderFilter from "../components/Common/OrderFilter";
import {
  getInventoryFilterOptions,
  getOrdersPayoutInventories,
  getOrdersPayoutInventoriesFromAPI,
} from "../redux/actions/inventoryActions";
import OrderTable from "../components/Common/OrderTable";
import { Order } from "../redux/reducers/OrderReducer";
import { RouteComponentProps, useHistory } from "react-router";
import { Payout } from "../redux/reducers/PayoutReducer";
import { UserState } from "../redux/reducers/UserReducer";
import {
  UploadOutlined,
  SyncOutlined,
  EyeOutlined,
  UnorderedListOutlined,
  GroupOutlined,
} from "@ant-design/icons";
import { ReactComponent as CaretDownOutlined } from "../assets/images/svg/CaretCircleUp.svg";
import { ReactComponent as EditOutlined } from "../assets/images/svg/TripleDot.svg";
import RedesignStyling from "../constants/RedesignStyling";
import Colors from "../constants/Colors";
import { getConsigners } from "../redux/actions/consignerActions";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import getSymbolFromCurrency from "currency-symbol-map";
import Link from "antd/lib/typography/Link";
import { getStore } from "../redux/actions/storeActions";
import { Inventory, InventoryState } from "../redux/reducers/InventoryReducer";
import {
  CustomB,
  CustomInventoryFilterContainer,
  InventoryPageTypes,
  SrchBx,
} from "./Inventory";
import InventoryFilter from "../components/Common/InventoryFilter";
import { inventories } from "../constants/TestData";
import {
  ColumnTypes,
  sizeSorter,
} from "../consignerScreens/ConsignerInventory";
import moment from "moment";
import {
  Wrapper,
  ProductRowMobile,
  ProductRowMobileTop,
  ActionButtons,
  StyledButton,
} from "../components/Common/InventoryCardView";
import { ConsignerTable } from "../styles/ConsignerPayout";

const { Search } = Input;

/**
 * Order Screen
 * Renders table of orders and search
 * TODO TESTS:
 *  - renders correctly
 *  - on search refetchs orders
 */
const Container = styled.div`
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  #inventoryFilter {
    margin: 0 0 0 15px;
    flex-wrap: nowrap;
    &.ant-form-inline .ant-form-item:last-of-type {
      margin-right: 0;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    height: 40px;
    color: #292d32;
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);
    background: var(--White, #fff);
  }
  table {
    thead {
      .ant-checkbox-inner {
        background-color: transparent; /* Customize the check color */
      }
    }
  }
  @media (max-width: 768px) {
    background-color: #fff;
  }
`;
const ActionBar = styled.div`
  gap: 8px;
  display: flex;
  justify-content: space-between;
  align-self: stretch;
  flex: 1;
  margin-left: 8px;
  .ant-btn {
    flex: 1;
  }
  @media (max-width: 400px) {
    flex-direction: column;
    order: 1;
  }
`;
interface CustomSpanProps {
  order?: number;
}
const ActionButtonsContainer = styled.div`
  display: flex;
  gap: 12px;
  .ant-btn {
    height: 100%;
    background-color: #e1ecff;
    color: #2e2e2e;
    border-radius: 8px;
    border: none;
  }
  @media (max-width: 400px) {
    margin-top: 10px;
  }
`;
export const SearchBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px auto;
  width: 100%;
  padding: 0 16px;
  & > span {
    width: 100%;
    max-width: 100%;
    height: ${RedesignStyling.SEARCH_HEIGHT};
  }
  .ant-input-group.ant-input-wrapper {
    display: flex;
    height: 100%;
    .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH});
    }
  }
  button.ant-input-search-button {
    padding: 8px 16px;
    background-color: ${Colors.ADMIN_BLUE};
    color: ${Colors.WHITE};
    border: 0;
    height: ${RedesignStyling.SEARCH_HEIGHT};
    width: ${RedesignStyling.SEARCH_BUTTON_WIDTH};
    padding: ${RedesignStyling.BUTTON_PADDING};
  }

  @media (max-width: 768px) {
    margin: 0 0 8px;
    order: 3;
    padding: 0;
    .ant-input-group.ant-input-wrapper .ant-input-affix-wrapper {
      max-width: calc(100% - ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE});
      width: 100%;
    }

    .ant-input-search-with-button .ant-input-group input.ant-input {
      font-size: 16px;
    }

    button.ant-input-search-button {
      width: ${RedesignStyling.SEARCH_BUTTON_WIDTH_MOBILE};
    }
  }
`;
const goToOrderPage = (
  history: RouteComponentProps["history"],
  order: Order | Payout
) => {
  history.push(`/orders/${order.id}`);
};

const ReviewListingContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
    max-width: 100vw;
    order: 4;
    padding: 40px 0 24px;
    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          // padding: 0;
        }
        .ant-collapse-content {
          .ant-collapse-content-box {
            padding: 0;
          }
        }
      }
    }
  }
`;

const CustomSpan = styled.div`
  display: flex;
  width: 100%;
  padding: 16px 0;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  b {
    font-size: 20px;
    line-height: 150%;
  }
  button {
    max-height: 40px;
    height: 40px;
  }
  span {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    .ant-picker-range {
      background: transparent;
      width: 225px;
      border: 0;
      color: ${Colors.CONSIGNER_GREY};
    }
    .ant-picker-input > input {
      color: #9ca3af;
    }
  }
  @media (max-width: 768px) {
    flex-wrap: nowrap;
    display: flex;
    flex-direction: column;
    #inventoryFilter {
      margin: 0;
      gap: 2px !important;
    }
  }
`;

export const visibleColumns: any = (store) => [
  {
    title: "Order No",
    key: "order.name",
    dataIndex: "order.name",
    render: (_: any, record: any) => record.order.name,
  },
  {
    title: "Name",
    key: "productName",
    dataIndex: "product.title",
    render: (_: any, record: any) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: 5,
        }}
      >
        <Image
          src={record.product.image}
          alt=""
          style={{ maxWidth: 27, alignSelf: "stretch" }}
        ></Image>
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            flex: 1,
          }}
        >
          <div>{record.product.title}</div>
          <div>{record.code}</div>
          <div>
            {record.product.sku.length > 12
              ? record.product.sku.slice(0, 12) + "..."
              : record.product.sku}
          </div>
        </span>
      </div>
    ),
  },
  {
    title: "Size",
    key: "option1Value",
    dataIndex: "option1Value",
    render: (_: any, record: any) => record?.option1Value ?? null,
    sorter: sizeSorter,
    // defaultSortOrder: "ascend",
  },
  // {
  //   title: "SKU",
  //   key: "sku",
  //   dataIndex: "product.sku",
  //   render: (_: any, record: any) => {
  //     const len = record.product.sku.length;
  //     if (len > 12) {
  //       const firstPart = record.product.sku.slice(0, 12);
  //       return firstPart + "...";
  //     } else {
  //       return record.product.sku;
  //     }
  //   },
  // },
  {
    title: "Location",
    key: "location",
    dataIndex: "location",
    render: (_: any, record: any) => record?.location ?? null,
  },
  {
    title: store.isBuyingStore ? "Seller" : "Consigner",
    key: "consigner",
    dataIndex: "consigner",
    render: (consigner: any, record: any) => (
      <CustomB className="underlined">
        <Link target="_blank" href={`consigners/${record.consignerId}`}>
          {consigner.firstName}&nbsp;{consigner.lastName}
        </Link>
      </CustomB>
    ),
  },
  {
    title: "Price",
    key: "price",
    dataIndex: "price",
    render: (_: any, record: any) =>
      `${getSymbolFromCurrency(record.store.currency)}${record.price}`,
  },
  {
    title: "Payout",
    key: "total",
    dataIndex: "total",
    render: (_: any, record: any) =>
      `${getSymbolFromCurrency(record.store.currency)}${Number(
        record.payoutAmount
      ).toFixed(2)}`,
  },
  {
    title: "Date Sold",
    key: "soldOn",
    dataIndex: "soldOn",
    render: (text: string, record: any) =>
      moment(text ?? record?.updatedAt).format("YY/MM/DD"),
  },
  {
    title: "",
    key: "operation",
    dataIndex: "operation",
    render: (_: any, record: any) => (
      <Link target="_blank" href={`/orders/${record.orderId}`}>
        <StyledButton type="primary" icon={<EyeOutlined />}>
          View
        </StyledButton>
      </Link>
    ),
  },
];

const InventoryCardMobileView = ({
  dataSource,
  columnSrc,
  priceMap,
  status,
}: {
  dataSource: Inventory[];
  columnSrc: any[];
  priceMap: any[];
  status: any;
}) => {
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [expandedKey, setExpandedKey] = useState(-1);
  return (
    <Wrapper>
      {dataSource.map((inventory, key) => (
        <ProductRowMobile key={key}>
          <ProductRowMobileTop>
            {columnSrc
              .find((col) => col.dataIndex === "id")
              ?.render(inventory.id, inventory)}
            <Image
              src={inventory.product?.image}
              width={77}
              alt=""
              style={{ flex: "0 0 77px" }}
            />
            <div style={{ flex: 1, flexDirection: "column", fontSize: 12 }}>
              <b style={{ flex: 1 }}>{inventory.product?.title}</b>
              <div style={{ display: "flex", flex: 1 }}>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>{inventory.order?.name}</b>
                    <label>Order Number</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>{inventory.product?.sku}</b>
                    <label>SKU</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>
                      {getSymbolFromCurrency(store.currency)}
                      {inventory.price}
                    </b>
                    <label>Price</label>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>
                      {inventory.consigner?.firstName}{" "}
                      {inventory.consigner?.lastName}
                    </b>
                    <label>Consigner</label>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <b>{inventory.option2Value}</b>
                    <label>Condition</label>
                  </div> */}
                </span>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>{inventory.option1Value}</b>
                    <label>Size</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>
                      {getSymbolFromCurrency(store.currency)}
                      {inventory.payoutAmount
                        ? Number(inventory.payoutAmount).toFixed(1)
                        : "0"}
                    </b>
                    <label>Payout</label>
                  </div>
                  {status === InventoryPageTypes.Requested && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <b>
                        {getSymbolFromCurrency(store.currency)}
                        {priceMap.find(
                          (rec) =>
                            rec.key ===
                            `${inventory.option1Value}-${inventory?.option2Value}-${inventory?.option3Value}-${inventory?.location}-${inventory?.storeId}-${inventory?.product?.id}`.replace(
                              /[\s]/g,
                              ""
                            )
                        )?.price ?? inventory.price}
                      </b>
                      <label>Current lowest price</label>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>{inventory.location}</b>
                    <label>Location</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>{moment(inventory.soldOn).format("YY/MM/DD")}</b>
                    <label>Date Sold</label>
                  </div>
                </span>
              </div>
            </div>
            <ActionButtons>
              {columnSrc
                .find((col) => col.dataIndex === "operation")
                ?.render("", inventory)}
            </ActionButtons>
          </ProductRowMobileTop>
        </ProductRowMobile>
      ))}
    </Wrapper>
  );
};

const Orders = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [inventories, setInventories] = useState<Inventory[]>([]);
  const [tableNestedView, setTableNestedView] = useState(false);
  const [filters, setFilters] = useState<any>({
    status: "",
    option1Value: undefined,
    option2Value: undefined,
    option3Value: undefined,
    category: undefined,
    // sortOption: "newestUpdated",
    consigner:
      dbUser && dbUser.id && process.env.REACT_APP_TYPE === "consigner"
        ? dbUser.id
        : undefined,
  });
  const { orders, ordersLoading } = useAppSelector(
    (state) => state.OrderReducer
  );

  const { ordersPayoutsInventories, inventoriesLoading }: InventoryState =
    useAppSelector((state) => state.InventoryReducer);
  const { consigners, consignersLoading } = useAppSelector(
    (state) => state.ConsignerReducer
  );

  const { isMobile } = useAppSelector((state) => state.AppReducer);
  const [filteredOrders, setFilteredOrders] = useState<any[]>([]);
  const [supplyInventoriesTable, setSupplyInventoriesTable] = useState<any[]>(
    []
  );
  const [columnSrc, setColumnSrc] = useState<any[]>([]);
  const [consignerView, setConsignerView] = useState(false);
  const { inventoryFilterOptions, inventoryFilterOptionsLoading } =
    useAppSelector((state) => state.InventoryReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);

  const handleResyncAllOrders = () => {
    dispatch(resyncAllOrders()).then(() => {
      window.location.reload();
    });
  };

  const groupColumns = [
    {
      title: "Product",
      dataIndex: "productAddress",
      key: "product.supplyDetails",
      render: (text: string, record: any) => (
        <Statistic
          title={consignerView ? "Consigner" : "Order ID"}
          value={consignerView ? record.consigner : record?.orderId}
        />
      ),
    },
    {
      title: "Units",
      dataIndex: "quantity",
      key: "product.quantity",
      render: (text: string, record: any) => (
        <Statistic title="Units" value={record?.quantity} />
      ),
    },
    {
      title: "Amount",
      dataIndex: "revenue",
      key: "product.revenue",
      render: (text: string, record: any) => (
        <Statistic
          title="Revenue"
          value={getSymbolFromCurrency(record.currency) + record?.revenue}
        />
      ),
    },
  ];

  useEffect(() => {
    dispatch(getInventoryFilterOptions());
    dispatch(getConsigners(""));
  }, []);

  const fetchOrdersData = async () => {
    setLoading(true);
    const data = await getOrdersPayoutInventoriesFromAPI(
      search,
      filters,
      currentPage,
      pageSize
    );
    const inv = data.inventories.filter((i) => i.status !== "Completed");
    setInventories(inv);
    setTotal(data.total);
    setLoading(false);
  };

  useEffect(() => {
    //refresh inventory list on filter or search change
    // dispatch(
    //   getOrdersPayoutInventories(search, filters, currentPage, pageSize)
    // );
    fetchOrdersData();
  }, [search, filters, currentPage, pageSize]);

  useEffect(() => {
    // if (inventories) createFilters();
    if (inventories) {
      const supplyInv: any = inventories;
      const groupedData = supplyInv.reduce((groups, item) => {
        const groupId = consignerView ? item.consignerId : item.orderId;
        if (!groups[groupId]) {
          groups[groupId] = {
            orderId: groupId,
            key: 0,
            supplyDetails: item.supplyDetails,
            revenue: 0,
            quantity: 0,
            currency: store.currency,
            consigner: consignerView
              ? item.consigner.firstName + " " + item.consigner.lastName
              : "",
            inventories: [],
          };
        }
        groups[groupId].key++;
        groups[groupId].quantity++;
        groups[groupId].revenue += Number(item.price);
        groups[groupId].inventories.push(item);
        return groups;
      }, {});
      console.log(groupedData, "group data");
      setSupplyInventoriesTable(Object.values(groupedData));
      setColumnSrc(groupColumns);
    }
  }, [inventories, tableNestedView, consignerView]);

  useEffect(() => {
    console.log(inventories);
  }, [inventories]);

  if (
    dbUser &&
    dbUser.accessControls &&
    !dbUser.accessControls.includes("Orders")
  ) {
    return <Redirect to="/" />;
  }
  if (!inventoryFilterOptions || inventoryFilterOptionsLoading) return <Spin />;

  return (
    <Container>
      <CustomSpan
        style={{ justifyContent: "space-between", gap: isMobile ? 5 : 0 }}
      >
        <span
          style={{
            flex: 2,
            alignItems: "center",
            alignSelf: isMobile ? "stretch" : "auto",
          }}
        >
          <SrchBx
            value={search}
            onChange={(e) => setSearch(e)}
            placeholder={Content.PRODUCT_SCREEN_SEARCH_PLACEHOLDER}
          />
        </span>
        <CustomInventoryFilterContainer>
          <InventoryFilter
            onFilter={(values) => setFilters(values)}
            formOptions={inventoryFilterOptions}
            showConsignerFilter
            showStatusFilter={false}
            currentFilters={filters}
            subLocations={[]}
          />
        </CustomInventoryFilterContainer>
        <ActionBar>
          {!isMobile && (
            <>
              <ActionButtonsContainer>
                {/* <Button type="primary" onClick={() => handleResyncAllOrders()}>
                {Content.RESYNC_ALL_ORDERS_ACTION_BUTTON_TEXT}
                <SyncOutlined />
              </Button> */}
                <Button
                  type="primary"
                  onClick={() => dispatch(exportOrders(filteredOrders))}
                >
                  {Content.FIND_ORDERS_ACTION_BUTTON_TEXT}
                  <UploadOutlined />
                </Button>
              </ActionButtonsContainer>
            </>
          )}
          {store.isSuperAdmin && (
            <CustomInventoryFilterContainer
              style={{ flex: "0 0 auto", marginLeft: 8, gap: 8 }}
            >
              <Button
                icon={<UnorderedListOutlined />}
                type={tableNestedView ? "default" : "primary"}
                onClick={() => setTableNestedView(false)}
              ></Button>
              <Button
                icon={<GroupOutlined />}
                type={tableNestedView ? "primary" : "default"}
                onClick={() => setTableNestedView(true)}
              ></Button>
            </CustomInventoryFilterContainer>
          )}
        </ActionBar>
      </CustomSpan>
      <ActionBar>
        <OrderFilter
          onFilter={(values) => setFilters({ ...filters, ...values })}
          formOptions={inventoryFilterOptions}
          showConsigner={false}
        />
        {tableNestedView && (
          <CustomInventoryFilterContainer
            style={{ flex: "0 0 auto", marginLeft: 8, gap: 8 }}
          >
            <Switch
              checkedChildren="Order"
              unCheckedChildren="Consigner"
              defaultChecked
              onChange={() => setConsignerView(!consignerView)}
            />
          </CustomInventoryFilterContainer>
        )}
      </ActionBar>
      {tableNestedView ? (
        <ConsignerTable
          data-testid="payoutTable"
          dataSource={supplyInventoriesTable}
          columns={columnSrc}
          rowClassName={"bordered-row"}
          rowKey={"orderId"}
          showHeader={false}
          loading={inventoriesLoading}
          expandable={{
            expandedRowRender: (record: any) =>
              isMobile ? (
                <InventoryCardMobileView
                  dataSource={record.inventories.map((inv: any) => ({
                    ...inv,
                    key: inv.id,
                  }))}
                  columnSrc={visibleColumns(store)}
                  priceMap={[]}
                  status={0}
                />
              ) : (
                <OrderTable
                  data={record.inventories.map((inv: any) => ({
                    ...inv,
                    key: inv.id,
                  }))}
                  loading={inventoriesLoading}
                  onItemAction={(order) => goToOrderPage(history, order)}
                  consigners={consigners}
                  pagina={false}
                  headers={false}
                />
              ),
          }}
        />
      ) : (
        <>
          {isMobile ? (
            <InventoryCardMobileView
              dataSource={inventories}
              columnSrc={visibleColumns(store)}
              priceMap={[]}
              status={0}
            />
          ) : (
            <OrderTable
              data={inventories}
              loading={loading || consignersLoading}
              onItemAction={(order) => goToOrderPage(history, order)}
              consigners={consigners}
              pagina={true}
              headers={true}
            />
          )}
        </>
      )}
      <Pagination
        style={{ marginTop: 10, order: 5 }}
        current={currentPage}
        total={total}
        pageSize={pageSize}
        onChange={(page, pageSize) => {
          setCurrentPage(page);
          setPageSize(pageSize);
        }}
        pageSizeOptions={[10, 20, 50, 100, 250, 500]}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        showQuickJumper // allows user to type in page number
        showSizeChanger // allows user to change number of items per page
      />
    </Container>
  );
};

export default Orders;
