import React, { useState } from "react";
import { Form, Row, Col, message } from "antd";
import InputField from "../../../components/Input/input";
import ButtonComponent from "../../../components/Button/button";
import {
  ButtonDivFirst,
  ColorPTag,
  Responsive,
  ResponsiveTitle,
  HrDiv,
  MobileView,
  BigScreenView,
} from "../../../styles/formStyles";
import { api } from "../../../api";
import styled from "styled-components";
import { Link } from "react-router-dom";
import CountryCode from "../../../components/Common/CountryCode";
import Cookies from "js-cookie";

interface UserInfoProp {
  onFinish: (data: object, step: number) => void;
}
const DropdownFlex = styled.div`
  display: flex;
`;

const SelectDropdown = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid #dddddd;
  width: 70px;
  height: 48px;
`;

const Select = styled.select`
  font-size: 1rem;
  font-weight: normal;
  max-width: 100%;
  padding: 8px 0 8px 10px;
  border: none;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:active,
  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const DropdownArrow = styled.div`
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  width: 0;
  height: 0;
  margin-top: -2px;
  border-top: 5px solid #aaa;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
`;

const DropdownWrapper = styled.div`
  position: relative;
`;

export default function UserInfo({ onFinish }: UserInfoProp) {
  const [form] = Form.useForm();

  const handleNextClick = async () => {
    try {
      const values = await form.validateFields();
      const countryCode = form.getFieldValue("countryCode");
      const phoneNumber = form.getFieldValue("phoneNumber");
      values.phoneNumber = `+${countryCode}${phoneNumber}`;

      const isAvailable = await checkAvailability(values);
      if (isAvailable) {
        handleFinish(values);
      }
    } catch (errorInfo) {
      console.error("Failed:", errorInfo);
    }
  };
  const checkAvailability = async (values: any) => {
    try {
      const { handle, email } = values;
      const { data, error } = await api.provide(
        "post",
        "/open/stores/checkAvailable",
        { handle, email }
      );
      if (error) throw error;
      if (data.message) {
        // Handle already in use
        message.error(data.message);
        return false;
      }
      return true;
    } catch (error) {
      message.error("An error occurred while checking availability");
      return false;
    }
  };

  const handleFinish = (values: any) => {
    const { confirmPassword, ...dataWithoutConfirmPassword } = values; // Remove confirmPassword
    const domain = window.location.hostname.split(".").slice(-2).join(".");
    console.log("===domain====", domain);

    Cookies.set("string1", values.email, {
      domain: "https://lux-staging-admin.onrender.com/}",
    });
    Cookies.set("string2", values.password, {
      domain: "https://lux-staging-admin.onrender.com/",
    });
    onFinish(dataWithoutConfirmPassword, 2);
  };
  const handleCountryCode = (value) => {
    form.setFieldsValue({ countryCode: value });
  };
  const handlePhoneProp = (value) => {
    form.setFieldsValue({ phoneNumber: value });
  };
  return (
    <Form
      form={form}
      name="register"
      onFinish={handleFinish}
      style={{ maxWidth: 600, marginTop: "2rem" }}
      scrollToFirstError
    >
      <Responsive>
        <Row gutter={[10, 5]}>
          <Col xs={24} xl={10} lg={10}>
            <ResponsiveTitle level={5}>Name</ResponsiveTitle>
            <InputField
              name="name"
              placeholder="Full Name"
              inputStyle={{ height: "48px" }}
              rules={[
                {
                  required: true,
                  message: "Please enter your name",
                },
                {
                  min: 3,
                  message: "Name must be at least 3 characters long",
                },
              ]}
            />
          </Col>
          <Col xs={24} xl={10} lg={10}>
            <ResponsiveTitle level={5}>Handle-Store Name</ResponsiveTitle>
            <InputField
              name="handle"
              placeholder="Store handle"
              inputStyle={{ height: "48px" }}
              rules={[
                {
                  required: true,
                  message: "Please enter store handle",
                },
              ]}
            />
          </Col>
          <Col xs={24} xl={10} lg={10}>
            <ResponsiveTitle level={5}>Email</ResponsiveTitle>
            <InputField
              name="email"
              placeholder="Enter your email"
              titleLevel={5}
              inputStyle={{ height: "48px" }}
              rules={[
                {
                  type: "email",
                  message: "Not a valid Email!",
                },
                {
                  required: true,
                  message: "Please enter your Email.",
                },
              ]}
            />
          </Col>
          <Col xs={24} xl={10} lg={10}>
            <ResponsiveTitle level={5}>Phone Number</ResponsiveTitle>
            <DropdownFlex>
              <CountryCode
                CountryCodeProp={handleCountryCode}
                phoneProp={handlePhoneProp}
              />
            </DropdownFlex>
          </Col>
          <Col xs={24} xl={10} lg={10}>
            <ResponsiveTitle level={5}>Password</ResponsiveTitle>
            <InputField
              name="password"
              placeholder="Enter password"
              titleLevel={5}
              inputStyle={{ height: "48px" }}
              rules={[
                {
                  required: true,
                  message: "Please enter your password.",
                },
              ]}
              password={true}
              hasFeedBack
            />
          </Col>
          <Col xs={24} xl={10} lg={10}>
            <ResponsiveTitle level={5}>Repeat Password</ResponsiveTitle>
            <InputField
              name="confirmPassword"
              placeholder="Repeat password"
              titleLevel={5}
              inputStyle={{ height: "48px" }}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Password not matched!"));
                  },
                }),
              ]}
              dependencies={["password"]}
              password={true}
              hasFeedBack
            />
          </Col>
        </Row>
      </Responsive>
      <BigScreenView>
        <ButtonDivFirst>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24, xl: 170 }}>
            <Col xs={24} lg={12} xl={12} style={{ marginTop: "1rem" }}>
              <ColorPTag>
                Already have an account? <Link to={"/login"}>Sign in</Link>
              </ColorPTag>
            </Col>

            <Col xs={24} lg={12} xl={12}>
              <Row gutter={[10, 5]}>
                <Col xs={4} lg={12} xl={12}>
                  <ButtonComponent
                    text="Cancel"
                    style={{
                      backgroundColor: "#DDDDDD",
                      color: "black",
                      height: "60px",
                      padding: "0rem 1.5rem",
                    }}
                  />
                </Col>
                <Col xs={16} lg={12} xl={12}>
                  <ButtonComponent
                    text="Next"
                    style={{
                      backgroundColor: "#A49FFB",
                      color: "white",
                      height: "60px",
                      padding: "0rem 2rem",
                    }}
                    onClick={handleNextClick}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </ButtonDivFirst>
      </BigScreenView>

      <MobileView>
        <Col xs={24} lg={12} xl={12} style={{ marginTop: "1rem" }}>
          <ColorPTag>
            Already have an account? <Link to={"/login"}>Sign in</Link>
          </ColorPTag>
        </Col>
        <HrDiv></HrDiv>
        <ButtonDivFirst style={{ backgroundColor: "rgba(0,0,0,0.04)" }}>
          <Row gutter={[10, 5]}>
            <Col xs={4} lg={12} xl={12}>
              <ButtonComponent
                text="Cancel"
                style={{
                  backgroundColor: "#DDDDDD",
                  color: "black",
                  height: "60px",
                  padding: "0rem 2rem",
                }}
              />
            </Col>
            <Col xs={16} lg={12} xl={12}>
              <ButtonComponent
                text="Next"
                style={{
                  backgroundColor: "#A49FFB",
                  color: "white",
                  height: "60px",
                  padding: "0rem 2rem",
                }}
                onClick={handleNextClick}
              />
            </Col>
          </Row>
        </ButtonDivFirst>
      </MobileView>
    </Form>
  );
}
