import React from "react";
import { Button } from "antd";

interface ButtonProp {
  text: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}
export default function ButtonComponent({
  text,
  style,
  onClick,
  disabled,
}: ButtonProp) {
  return (
    <Button
      style={{
        ...style,
        width: "100%",
        borderRadius: "8px",
        fontWeight: "600",
        fontSize: "16px",
        cursor: "pointer",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
}
