import React from "react";
import { Form, Select, DatePicker } from "antd";
import { useForm } from "antd/lib/form/Form";
import { InventoryFilterOptions } from "../../redux/reducers/InventoryReducer";

/**
 * Orders Filter
 * Used to filer a list of orders
 * has onFilter prop which triggers on filter changes
 * form of filters
 * TODO Test
 *  - render correctly
 *  - onFilter triggers on filter changes
 */
const { RangePicker } = DatePicker;

export interface FormValues {
  consigner: string;
  orderDateRange: any;
}

interface Props {
  formOptions: InventoryFilterOptions;
  onFilter: (values: FormValues) => void;
}

const OrderFilterV1 = (props: Props) => {
  const [form] = useForm();
  const { formOptions, onFilter } = props;

  let consignersWithoutStore;
  if (formOptions) {
    consignersWithoutStore = formOptions?.consigners?.filter(
      (consigner: any) => !consigner.value.isStoreAccount
    );
  }

  return (
    <Form
      layout="inline"
      name="orderFilter"
      form={form}
      data-testid="orderFilter"
      onValuesChange={onFilter}
      style={{ marginTop: 10 }}
    >
      <Form.Item name="consigner">
        <Select
          placeholder="Filter by Consigner"
          style={{ width: 200 }}
          showSearch
          filterOption={(input, option) =>
            String(option?.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          allowClear
          data-testid="consigners"
        >
          {consignersWithoutStore?.map((consigner) => (
            <Select.Option
              key={consigner.value.id}
              value={JSON.stringify(consigner.value.id)}
            >
              {consigner.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="orderDateRange">
        <RangePicker />
      </Form.Item>
    </Form>
  );
};

export default OrderFilterV1;
