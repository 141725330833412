import { Button, Modal, Radio } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Colors from "../../constants/Colors";
import { ReactComponent as CircleTick } from "./CircleTick.svg";
import { useDispatch } from "react-redux";
import {
  createMembershipStripePaymentFromAPI,
  getConsignerTypes,
} from "../../redux/actions/consignerTypeActions";
import { useAppSelector } from "../../redux/hooks";
import { loadStripe } from "@stripe/stripe-js";

export const MembershipModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 10px;
  }
  .ant-modal-close {
    display: none;
  }
  .ant-modal-header {
    display: none;
  }
  h3 {
    font-weight: 600;
    text-align: center;
    font-size: 17px;
    margin-bottom: 20px;
  }
  .package-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
    .ant-btn {
      padding: 10px 20px;
      height: 50px;
      border-radius: 7px;
    }
    .ant-btn-default {
      background-color: ${Colors.SMOKE_GREY};
    }
    .ant-btn-primary {
      background-color: ${Colors.CONSIGNER_BLUE};
    }
  }
`;

export const Congratulation = styled(Modal)`
  .ant-modal-header {
    display: none;
  }
  border-radius: 10px;
  .ant-modal-close {
    display: none;
  }
  .ant-modal-content {
    border-radius: 10px;
    text-align: center;
    width: 435px;
  }
  .ant-btn-default {
    display: none;
  }
  .ant-btn-primary {
    width: 100%;
    border-radius: 10px;
    height: 50px;
    background: ${Colors.CONSIGNER_BLUE};
  }
  h3 {
    font-weight: 600;
    font-size: 20px;
  }
  p {
    color: rgba(116, 116, 116, 1);
    font-size: 15px;
    margin: 0;
    padding: 0 15px;
  }
  @media (max-width: 768px) {
    .ant-modal-content {
      width: 100%;
    }
  }
`;

export const Package = styled.div`
  position: relative;
  border: 1px solid rgba(221, 221, 221, 1);
  background: rgba(244, 244, 244, 1);
  border-radius: 10px;
  padding: 10px 10px 4px 10px;
  span.ant-radio + *{
  width: 100%;
  }

  .badge {
    border-radius: 5px;
    width: fit-content;
    padding: 3px 7px;
    font-size: 10px;
    position: absolute;
    top: -8px;
    right: 22px;
  }
  .white{
    color: white;
  }
  .current-plan {
    background: rgba(221, 221, 221, 1);
  }
  .best-value {
    background: white;
    color: ${Colors.CONSIGNER_BLUE};
    border: 1px solid ${Colors.CONSIGNER_BLUE};
    z-index; 9999;
  }
  .ant-radio {
    position: relative;
    top: 10px;
  }
  .tier-name {
    position: relative;
    top: 4px;
  }
  .ant-radio-wrapper {
    width: 100%;
    // span:nth-child(2) {
    //   width: 100%;
    // }
    h3 {
      font-size: 13px;
      font-weight: 500;
      text-align: left;
      margin: 0;
    }
    .flex {
      display: flex;
      gap: 3px;
    }
    .flex-btw {
      display: flex;
      justify-content: space-between;
    }
    p {
      font-size: 8px;
      color: rgba(116, 116, 116, 1);
      margin: 0;
    }
    .package-rate {
      display: flex;
      color: black;
      font-size: 9px;
      position: relative;
      bottom: 2px;
      .price {
        font-size: 13px;
        color: black;
        position: relative;
        bottom: 4px;
      }
    }
  }
`;

const CongratulationModal = (props: any) => {
  const { open, setOpen } = props;
  return (
    <>
      <Congratulation
        title="Basic Modal"
        open={open}
        onOk={() => setOpen(false)}
        okText="Back to home"
      >
        <CircleTick fill={Colors.CONSIGNER_BLUE} />
        <h3>Congratulations</h3>
        <p>
          Congratulations! you account has been upgraded to Pro Plan
          successfully. Enjoy the new experience
        </p>
      </Congratulation>
    </>
  );
};

const MembershipPackages = (props: any) => {
  const { membershipModal, setMembershipModal, currentPackage } = props;
  const [congratulation, setCongratulation] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [packages, setPackages] = useState([]);
  const dispatch = useDispatch();
  const { consignerTypes } = useAppSelector(
    (state) => state.ConsignerTypeReducer
  );
  const makePaymemt = async () => {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY || "");
    const selectedPackage: any = packages.find(
      (pack: any) => pack.id === selectedValue
    );
    const data = {
      ...selectedPackage,
      sellingFee: Number(selectedPackage.sellingFee),
      minFee: Number(selectedPackage.sellingFee),
      flatFee: Number(selectedPackage.flatFee),
      id: Number(selectedPackage.id),
    };
    const response = await createMembershipStripePaymentFromAPI(data);
    console.log(response, "stripe response");

    const result = await stripe?.redirectToCheckout({ sessionId: response.id });
    console.log(result, "result");
  };

  useEffect(() => {
    dispatch(getConsignerTypes());
  }, []);

  useEffect(() => {
    if (consignerTypes.length) {
      const types: any = consignerTypes.filter(
        (types) => types.isPaid === true
      );
      setPackages(types);
    }
  }, [consignerTypes]);

  return (
    <div>
      <MembershipModal
        open={membershipModal}
        title="Title"
        onOk={makePaymemt}
        okText="Upgrade"
        onCancel={() => setMembershipModal(false)}
        width={380}
      >
        <h3>Upgrade for the fee they created now</h3>
        <Radio.Group
          className="package-list"
          value={selectedValue}
          onChange={(e) => setSelectedValue(e.target.value)}
        >
          {packages.map((type: any, index) => (
            <Package
              key={index}
              style={{
                color: index === 1 ? "white" : "inherit",
                background:
                  index === 1 ? `${Colors.CONSIGNER_BLUE}` : "inherit",
              }}
            >
              <Radio value={type.id}>
                <div style={{ width: "100%" }}>
                  <span className="flex">
                    <h3
                      className={index === 1 ? "white" : ""}
                      style={{ color: index === 1 ? "white" : "inherit" }}
                    >
                      {type.isLocationTier ? type.location : type.accountType}
                    </h3>
                    <p
                      className="tier-name"
                      style={{ color: index === 1 ? "white" : "inherit" }}
                    >
                      ({type.tierName})
                    </p>
                  </span>
                  <div className="flex-btw">
                    <p style={{ color: index === 1 ? "white" : "inherit" }}>
                      per user/month, billed anually
                    </p>
                    <p
                      className="package-rate"
                      style={{ color: index === 1 ? "white" : "inherit" }}
                    >
                      For only $
                      <p
                        className="price"
                        style={{ color: index === 1 ? "white" : "inherit" }}
                      >
                        {type.sellingFee}
                      </p>
                      /month
                    </p>
                  </div>
                </div>
              </Radio>
              <p
                className={
                  index === 1 ? "badge best-value" : "badge current-plan"
                }
              >
                {type.id === currentPackage?.consignerType?.id
                  ? "Current Plan"
                  : "Best Value"}
              </p>
            </Package>
          ))}
        </Radio.Group>
      </MembershipModal>
      {congratulation && (
        <CongratulationModal
          open={congratulation}
          setOpen={setCongratulation}
        />
      )}
    </div>
  );
};

export default MembershipPackages;
