import React, { useState } from "react";
import { Table, Image, Button } from "antd";
import styled from "styled-components";
import { Order } from "../../redux/reducers/OrderReducer";
import moment from "moment";
import { useAppSelector } from "../../redux/hooks";
import {
  ColumnTypes,
  sizeSorter,
} from "../../consignerScreens/ConsignerInventory";
import { CustomB } from "../../screens/Inventory";
import getSymbolFromCurrency from "currency-symbol-map";
import Colors from "../../constants/Colors";
import { ReactComponent as CaretCircleUp } from "../../assets/images/svg/CaretCircleUp.svg";
import { StoreState } from "../../redux/reducers/StoreReducer";
import { Consigner } from "../../redux/reducers/ConsignerReducer";
import { visibleColumns } from "../../screens/Orders";

/**
 * Payout / Order Table
 * Takes a list of order OR payout items and renders a filterable table
 * - List of order
 * - Filter
 * - Action to go to order page
 * TODO Test
 * - Filter changes
 */
const Container = styled.div`
  @media (max-width: 768px) {
    order: 3;
  }
`;

const HeaderContainer = styled.div``;
const HeaderTitle = styled.p``;

export const OrderTableStyled = styled(Table)`
  width: 100%;
  padding: 8px 0;
  .nPoos .ant-table table {
    border-spacing: 0 !important;
  }
  .ant-table-wrapper::before {
    display: none;
  }
  .ant-table {
    .ant-table-thead .ant-table-cell {
      background-color: #f8fafc;
      text-transform: uppercase;
      color: #2e2e2e;
      padding: 10px 8px;
      white-space: nowrap;
    }
    .ant-table-tbody .ant-table-cell {
      font-size: 13px;
      .ant-table {
        margin: 0;
        .ant-table-thead .ant-table-cell {
          background-color: #f3f4f6;
          color: var(--heading-color, #2e2e2e);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
`;

//types
interface Props {
  data: Order[];
  loading: boolean;
  consigners: Consigner[];
  onItemAction: (order: Order) => void;
  pagina: boolean;
  headers: boolean;
}
interface Columns {
  title: string;
  dataIndex: string;
  key: string;
  render: (key: string, data: Order) => JSX.Element;
}

let store: any;

const getColumns = (
  onItemAction: (order: Order) => void,
  expandedRows: any[]
): Columns[] => [
  {
    title: "Order No",
    dataIndex: "name",
    key: "name",
    render: (text: string) => (
      <span data-testid="orderTableItemName">{text}</span>
    ),
  },
  {
    title: "Location",
    dataIndex: "location",
    key: "location",
    render: (_: string, record: any) => record?.inventories?.[0]?.location,
  },
  {
    title: "Date Sold",
    dataIndex: "soldOn",
    key: "soldOn",
    render: (_: string, record: any) =>
      moment(record?.createdAt).format("YY/MM/DD"),
  },
  {
    title: "",
    dataIndex: "",
    key: "expand",
    render: (_: string, record: any) => (
      <span
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <CaretCircleUp
          style={{
            transform: expandedRows.includes(record.id)
              ? "initial"
              : "rotateZ(180deg)",
          }}
        />
      </span>
    ),
  },
];

const ExpandedRowRender = (record, inventories) => {
  const { store } = useAppSelector((state) => state.StoreReducer);
  const expandedDataSrc = inventories.filter(
    (inventory) => inventory.orderId === record.id
  );
  return (
    <Table
      dataSource={expandedDataSrc}
      // style={{ borderLeft: `4px solid ${Colors.CONSIGNER_BLUE}` }}
      columns={visibleColumns(store)}
      rowKey="id"
      pagination={{
        pageSize: 10,
        // responsive: true,
        hideOnSinglePage: true,
      }}
      // showHeader={false}
    />
  );
};

const OrderTable = (props: Props) => {
  const { data, loading, consigners, onItemAction, pagina, headers } = props;
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [expandedRows, setExpandedRows] = useState<any[]>([]);

  return (
    <Container data-testid="orderTableContainer" style={{ marginTop: 10 }}>
      <OrderTableStyled
        data-testid="orderTable"
        columns={visibleColumns(store)}
        showHeader={headers}
        dataSource={data}
        rowKey="id"
        loading={loading}
        pagination={
          pagina
            ? {
                pageSize: pageSize,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: currentPage,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                  setPageSize(pageSize);
                },
              }
            : false
        }
      />
    </Container>
  );
};

export default OrderTable;
