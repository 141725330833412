import { employee } from "../../constants/TestData";
import {
  GET_INVENTORIES,
  GET_PAGINATED_INVENTORIES,
  GET_ORDERS_PAYOUTS_INVENTORIES,
  CALCULATE_FEES,
  CREATE_INVENTORY,
  GET_INVENTORY_FILTER_OPTIONS,
  ACCEPT_INVENTORY,
  ACCEPT_REQUESTED_INVENTORY,
  DECLINE_INVENTORY,
  WITHDRAW_INVENTORY,
  ACCEPT_INVENTORY_PRICE,
  DECLINE_INVENTORY_PRICE,
  GET_INVENTORY,
  UPDATE_INVENTORY,
  EXPORT_INVENTORY,
  PRINT_INVENTORY,
  DELETE_INVENTORY,
  RESET_CREATED_INVENTORY,
  REQUEST_INVENTORY_PRICE_CHANGE,
  TRANSFER_INVENTORY,
  ACCEPT_TRANSFER_INVENTORY,
  REQUEST_INVENTORY_WITHDRAWAL,
  ACCEPT_INVENTORY_WITHDRAWAL,
  DECLINE_INVENTORY_WITHDRAWAL,
  SCAN_GTIN_INVENTORY,
  CANCEL_DROPOFF,
  SET_INVENTORIES_TO_ADD,
  GET_LOWEST_PRICE_INVENTORIES,
  GET_SUPPLY_INVENTORIES,
} from "../actions/types";
import { Consigner } from "./ConsignerReducer";
import { Product } from "./ProductReducer";
import { ProductTemplate } from "./ProductTemplateReducer";

export type Inventory = any;

export interface InventoryFilterOption {
  id: any;
  label: string;
  value: string;
}

export interface ConsignerFormOption {
  label: string;
  value: Consigner;
}

export interface InventoryFilterOptions {
  statuses: InventoryFilterOption[];
  option1Values: string[];
  option2Values: string[];
  option3Values: string[];
  locations: InventoryFilterOption[];
  categories: InventoryFilterOption[];
  consigners: ConsignerFormOption[];
  subLocations: InventoryFilterOption[];
  sortOption?: string[];
}

export interface InventoryPreapproval {
  preapproval: boolean;
  min: number;
  max: number;
}

export interface CancelDropoff {
  inventory: Inventory[];
}

export interface InventoryFee {
  transcation: number;
  selling: number;
  cleaning: number;
  box: number;
  payout: number;
  total: number;
  vat: number;
  negativeBalance: number;
  payoutFee?: number;
  subtotalPayout?: number;
}

export interface InventoryState {
  inventoriesLoading: boolean;
  calculatedFeesLoading: boolean;
  inventories: Inventory[];
  lowestPriceProducts: Inventory[];
  paginatedInventories: {
    rows: Inventory[];
    count: number;
  };
  supplyInventories: Inventory[];
  ordersPayoutsInventories: Inventory[];
  calculatedFees: InventoryFee | null;
  createdInventory: Inventory[];
  createdInventoryLoading: boolean;
  inventoryFilterOptionsLoading: boolean;
  inventoryFilterOptions: InventoryFilterOptions;
  acceptInventory: Inventory | null;
  acceptRequestedInventory: Inventory | null;
  acceptInventoryLoading: boolean;
  acceptRequestedInventoryLoading: boolean;
  declineInventory: Inventory | null;
  declineInventoryLoading: boolean;
  withdrawInventory: Inventory | null;
  withdrawInventoryLoading: boolean;
  acceptInventoryPrice: Inventory | null;
  acceptInventoryPriceLoading: boolean;
  declineInventoryPrice: Inventory | null;
  declineInventoryPriceLoading: boolean;
  acceptInventoryWithdrawal: Inventory | null;
  acceptInventoryWithdrawalLoading: boolean;
  declineInventoryWithdrawal: Inventory | null;
  declineInventoryWithdrawalLoading: boolean;
  inventory: Inventory | null;
  inventoryLoading: boolean;
  updatedInventory: Inventory | null;
  updateInventoryLoading: boolean;
  bulkPrintedInventory: Inventory[] | null;
  bulkPrintInventoryLoading: boolean;
  exportedInventory: Inventory[] | null;
  exportInventoryLoading: boolean;
  deletedInventory: Inventory[] | null;
  deleteInventoryLoading: boolean;
  requestInventoryPriceChangedInventory: Inventory[] | null;
  requestInventoryPriceChangeLoading: boolean;
  requestInventoryWithdrawalChangedInventory?: Inventory[] | null;
  requestInventoryWithdrawalChangedLoading?: boolean;
  transferInventoryLoading: boolean;
  acceptTransferInventoryLoading: boolean;
  scanGTINInventories: Inventory[] | null;
  scanGTINInventoriesLoading: boolean;
  scanGTINProduct: Product | null;
  scanGTINProductTemplate: ProductTemplate | null;
  gtinSize: string | null;
  cancelDropoff: Inventory[] | null;
  cancelDropoffLoading: boolean;
  inventoriesToReview: Inventory[] | null;
}

const defaultState = {
  inventoriesLoading: false,
  inventories: [],
  lowestPriceProducts: [],
  paginatedInventories: {
    rows: [],
    count: 0,
  },
  supplyInventories: [],
  ordersPayoutsInventories: [],
  calculatedFees: null,
  calculatedFeesLoading: false,
  createdInventoryLoading: false,
  createdInventory: [],
  inventoryFilterOptions: {
    statuses: [],
    option1Values: [],
    option2Values: [],
    option3Values: [],
    locations: [],
    subLocations: [],
    categories: [],
    consigners: [],
    sortOption: [],
  },
  inventoryFilterOptionsLoading: false,
  acceptInventory: null,
  acceptInventoryLoading: false,
  acceptRequestedInventory: null,
  acceptRequestedInventoryLoading: false,
  declineInventory: null,
  declineInventoryLoading: false,
  withdrawInventory: null,
  withdrawInventoryLoading: false,
  acceptInventoryPrice: null,
  acceptInventoryPriceLoading: false,
  declineInventoryPrice: null,
  declineInventoryPriceLoading: false,
  acceptInventoryWithdrawal: null,
  acceptInventoryWithdrawalLoading: false,
  declineInventoryWithdrawal: null,
  declineInventoryWithdrawalLoading: false,
  inventory: null,
  inventoryLoading: false,
  updatedInventory: null,
  updateInventoryLoading: false,
  bulkPrintedInventory: [],
  bulkPrintInventoryLoading: false,
  exportedInventory: [],
  exportInventoryLoading: false,
  deletedInventory: null,
  deleteInventoryLoading: true,
  requestInventoryPriceChangedInventory: null,
  requestInventoryPriceChangeLoading: false,
  requestInventoryWithdrawalChangedInventory: null,
  requestInventoryWithdrawalChangedInventoryLoading: false,
  transferInventoryLoading: false,
  acceptTransferInventoryLoading: false,
  requestInventoryWithdrawalAcceptedOn: "",
  requestInventoryWithdrawalDeclinedOn: "",
  requestInventoryWithdrawalAcceptedBy: employee,
  requestInventoryWithdrawalDeclinedBy: employee,
  scanGTINInventories: null,
  scanGTINInventoriesLoading: false,
  scanGTINProduct: null,
  scanGTINProductTemplate: null,
  gtinSize: null,
  cancelDropoff: [],
  cancelDropoffLoading: false,
  inventoriesToReview: [],
};

export const DefaultInventoryState = defaultState;

export type InventoryAction = {
  type: string;
  inventoriesLoading?: boolean;
  inventories?: Inventory[];
  lowestPriceProducts?: Inventory[];
  paginatedInventories?: {
    rows: Inventory[];
    count: number;
  };
  supplyInventories?: Inventory[];
  ordersPayoutsInventories?: Inventory[];
  calculatedFeesLoading?: boolean;
  calculatedFees?: InventoryFee | null;
  createdInventory: Inventory[];
  createdInventoryLoading: boolean;
  inventoryFilterOptionsLoading: boolean;
  inventoryFilterOptions: InventoryFilterOptions;
  acceptInventory: Inventory | null;
  acceptInventoryLoading: boolean;
  acceptRequestedInventory: Inventory | null;
  acceptRequestedInventoryLoading: boolean;
  declineInventory: Inventory | null;
  declineInventoryLoading: boolean;
  withdrawInventory: Inventory | null;
  withdrawInventoryLoading: boolean;
  acceptInventoryPrice: Inventory | null;
  acceptInventoryPriceLoading: boolean;
  declineInventoryPrice: Inventory | null;
  declineInventoryPriceLoading: boolean;
  acceptInventoryWithdrawal: Inventory | null;
  acceptInventoryWithdrawalLoading: boolean;
  declineInventoryWithdrawal: Inventory | null;
  declineInventoryWithdrawalLoading: boolean;
  inventory: Inventory | null;
  inventoryLoading: boolean;
  updatedInventory: Inventory | null;
  updateInventoryLoading: boolean;
  bulkPrintedInventory: Inventory[] | null;
  bulkPrintInventoryLoading: boolean;
  exportedInventory: Inventory[] | null;
  exportInventoryLoading: boolean;
  deletedInventory: Inventory[] | null;
  deleteInventoryLoading: boolean;
  requestInventoryPriceChangedInventory: Inventory[] | null;
  requestInventoryPriceChangeLoading: boolean;
  inventoryRequestWithdrawalInventory: Inventory[] | null;
  requestInventoryWithdrawalChangedLoading: boolean;
  transferInventoryLoading: boolean;
  acceptTransferInventoryLoading: boolean;
  scanGTINInventories: Inventory[] | null;
  scanGTINInventoriesLoading: boolean;
  scanGTINProduct: Product | null;
  scanGTINProductTemplate: ProductTemplate | null;
  gtinSize: string | null;
  cancelDropoff: Inventory[] | null;
  cancelDropoffLoading: boolean;
  inventoriesToReview: Inventory[] | null;
};

export default (
  state: InventoryState = defaultState,
  action: InventoryAction
) => {
  switch (action.type) {
    case GET_INVENTORIES:
      return {
        ...state,
        inventoriesLoading: action.inventoriesLoading,
        inventories: action.inventories,
      };
    case GET_LOWEST_PRICE_INVENTORIES:
      return {
        ...state,
        inventoriesLoading: action.inventoriesLoading,
        lowestPriceProducts: action.lowestPriceProducts,
      };
    case GET_PAGINATED_INVENTORIES:
      return {
        ...state,
        inventoriesLoading: action.inventoriesLoading,
        paginatedInventories: action.paginatedInventories,
      };
    case GET_SUPPLY_INVENTORIES:
      return {
        ...state,
        supplyInventoriesLoading: action.inventoriesLoading,
        supplyInventories: action.supplyInventories,
      };
    case GET_ORDERS_PAYOUTS_INVENTORIES:
      return {
        ...state,
        inventoriesLoading: action.inventoriesLoading,
        ordersPayoutsInventories: action.ordersPayoutsInventories,
      };
    case CALCULATE_FEES:
      return {
        ...state,
        calculatedFeesLoading: action.calculatedFeesLoading,
        calculatedFees: action.calculatedFees,
      };

    case CREATE_INVENTORY:
      return {
        ...state,
        createdInventory: action.createdInventory,
        createdInventoryLoading: action.createdInventoryLoading,
      };
    case GET_INVENTORY_FILTER_OPTIONS:
      return {
        ...state,
        inventoryFilterOptions: action.inventoryFilterOptions,
        inventoryFilterOptionsLoading: action.inventoryFilterOptionsLoading,
      };
    case ACCEPT_INVENTORY:
      return {
        ...state,
        acceptInventory: action.acceptInventory,
        acceptInventoryLoading: action.acceptInventoryLoading,
      };
    case CANCEL_DROPOFF:
      return {
        ...state,
        cancelDropoff: action.cancelDropoff,
        cancelDropoffLoading: action.cancelDropoffLoading,
      };
    case ACCEPT_REQUESTED_INVENTORY:
      return {
        ...state,
        acceptRequestedInventory: action.acceptRequestedInventory,
        acceptRequestedInventoryLoading: action.acceptRequestedInventoryLoading,
      };
    case DECLINE_INVENTORY:
      return {
        ...state,
        declineInventory: action.declineInventory,
        declineInventoryLoading: action.declineInventoryLoading,
      };
    case WITHDRAW_INVENTORY:
      return {
        ...state,
        withdrawInventory: action.withdrawInventory,
        withdrawInventoryLoading: action.withdrawInventoryLoading,
      };
    case ACCEPT_INVENTORY_PRICE:
      return {
        ...state,
        acceptInventoryPrice: action.acceptInventoryPrice,
        acceptInventoryPriceLoading: action.acceptInventoryPriceLoading,
      };
    case DECLINE_INVENTORY_PRICE:
      return {
        ...state,
        declineInventoryPrice: action.declineInventoryPrice,
        declineInventoryPriceLoading: action.declineInventoryPriceLoading,
      };
    case GET_INVENTORY:
      return {
        ...state,
        inventory: action.inventory,
        inventoryLoading: action.inventoryLoading,
      };
    case UPDATE_INVENTORY:
      return {
        ...state,
        updatedInventory: action.updatedInventory,
        updateInventoryLoading: action.updateInventoryLoading,
      };
    case PRINT_INVENTORY:
      return {
        ...state,
        bulkPrintedInventory: action.bulkPrintedInventory,
        bulkPrintInventoryLoading: action.bulkPrintInventoryLoading,
      };
    case EXPORT_INVENTORY:
      return {
        ...state,
        exportedInventory: action.exportedInventory,
        exportInventoryLoading: action.exportInventoryLoading,
      };
    case DELETE_INVENTORY:
      return {
        ...state,
        deletedInventory: action.deletedInventory,
        deleteInventoryLoading: action.deleteInventoryLoading,
      };
    case REQUEST_INVENTORY_PRICE_CHANGE:
      return {
        ...state,
        requestInventoryPriceChangedInventory:
          action.requestInventoryPriceChangedInventory,
        requestInventoryPriceChangeLoading:
          action.requestInventoryPriceChangeLoading,
      };
    case REQUEST_INVENTORY_WITHDRAWAL:
      return {
        ...state,
        requestInventoryWithdrawal: action.inventoryRequestWithdrawalInventory,
        requestInventoryWithdrawalLoading:
          action.requestInventoryWithdrawalChangedLoading,
      };
    case ACCEPT_INVENTORY_WITHDRAWAL:
      return {
        ...state,
        acceptInventoryWithdrawal: action.acceptInventoryWithdrawal,
        acceptInventoryWithdrawalLoading:
          action.acceptInventoryWithdrawalLoading,
      };
    case DECLINE_INVENTORY_WITHDRAWAL:
      return {
        ...state,
        declineInventoryWithdrawal: action.declineInventoryWithdrawal,
        declineInventoryWithdrawalLoading:
          action.declineInventoryWithdrawalLoading,
      };
    case RESET_CREATED_INVENTORY:
      return {
        ...state,
        createdInventory: action.createdInventory,
      };
    case TRANSFER_INVENTORY:
      return {
        ...state,
        transferInventoryLoading: action.transferInventoryLoading,
      };
    case ACCEPT_TRANSFER_INVENTORY:
      return {
        ...state,
        acceptTransferInventoryLoading: action.acceptTransferInventoryLoading,
      };
    case SCAN_GTIN_INVENTORY:
      return {
        ...state,
        scanGTINInventories: action.scanGTINInventories,
        scanGTINInventoriesLoading: action.scanGTINInventoriesLoading,
        scanGTINProduct: action.scanGTINProduct,
        scanGTINProductTemplate: action.scanGTINProductTemplate,
        gtinSize: action.gtinSize,
      };
    case SET_INVENTORIES_TO_ADD:
      return {
        ...state,
        inventoriesToReview: action.inventoriesToReview,
      };
    default:
      return state;
  }
};
