import { GET_MEMBERSHIP_PAYMENTS, CREATE_MEMBERSHIP_PAYMENT } from "../actions/types";

export type MembershipPayment = any;

const defaultState = {
  membershipPaymentsLoading: false,
  membershipPayments: [],
  membershipPaymentLoading: false,
  membershipPayment: null,
  createMembershipPaymantLoading: false,
  createdMembershipPaymant: null,
};

export const DefaultMembershipPaymentState = defaultState;

export interface MembershipPaymentState {
  membershipPaymentsLoading: boolean;
  membershipPayments: MembershipPayment[];
  createdMembershipPaymant: MembershipPayment | null;
  createMembershipPaymantLoading: boolean;
}

export type MembershipPaymentAction = {
  type: string;
  membershipPaymantsLoading: boolean;
  membershipPaymentLoading: boolean;
  membershipPayments: MembershipPayment[];
  membershipPayment: MembershipPayment | null;
  createdMembershipPayment: MembershipPayment | null;
  createMembershipPaymantLoading: boolean;
};

export default (
  state: MembershipPaymentState = defaultState,
  action: MembershipPaymentAction
) => {
  switch (action.type) {
    case GET_MEMBERSHIP_PAYMENTS:
      return {
        ...state,
        membershipPaymantsLoading: action.membershipPaymantsLoading,
        membershipPayments: action.membershipPayments,
      };
    case CREATE_MEMBERSHIP_PAYMENT:
      return {
        ...state,
        createProductLoading: action.createMembershipPaymantLoading,
        createdProduct: action.createdMembershipPayment,
      };
    default:
      return state;
  }
};
