import styled from "styled-components";
import RedesignStyling from "../constants/RedesignStyling";
import { Card } from "antd";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  //   justify-content: center;
  //   align-items: center;
  padding-bottom: 40px;
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
`;

export const InventoryCards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 768px) {
    & > span {
      width: 100%;
    }
  }
`;

export const ProductCard = styled(Card)`
  border-radius: 5px;
  border: 2px solid rgba(221, 221, 221, 0.6);
  cursor: pointer;
  height: calc(100% - 15px);
  .ant-card-body {
    text-align: center;
    padding: 40px;
  }
  .ant-image-img {
    max-width: 215px;
    max-height: 165px;
  }
  h2 {
    margin-top: 40px;
  }
  p {
    color: rgba(127, 128, 128, 1);
    font-size: 16px;
  }

  @media (max-width: 768px) {
    .ant-statistic-title {
      font-size: 12px;
    }
    .ant-card-body {
      text-align: center;
      padding: 20px;
    }
    h2 {
      margin-top: 15px;
    }
  }
`;

export const SizePriceCards = styled.div`
  .size-price-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 10px 20px;
  }
  .item {
    flex: 1 1 50%; /* Each item takes up 50% of the container width */
    max-width: 45%;
    text-align: left;
    background: rgba(244, 244, 244, 1);
    border-radius: 5px;
    border: 1px solid rgba(221, 221, 221, 0.6);
    padding: 10px;
    .ant-statistic-content {
      font-size: 16px;
      font-weight: 500;
    }
  }
`;
