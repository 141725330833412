import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Button,
  Drawer,
  Tooltip,
  Dropdown,
  MenuProps,
  Divider,
} from "antd";
import styled, { createGlobalStyle } from "styled-components";
import { Link } from "react-router-dom";
import { Store } from "../../redux/reducers/StoreReducer";
import { navItem, navItems } from "../../nav/navData";
import Colors from "../../constants/Colors";
import { Consigner } from "../../redux/reducers/ConsignerReducer";
import {
  DeleteOutlined,
  ExportOutlined,
  MenuOutlined,
  ProductOutlined,
} from "@ant-design/icons";
import RedesignStyling from "../../constants/RedesignStyling";
import { useAppSelector } from "../../redux/hooks";
import { AppState } from "../../redux/reducers/AppReducer";
import MembershipPackages from "./MembershipPackages";
import { ReactComponent as ProductSvg } from "../../assets/images/svg/Products.svg";
import { getMemmbershipPayments } from "../../redux/actions/membershipPaymentActions";
import { UserState } from "../../redux/reducers/UserReducer";
import { useDispatch } from "react-redux";

const { Header, Content, Footer } = Layout;

export const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');

  body {
    font-family: 'Inter', sans-serif;
  }
`;

export const HeaderLite = styled(Header)`
  height: ${RedesignStyling.HEADER_HEIGHT};
  display: flex;
  align-items: center;
  background-color: ${Colors.WHITE};
  flex: 1;
  z-index: 2;
  width: 100%;
  padding: 0 10px;
  line-height: 60px;

  ul {
    background-color: inherit;
    height: 100%;
    flex: 1;
    border-bottom: none;
    height: ${RedesignStyling.HEADER_HEIGHT};
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    content: none;
    border: none !important; // remove underline
  }
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 ${RedesignStyling.PAGE_PAD_X_MOBILE};
    max-width: 100vw;
  }
`;

const CustomMenuItem = styled(Menu.Item)`
  background-color: inherit;
  li {
    line-height: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.CONSIGNER_GREY};
    &:after {
      border-bottom: none;
    }
  }
  &.ant-menu-item-selected {
    fill: ${Colors.BLUE_MAGENTA} !important;
    stroke: ${Colors.BLUE_MAGENTA} !important;
  }
  &:hover,
  &:active {
    svg {
      stroke: ${Colors.BLUE_MAGENTA} !important;
      fill: ${Colors.BLUE_MAGENTA} !important;
      transition: stroke fill 0.3s ease;
    }
  }
`;

const LogoContainer = styled.span`
  display: flex;
  // flex: 1 1 auto;
  justify-content: start;
  align-items: center;
  background-color: inherit;
  padding-right: 10px;
  @media (max-width: 768px) {
    padding-right: 0;
  }
`;

const Logo = styled.img`
  width: 100px;
  height: auto;
  max-height: 60px;
  object-fit: contain;
  max-width: 100px;
`;

const UserName = styled.span`
  height: 100%;
  align-self: center;
  padding: 0 16px;
  min-width: 50px;
  color: ${Colors.CONSIGNER_GREY};
  order: 3;
  line-height: 25px;
  display: flex;
  flex-direction: column;
  b {
    // display: block;
    font-weight: 100;
    white-space: nowrap;
    // max-height:20px;
  }
`;

export const MenuButton = styled(MenuOutlined)`
  display: flex;
  width: 50px;
  font-size: 32px;
  order: 1;
  height: 60px;
  align-self: flex-start;
  align-items: center;
  justify-content: center;
  border: 0;
`;

const CustomLayout = styled(Layout)`
  min-height: calc(100vh - ${RedesignStyling.HEADER_HEIGHT});
  padding-top: 0;
  background: ${Colors.CONSIGNER_BG};
  @media (max-width: 768px) {
    padding-top: ${RedesignStyling.HEADER_HEIGHT};
  }
`;

const PackagesMenu = styled.div`
  width: 250px;
  .products {
    font-size: 16px;
    svg {
      position: relative;
      top: 4px;
    }
  }
  .ant-dropdown-menu-item {
    padding: 12px;
  }
  svg {
    margin-right: 5px;
  }
  .ant-divider-horizontal.ant-divider-with-text-center::before,
  .ant-divider-horizontal.ant-divider-with-text-center::after {
    border-top: 1px solid red;
  }
  .upgrade-btn {
    button {
      background: ${Colors.CONSIGNER_BLUE};
      border-radius: 5px;
      color: white;
      height: 42px;
      width: 100%;
    }
  }
  .delete-acc {
    a {
      color: rgba(250, 67, 67, 1);
      border: none;
      font-size: 16px;
    }
  }
`;

//types
interface Props {
  children: JSX.Element;
  navItems: navItem[];
  logo: string;
  store: Store;
  consigner: Consigner;
}

interface DrawerProps {
  showMenu: boolean;
  toggleMenu: any;
  navItems: any[];
}
type renderMenuItemProp = (navItem: navItem) => JSX.Element;

export const renderMenuItem = (navItem: navItem): JSX.Element => (
  <CustomMenuItem
    key={navItem.path}
    icon={navItem.icon}
    style={{
      lineHeight: "initial",
      display: "flex",
      alignItems: "center",
    }}
  >
    <Link target="_blank" to={navItem.path} onClick={navItem.onClick}>
      {navItem.label}
    </Link>
  </CustomMenuItem>
);

export const renderSubMenuItem = (
  navItem: navItem,
  renderMenuItem: renderMenuItemProp
) => (
  //rendering a submenu, adds a submenu wrapper and create navitems inside
  <Menu.SubMenu
    data-testid="subMenuItem"
    key={navItem.path}
    icon={navItem.icon}
    title={navItem.label}
  >
    {navItem.subMenu?.map((subMenuItem) => renderMenuItem(subMenuItem))}
  </Menu.SubMenu>
);

export const renderNavItem = (
  navItem: navItem,
  renderMenuItem: renderMenuItemProp,
  renderSubMenuItem: (
    navItem: navItem,
    renderMenuItem: renderMenuItemProp
  ) => JSX.Element
): JSX.Element => {
  //if has a submenu, will render a submenu
  return navItem.subMenu
    ? renderSubMenuItem(navItem, renderMenuItem)
    : renderMenuItem(navItem);
};

export const MobileDrawer = (props: DrawerProps) => {
  const { showMenu, toggleMenu, navItems } = props;
  return (
    <Drawer visible={showMenu} onClose={toggleMenu} placement="left">
      <Menu
        mode="vertical"
        theme="light"
        defaultSelectedKeys={["0"]}
        selectedKeys={[location.pathname]}
      >
        {navItems.map((navItem, idx) =>
          renderNavItem(navItem, renderMenuItem, renderSubMenuItem)
        )}
      </Menu>
      <div
        style={{
          position: "fixed",
          cursor: "pointer",
          bottom: "2rem",
          left: "50%",
          transform: "translateX(-50%)",
          fontWeight: "600",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Tooltip title="Check New Updates">
          <a
            href="https://app.getbeamer.com/trace/en"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundColor: "#67abf4",
              color: "white",
              padding: "10px 20px",
              borderRadius: "10px",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            Another Updates
            <ExportOutlined style={{ marginLeft: "0.5em" }} />
          </a>
        </Tooltip>
      </div>
    </Drawer>
  );
};

const ConsignerNavLayout = (props: Props) => {
  const { children, navItems, logo, store, consigner } = props;
  const [showMenu, setShowMenu] = useState(false);
  const [membershipModal, setMembershipModal] = useState(false);
  const { isMobile }: AppState = useAppSelector((state) => state.AppReducer);
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const { membershipPayments, membershipPaymentsLoading } = useAppSelector(
    (state) => state.MembershipPaymentReducer
  );
  const [filters, setFilters] = useState<any>({
    consignerId: Number(dbUser.id),
  });

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMemmbershipPayments(filters));
  }, []);

  const menu = (
    <PackagesMenu>
      <Menu>
        <Menu.Item className="products">
          <ProductSvg />
          Products
        </Menu.Item>
        <Divider style={{ margin: "0" }} />
        <Menu.Item className="upgrade-btn">
          <Button onClick={() => setMembershipModal(true)}>Upgrade</Button>
        </Menu.Item>
        <Divider style={{ margin: "0" }} />
        <Menu.Item className="delete-acc">
          <a>
            <DeleteOutlined />
            Delete Account
          </a>
        </Menu.Item>
      </Menu>
    </PackagesMenu>
  );

  return (
    <Layout style={{ overflowY: isMobile ? "scroll" : "initial" }}>
      <HeaderLite
        className="header"
        style={{
          justifyContent: isMobile ? "space-between" : "flex-start",
        }}
      >
        <LogoContainer style={{ order: isMobile ? "2" : "1" }}>
          <Logo src={logo} />
        </LogoContainer>

        {isMobile ? (
          <MenuButton onClick={toggleMenu} />
        ) : (
          <Menu
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={["0"]}
            selectedKeys={[location.pathname]}
            style={{ order: 2 }}
          >
            {navItems.map((navItem, idx) => (
              <CustomMenuItem
                key={navItem.path}
                icon={navItem.icon}
                style={{
                  lineHeight: "initial",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0 15px",
                }}
              >
                <Link
                  data-testid="menuItemLink"
                  to={navItem.path}
                  onClick={navItem.onClick}
                >
                  {navItem.label}
                </Link>
              </CustomMenuItem>
            ))}
          </Menu>
        )}

        <MobileDrawer
          toggleMenu={toggleMenu}
          showMenu={showMenu}
          navItems={navItems}
        />
        <UserName>{isMobile ? "" : `ID: ${consigner.id}`}</UserName>
        <Dropdown
          overlay={menu}
          placement="bottomRight"
          arrow={{ pointAtCenter: true }}
        >
          <a style={{ order: 3, color: Colors.CONSIGNER_BLUE }}>
            {membershipPayments.length
              ? membershipPayments[0].consignerType.tierName
              : "Plans"}
          </a>
        </Dropdown>
        <MembershipPackages
          membershipModal={membershipModal}
          setMembershipModal={setMembershipModal}
          currentPackage={membershipPayments[0]}
        />
      </HeaderLite>
      <CustomLayout>
        <GlobalStyle />
        <Content>{children}</Content>
        {process.env.REACT_APP_TYPE != "consigner" && (
          <Footer style={{ textAlign: "center" }}>
            <>
              Powered By{" "}
              <a
                href="https://fulltrace.co/"
                target="_blank"
                rel="noopener noreferrer"
              >
                TRACE
              </a>{" "}
            </>
          </Footer>
        )}
      </CustomLayout>
    </Layout>
  );
};

export default ConsignerNavLayout;
