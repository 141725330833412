import {
  Button,
  Dropdown,
  Form,
  Image,
  Input,
  Menu,
  Modal,
  Select,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import { ColumnTypes } from "../consignerScreens/ConsignerInventory";
import { countries } from "../components/Common/CountryCode";
import {
  Container,
  PaymentForm,
  ProductNameImage,
} from "../styles/SupplyStepThree";
import {
  bulkSupplyInventory,
  createSupplyInventoryOrder,
} from "../redux/actions/inventoryActions";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useHistory } from "react-router-dom";
import { UserState } from "../redux/reducers/UserReducer";
import Colors from "../constants/Colors";

const { Option } = Select;

const visibleColumns: ColumnTypes[number][] = [
  {
    title: "Size",
    key: "option1Value",
    dataIndex: "option1Value",
    render: (_: any, record: any) => record?.option1Value ?? null,
  },
  {
    title: "Quantity",
    key: "option2Value",
    dataIndex: "option2Value",
    render: (_: any, record: any) => record.quantity,
  },
  {
    title: "Price",
    key: "price",
    dataIndex: "price",
    render: (_: any, record: any) => "$" + record.price,
  },
];

const SupplyStepThree = (props: any) => {
  const { inventory, totalPrice } = props;
  const [form] = Form.useForm();
  const [inventories, setInventories]: any = useState([]);
  const [sizeAndQuantity, setSizeAndQuantity] = useState("");
  const [minimalView, setMinimalView] = useState(false);
  const { store } = useAppSelector((state) => state.StoreReducer);
  const { dbUser }: UserState = useAppSelector((state) => state.UserReducer);
  const [supplyDetails, setSupplyDetails] = useState<{
    email: string;
    address_line_1: string;
    address_line_2: string;
    postal: string;
    country: string;
    city: string;
    shipment_location: string;
    consigner_buy: boolean;
  }>({
    email: "",
    address_line_1: "",
    address_line_2: "",
    postal: "",
    country: "",
    city: "",
    shipment_location: "Store Location",
    consigner_buy: process.env.REACT_APP_TYPE === "consigner",
  });
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    const inventoryMap = inventory.reduce((acc, inventory) => {
      // Create a unique key combining option1Value and price
      const key = `${inventory.option1Value}-${inventory.price}`;

      if (acc[key]) {
        acc[key].quantity += 1;
      } else {
        acc[key] = { ...inventory, quantity: 1 };
      }
      return acc;
    }, {});

    const finalInventories = Object.values(inventoryMap);
    const sizeQuantity = finalInventories.reduce(
      (accumulator: string, currentValue: any) => {
        const itemString = `${currentValue.option1Value} (${currentValue.quantity})`;
        return accumulator ? `${accumulator} - ${itemString}` : itemString;
      },
      ""
    );
    setSizeAndQuantity(sizeQuantity);
    setInventories(Object.values(inventoryMap));
  }, []);

  const handleSupplyInventory = async () => {
    Modal.confirm({
      title: "Are you sure you want to supply these items?",
      content:
        "This item is currently active and supplying it will change it's status.",
      okText: "Yes",
      // okType: "danger",
      cancelText: "No",
      onOk: async () => {
        const order = {
          order: {
            name: `supply_${inventory[0].id}`,
            total: totalPrice,
            storeId: store.id,
          },
          inventories: inventory.map((inv) => ({
            ...inv,
            consignerId:
              process.env.REACT_APP_TYPE === "consigner" && dbUser?.id,
            supplyDetails,
          })),
        };
        // const data = inventory.map((inv) => ({
        //   ...inv,
        //   supplyDetails,
        // }));
        await dispatch(createSupplyInventoryOrder(order));
        history.push("/supply");
      },
    });
  };

  return (
    <div>
      <Container>
        <div className="container-item">
          <h2>Summary Order</h2>
          <div className="product-summary">
            <ProductNameImage>
              <div className="inventory-image">
                <Image
                  src={inventory.productImage}
                  alt=""
                  style={{ height: "auto", width: "150px" }}
                ></Image>
              </div>
              <div className="inventory-detail">
                <h3>{inventory.productTitle}</h3>
                <p>{inventory.productSku}</p>
              </div>
            </ProductNameImage>
            <Table
              dataSource={
                !minimalView ? inventories : [{ option1Value: sizeAndQuantity }]
              }
              columns={visibleColumns}
              pagination={false}
              summary={(pageData: any) => {
                const total = inventories.reduce(
                  (accumulator, currentValue) =>
                    accumulator +
                    currentValue.quantity * Number(currentValue.price),
                  0
                );
                const units = inventories.reduce(
                  (accumulator, currentValue) =>
                    accumulator + currentValue.quantity,
                  0
                );
                return (
                  <>
                    <Table.Summary.Row>
                      <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                      <Table.Summary.Cell index={2}>
                        ${total}
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={3}>{units}</Table.Summary.Cell>
                    </Table.Summary.Row>
                  </>
                );
              }}
            />
            <div className="minimal-view">
              <Button onClick={() => setMinimalView(!minimalView)}>
                {minimalView ? "Detailed View" : "Minimal View"}
              </Button>
            </div>
          </div>
          {process.env.REACT_APP_TYPE === "consigner" && (
            <div style={{ marginTop: "15px", display: "flex", gap: "10px" }}>
              <h2>Pick up here -</h2>
              <h2 style={{ color: `${Colors.CONSIGNER_BLUE}` }}>
                Store Location
              </h2>
            </div>
          )}
        </div>
        <div className="container-item">
          <h2>Payment Details</h2>
          <p>Complete your order by providing payment details</p>
          <PaymentForm>
            <Form
              form={form}
              name="dependencies"
              autoComplete="off"
              style={{ maxWidth: 600 }}
              layout="vertical"
              onFinish={handleSupplyInventory}
            >
              <Form.Item
                label="Email Address"
                name="email"
                rules={[{ required: true, message: "Please input your email" }]}
              >
                <Input
                  placeholder="Email Address"
                  value={supplyDetails.email}
                  type="email"
                  onChange={(e) =>
                    setSupplyDetails({
                      ...supplyDetails,
                      email: e.target.value,
                    })
                  }
                />
              </Form.Item>

              <Form.Item
                label="Address Line 1"
                name="address1"
                rules={[
                  { required: true, message: "Please input your address" },
                ]}
              >
                <Input
                  placeholder="Your Address"
                  type="text"
                  onChange={(e) =>
                    setSupplyDetails({
                      ...supplyDetails,
                      address_line_1: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <Form.Item label="Address Line 2" name="address2">
                <Input
                  placeholder="(optional)"
                  type="text"
                  onChange={(e) =>
                    setSupplyDetails({
                      ...supplyDetails,
                      address_line_2: e.target.value,
                    })
                  }
                />
              </Form.Item>
              <div style={{ display: "flex", gap: "10px" }}>
                <Form.Item
                  label="Postal Number"
                  name="postal"
                  rules={[{ required: true, message: "Postal code req." }]}
                >
                  <Input
                    placeholder="092138"
                    type="text"
                    value={supplyDetails.postal}
                    onChange={(e) =>
                      setSupplyDetails({
                        ...supplyDetails,
                        postal: e.target.value,
                      })
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Country"
                  name="country"
                  rules={[{ required: true, message: "Country req." }]}
                >
                  <Select
                    placeholder="Select Country"
                    allowClear
                    onChange={(value) =>
                      setSupplyDetails({
                        ...supplyDetails,
                        country: value,
                      })
                    }
                  >
                    {countries.map((country) => (
                      <Option value={country.name} key={country.code}>
                        {country.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[{ required: true, message: "City req." }]}
                >
                  <Input
                    placeholder="Enter City"
                    type="text"
                    onChange={(e) =>
                      setSupplyDetails({
                        ...supplyDetails,
                        city: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <Button className="pay-btn" htmlType="submit">
                  Pay - ${totalPrice}
                </Button>
              </Form.Item>
            </Form>
          </PaymentForm>
        </div>
      </Container>
    </div>
  );
};

export default SupplyStepThree;
