import { combineReducers } from "redux";
import UserReducer from "./UserReducer";
import ProductReducer from "./ProductReducer";
import InventoryReducer from "./InventoryReducer";
import InventoryLogReducer from "./InventoryLogReducer";
import EmployeeReducer from "./EmployeeReducer";
import ConsignerReducer from "./ConsignerReducer";
import OrderReducer from "./OrderReducer";
import PayoutReducer from "./PayoutReducer";
import StoreReducer from "./StoreReducer";
import ConsignerTypeReducer from "./ConsignerTypeReducer";
import ProductTemplateReducer from "./ProductTemplateReducer";
import SubLocationReducer from "./SubLocationReducer";
import ScanReducer from "./ScanReducer";
import ChequeReducer from "./ChequeReducer";
import AppReducer from "./AppReducer";
import MembershipPaymentReducer from "./MembershipPaymentReducer";

export default combineReducers({
  UserReducer,
  ProductReducer,
  ProductTemplateReducer,
  InventoryReducer,
  InventoryLogReducer,
  EmployeeReducer,
  ConsignerReducer,
  OrderReducer,
  PayoutReducer,
  StoreReducer,
  ConsignerTypeReducer,
  SubLocationReducer,
  ChequeReducer,
  AppReducer,
  ScanReducer,
  MembershipPaymentReducer,
});
