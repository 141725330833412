import styled from "styled-components";
import RedesignStyling from "../constants/RedesignStyling";
import { Breadcrumb, Table } from "antd";

export const Container = styled.div`
  padding-bottom: 40px;
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
  .product {
    display: flex;
    gap: 40px;
    //   justify-content: center;
    //   align-items: center;
    & > div {
      width: 50%;
    }
  }
  .product-image {
    background: white;
    padding: 50px;
    height: fit-content;
  }
  .product-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    h3 {
      font-size: 22px;
      font-weight: 700;
    }
    p {
      font-size: 16px;
      color: rgba(102, 102, 102, 1);
      letter-spacing: 0.3px;
    }
    .checkout-btn {
      width: 100%;
      background: rgba(51, 51, 51, 1);
      color: white;
      border-radius: 5px;
      height: 50px;
    }
  }
  .ant-table-thead > tr > th {
    background: #f3f4f6;
    font-weight: 400;
    padding-bottom: 3px;
    color: rgba(102, 102, 102, 1);
  }
  .ant-table table {
    border-collapse: collapse;
  }
  tfoot {
    border: 1px solid rgba(221, 221, 221, 1);
    background: rgba(249, 249, 249, 1);
    tr td:nth-child(3),
    tr td:nth-child(4) {
      text-align: center;
    }
  }

  .ant-table-tbody {
    border: 1.5px solid rgba(221, 221, 221, 1);
    border-radius: 5px;
  }
  tfoot {
    font-weight: 600;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: static;
  }
  .ant-table-row-expand-icon-cell {
    padding: 0;
  }
  .ant-table-expanded-row {
    & > .ant-table-cell {
      padding: 0;
    }
  }
  .ant-table-row-expand-icon-cell {
    width: 30px;
  }
  .ant-table-thead > tr > th:nth-child(3),
  .ant-table-thead > tr > th:nth-child(4) {
    text-align: center;
  }

  .ant-table-tbody > tr > td:nth-child(3),
  .ant-table-tbody > tr > td:nth-child(4) {
    text-align: center;
  }

  .ant-table-cell {
    // width: 33.3%;
    border-bottom: 1px solid rgba(221, 221, 221, 1);
    .minus,
    .plus {
      padding: 0 10px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      //   font-size: 16px;
    }
    input {
      height: 34px;
      width: 80px;
      text-align: center;
      font-size: 26px;
      border: 1px solid #ddd;
      border-radius: 4px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  @media (max-width: 768px) {
    .product {
      display: flex;
      flex-direction: column;
      & > div {
        width: 100%;
      }
    }
  }
`;

export const InventoryCards = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

export const CustomBreadcrumb = styled(Breadcrumb)`
  color: rgba(116, 116, 116, 1);
  li:last-child {
    color: rgba(51, 51, 51, 1);
    font-weight: 500;
  }
`;

export const StepTwoTable = styled(Table)`
  .ant-table-cell .in-stock {
    padding: 0.5px 5px;
    font-size: 12px;
    background: rgba(244, 244, 244, 1);
    border-radius: 50%;
  }
  .ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table,
  .ant-table-tbody
    > tr
    > td
    > .ant-table-expanded-row-fixed
    > .ant-table-wrapper:only-child
    .ant-table {
    margin: 0;
  }
  .ant-table-expand-icon-col {
    width: 30px;
  }
  .ant-table-cell {
    svg {
      font-size: 10px;
      position: relative;
      top: -3px;
    }
  }
  .ant-table-row td:nth-child(2) {
    padding: 16px 16px 16px 0;
  }
`;

export const ExpendedTable = styled(Table)`
  .ant-table-tbody > tr > td:nth-child(1) {
    width: 10px;
  }
`;
