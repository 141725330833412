import React, { useState, useEffect } from "react";
import { Form, Select, Typography, Row, Col, Spin } from "antd";
import ButtonComponent from "../../../components/Button/button";
import {
  StyledSelect,
  TitlePTag,
  ColorPTag,
  HrDiv,
  ButtonDiv,
  ResponsiveStore,
} from "../../../styles/formStyles";

const { Option } = Select;
const { Title } = Typography;
interface StoreInfoProp {
  onFinish: (data: object, step: number) => void;
}
export default function StoreInfo({ onFinish }: StoreInfoProp) {
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const handleNextClick = () => {
    setLoading(true);
    form.submit(); // Submit the form to trigger onFinish
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 990);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  if (loading) return <Spin />;

  return (
    <>
      <ResponsiveStore>
        <Form
          form={form}
          name="validate_other"
          onFinish={(values) => onFinish(values, 3)} // Pass step as 1 when calling onFinish
          style={{
            maxWidth: 600,
            padding: `${isMobile ? "1rem" : "0rem"}`,
            paddingTop: "2rem",
          }}
        >
          <Row gutter={[10, 10]} style={{ marginTop: "2rem" }}>
            <Col xs={24} sm={24} md={24} xl={10} lg={10}>
              <TitlePTag>Store Type</TitlePTag>
              <Form.Item
                name="storeType"
                rules={[
                  { required: true, message: "Please select a store type" },
                ]}
              >
                <StyledSelect bordered={false} placeholder="Store Type">
                  {/* <Option value="luxury">Luxury</Option> */}
                  <Option value="snickers">Sneakers</Option>
                  <Option value="luxury">Luxury</Option>
                </StyledSelect>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} xl={10} lg={10}>
              <TitlePTag>Currency</TitlePTag>
              <Form.Item
                name="currency"
                rules={[
                  { required: true, message: "Please select a currency" },
                ]}
              >
                <StyledSelect
                  bordered={false}
                  placeholder="Store Currency"
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.children || "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Option value="USD">USD</Option>
                  <Option value="CAD">CAD</Option>
                  <Option value="EUR">EUR</Option>
                  <Option value="AED">AED</Option>
                  <Option value="ALL">ALL</Option>
                  <Option value="AMD">AMD</Option>
                  <Option value="AOA">AOA</Option>
                  <Option value="ARS">ARS</Option>
                  <Option value="AUD">AUD</Option>
                  <Option value="AZN">AZN</Option>
                  <Option value="BAM">BAM</Option>
                  <Option value="BBD">BBD</Option>
                  <Option value="BGN">BGN</Option>
                  <Option value="BHD">BHD</Option>
                  <Option value="BMD">BMD</Option>
                  <Option value="BOB">BOB</Option>
                  <Option value="BRL">BRL</Option>
                  <Option value="BWP">BWP</Option>
                  <Option value="BYN">BYN</Option>
                  <Option value="CAD">CAD</Option>
                  <Option value="CDF">CDF</Option>
                  <Option value="CHF">CHF</Option>
                  <Option value="CLP">CLP</Option>
                  <Option value="CNY">CNY</Option>
                  <Option value="COP">COP</Option>
                  <Option value="CRC">CRC</Option>
                  <Option value="CVE">CVE</Option>
                  <Option value="CZK">CZK</Option>
                  <Option value="DKK">DKK</Option>
                  <Option value="DOP">DOP</Option>
                  <Option value="DZD">DZD</Option>
                  <Option value="EGP">EGP</Option>
                  <Option value="EUR">EUR</Option>
                  <Option value="FJD">FJD</Option>
                  <Option value="GBP">GBP</Option>
                  <Option value="GEL">GEL</Option>
                  <Option value="GHS">GHS</Option>
                  <Option value="GIP">GIP</Option>
                  <Option value="GTQ">GTQ</Option>
                  <Option value="GYD">GYD</Option>
                  <Option value="HKD">HKD</Option>
                  <Option value="HNL">HNL</Option>
                  <Option value="HRK">HRK</Option>
                  <Option value="HUF">HUF</Option>
                  <Option value="IDR">IDR</Option>
                  <Option value="ILS">ILS</Option>
                  <Option value="IMP">IMP</Option>
                  <Option value="INR">INR</Option>
                  <Option value="IQD">IQD</Option>
                  <Option value="ISK">ISK</Option>
                  <Option value="JMD">JMD</Option>
                  <Option value="JOD">JOD</Option>
                  <Option value="JPY">JPY</Option>
                  <Option value="KES">KES</Option>
                  <Option value="KGS">KGS</Option>
                  <Option value="KHR">KHR</Option>
                  <Option value="KRW">KRW</Option>
                  <Option value="KWD">KWD</Option>
                  <Option value="KYD">KYD</Option>
                  <Option value="KZT">KZT</Option>
                  <Option value="LAK">LAK</Option>
                  <Option value="LBP">LBP</Option>
                  <Option value="LKR">LKR</Option>
                  <Option value="LYD">LYD</Option>
                  <Option value="MAD">MAD</Option>
                  <Option value="MDL">MDL</Option>
                  <Option value="MGA">MGA</Option>
                  <Option value="MKD">MKD</Option>
                  <Option value="MMK">MMK</Option>
                  <Option value="MNT">MNT</Option>
                  <Option value="MOP">MOP</Option>
                  <Option value="MRU">MRU</Option>
                  <Option value="MUR">MUR</Option>
                  <Option value="MVR">MVR</Option>
                  <Option value="MWK">MWK</Option>
                  <Option value="MXN">MXN</Option>
                  <Option value="MYR">MYR</Option>
                  <Option value="MZN">MZN</Option>
                  <Option value="NAD">NAD</Option>
                  <Option value="NGN">NGN</Option>
                  <Option value="NIO">NIO</Option>
                  <Option value="NOK">NOK</Option>
                  <Option value="NZD">NZD</Option>
                  <Option value="OMR">OMR</Option>
                  <Option value="PAB">PAB</Option>
                  <Option value="PEN">PEN</Option>
                  <Option value="PGK">PGK</Option>
                  <Option value="PHP">PHP</Option>
                  <Option value="PKR">PKR</Option>
                  <Option value="PLN">PLN</Option>
                  <Option value="PYG">PYG</Option>
                  <Option value="QAR">QAR</Option>
                  <Option value="RON">RON</Option>
                  <Option value="RSD">RSD</Option>
                  <Option value="RUB">RUB</Option>
                  <Option value="RWF">RWF</Option>
                  <Option value="SAR">SAR</Option>
                  <Option value="SEK">SEK</Option>
                  <Option value="SGD">SGD</Option>
                  <Option value="SZL">SZL</Option>
                  <Option value="THB">THB</Option>
                  <Option value="TJS">TJS</Option>
                  <Option value="TMT">TMT</Option>
                  <Option value="TND">TND</Option>
                  <Option value="TRY">TRY</Option>
                  <Option value="TTD">TTD</Option>
                  <Option value="TWD">TWD</Option>
                  <Option value="TZS">TZS</Option>
                  <Option value="UAH">UAH</Option>
                  <Option value="UGX">UGX</Option>
                  <Option value="USD">USD</Option>
                  <Option value="UYU">UYU</Option>
                  <Option value="UZS">UZS</Option>
                  <Option value="VEF">VEF</Option>
                  <Option value="VND">VND</Option>
                  <Option value="XAF">XAF</Option>
                  <Option value="XCD">XCD</Option>
                  <Option value="XOF">XOF</Option>
                  <Option value="ZAR">ZAR</Option>
                  <Option value="ZMW">ZMW</Option>
                  <Option value="ZWD">ZWD</Option>
                </StyledSelect>
              </Form.Item>
            </Col>
          </Row>

          <ButtonDiv>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24, xl: 170 }}>
              <Col xs={24} lg={12} xl={12} style={{ marginTop: "1rem" }}>
                <ColorPTag>
                  Already have an account? <a href="">Sign in</a>
                </ColorPTag>
              </Col>

              <HrDiv></HrDiv>
              <Col xs={24} lg={12} xl={12}>
                <Row gutter={[10, 5]}>
                  <Col xs={4} lg={12} xl={12}>
                    <ButtonComponent
                      text={isMobile ? "Back" : "Cancel"}
                      style={{
                        backgroundColor: "#DDDDDD",
                        color: "black",
                        height: "60px",
                        padding: "0rem 2rem",
                        // marginLeft: "4rem",
                      }}
                      onClick={() => onFinish({}, 1)}
                    />
                  </Col>
                  {/* <Col xs={2} lg={1} xl={1} /> Spacer Column */}
                  <Col xs={17} lg={12} xl={12}>
                    <ButtonComponent
                      text={isMobile ? "Get Started" : "Next"}
                      style={{
                        backgroundColor: "#A49FFB",
                        color: "white",
                        height: "60px",
                        padding: "0rem 2rem",
                        // marginLeft: "4rem",
                      }}
                      disabled={loading}
                      onClick={handleNextClick}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </ButtonDiv>
        </Form>
      </ResponsiveStore>
    </>
  );
}
