import styled from "styled-components";
import { Select, Typography, Card, Layout } from "antd";
const { Title } = Typography;

export const StyledSelect = styled(Select)`
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  width: 100%;
  padding: 0.4rem 0;
`;

export const TitlePTag = styled.p`
  margin-bottom: 4px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
`;

export const ColorPTag = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 14px;
  @media only screen and (max-width: 990px) {
    margin: 0.5rem 0.5rem 2rem 0.5rem;
  }
`;

export const HrDiv = styled.hr`
  display: none;
  @media only screen and (max-width: 990px) {
    display: block;
    width: 100%;
    height: 1px;
    background: #dddddd;
    margin-bottom: 1rem;
  }
`;

export const ButtonDiv = styled.div`
  position: absolute;
  bottom: 5rem;

  @media only screen and (max-width: 2560px) {
    bottom: 5rem;
  }
  @media only screen and (max-width: 1440px) {
    bottom: 3rem;
  }
  @media only screen and (max-width: 990px) {
    bottom: 0rem;
    width: 95%;
  }

  @media only screen and (max-width: 990px) {
    margin-top: 1rem;
    .ant-col {
      width: 100% !important;
      max-width: 100% !important;
    }
    .ant-row {
      margin: 0.5rem;
    }
  }
`;

export const ButtonDivFirst = styled.div`
  margin-top: 3rem;
  position: absolute;
  bottom: 0rem;
  @media only screen and (max-width: 2560px) {
    bottom: 5rem;
  }
  @media only screen and (max-width: 1440px) {
    bottom: 3rem;
  }
  @media only screen and (max-width: 1024px) {
    bottom: 3rem;
    width: 50%;
  }
  @media only screen and (max-width: 990px) {
    bottom: -5rem;
    width: 75%;
  }
  @media only screen and (max-width: 768px) {
    bottom: -10rem;
    width: 75%;
    .ant-col {
      width: 100% !important;
      max-width: 100% !important;
    }
    .ant-row {
      margin: 0.5rem;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 90%;
    padding: 0.5rem;
  }
  @media only screen and (max-width: 425px) {
    width: 100%;
    padding: 1.8rem 0 0.5rem 0.5rem;
  }
  @media only screen and (max-width: 375px) {
    width: 100%;
    padding: 0.5rem;
    bottom: -7rem;
  }
`;
export const BigScreenView = styled.div`
  @media only screen and (max-width: 990px) {
    display: none;
  }
`;

export const MobileView = styled.div`
  display: none;

  @media only screen and (max-width: 990px) {
    display: block;
  }
`;
export const Responsive = styled.div`
  @media only screen and (max-width: 990px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 768px) {
    .ant-col {
      width: 100% !important;
      max-width: 100% !important;
    }
    .ant-row {
      margin: 0.5rem;
    }
  }
`;

export const ResponsiveStore = styled.div`
  @media only screen and (max-width: 990px) {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden !important;
    box-sizing: border-box !important;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden !important;
    box-sizing: border-box !important;
  }
`;
// export const CenterDiv = styled.div`
// display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     width:100%;
// `

export const ResponsiveTitle = styled(Title)`
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    margin-left: 0.5rem;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    gap: 0rem !important;
  }
`;

export const LeftContainer = styled.div`
  padding: 1rem;
  width: 50%;

  @media screen and (max-width: 990px) {
    display: none;
  }
`;

export const RightContainer = styled.div`
  padding-left: 1rem;
  padding-right: 3rem;
  width: 50%;

  @media screen and (max-width: 990px) {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden !important;
    box-sizing: border-box !important;
  }
  @media screen and (max-width: 786px) {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    overflow-x: hidden !important;
    box-sizing: border-box !important;
  }
`;

export const ResponsiveCenter = styled.div`
  @media screen and (max-width: 990px) {
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const CardContainer = styled(Card)`
  width: 100%;
  height: 95vh;
  background-color: #f4f4f4;
  border-radius: 12px;
`;

export const TraceText = styled.h1`
  color: #685ff9;
  font-size: 40px;
  font-style: italic;
  font-weight: 500;
`;

export const TextHeading = styled.h1`
  font-family: "Inter", sans-serif;
  font-weight: 900;
  font-size: 50px;
  line-height: 55px;
  padding-top: 3rem;
`;

export const TextDescription = styled.p`
  color: #989898;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  padding-top: 1rem;
`;

export const NavigationItem = styled.div`
  width: 10rem;
  height: 8px;
  border-radius: 10px;
  margin-top: 0.5rem;

  @media screen and (max-width: 990px) {
    width: 8rem;
    height: 6px;
  }
`;

export const ColorPTagFirst = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;

  @media screen and (max-width: 990px) {
    font-weight: 500;
    font-size: 14px;
  }
`;

export const ColorStoreTag = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 16px;

  @media screen and (max-width: 990px) {
    font-weight: 600;
    font-size: 14px;
  }
`;

export const BarsDiv = styled.div`
  display: flex;
  gap: 2rem;
  margin-left: 1rem;

  @media screen and (max-width: 990px) {
    width: 100%;
    padding: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    // marging-right:-1rem;
  }
`;

export const SignUpTag = styled.p`
  padding-top: 2rem;
  font-weight: 600;
  font-size: 32px;

  @media screen and (max-width: 990px) {
    display: none;
  }
`;

export const ResponsiveLayout = styled(Layout)`
  padding: 24px 10px;
  border-radius: 12px;
  color: "black";

  @media only screen and (max-width: 990px) {
    padding: 24px 0;
    border-radius: 0px !important;
  }
`;

export const MobileTitle = styled.p`
  display: none;

  @media only screen and (max-width: 990px) {
    display: block;
    text-align: center;
    font-size: 40px;
    font-style: italic;
    font-weight: 500;
    color: #333333;
    margin-bottom: -0.1rem;
  }
`;

export const ResponsiveText = styled.p`
  display: none;

  @media only screen and (max-width: 990px) {
    display: block;
    margin: 0.5rem;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #989898;
  }
`;
