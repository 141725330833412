import styled from "styled-components";
import RedesignStyling from "../constants/RedesignStyling";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
  //   justify-content: center;
  //   align-items: center;
  padding-bottom: 40px;
  padding: ${RedesignStyling.PAGE_PADDING_ADMIN};
  .container-item {
    flex: 1;
    h2 {
      text-align: left;
    }
  }
  .ant-table-wrapper {
    thead > tr > th {
      background: white;
    }
  }
  .product-summary {
    background: white;
    padding: 20px;
    border-radius: 10px;
  }
  .minimal-view {
    text-decoration: underline;
    text-align: center;
    margin-top: 20px;
    .ant-btn {
      border: none;
      text-decoration: underline;
      border-bottom: 1px solid black;
      padding: 0;
      height: 25px;
      font-weight: 600;
      font-size: 16px;
    }
    .ant-btn:active,
    .ant-btn:focus,
    .ant-btn:hover {
      color: black;
    }
  }
  .pay-btn {
    width: 100%;
    margin-top: 40px;
    background: rgba(51, 51, 51, 1);
    color: white;
    border-radius: 5px;
    height: 50px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ProductNameImage = styled.div`
  display: flex;
  gap: 20px;
  .inventory-image {
    padding: 20px;
  }
  .inventory-detail {
    h3 {
      font-weight: 500;
      font-size: 18px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const PaymentForm = styled.div`
  margin-top: 40px;
  input {
    padding: 10px;
    border-radius: 5px;
  }
  label {
    font-weight: 500;
  }
  .ant-form-item {
    width: 100%;
    .ant-select-selector {
      height: 42px;
      padding: 5px 11px;
      border-radius: 5px;
    }
  }
`;
