import {
  GET_STORE,
  UPDATE_STORE,
  CREATE_STORE,
  GET_STORE_FOR_DOMAIN,
  UPDATE_STORE_SHOP,
  RESYNC_ALL_INVENTORY,
  CREATE_NEW_STORE,
  BOOK_DEMO,
} from "../actions/types";

export type Store = any;

export interface StoreState {
  store: Store | null;
  storeLoading: boolean;
  updateStore: Store | null;
  updateStoreLoading: boolean;
  createStore: Store | null;
  createNewStore: Store | null;
  createStoreLoading: boolean;
  updatingStoreShop: boolean;
  createdSuccess: boolean;
  resyncAllInventoryLoading: boolean;
}

const defaultState = {
  store: null,
  storeLoading: false,
  updateStore: null,
  updateStoreLoading: false,
  createStore: null,
  createNewStore: null,
  createStoreLoading: false,
  updatingStoreShop: false,
  createdSuccess: false,
  resyncAllInventoryLoading: false,
};

export const DefaultConsignerTypeState = defaultState;

export type StoreAction = {
  type: string;
  store: Store | null;
  storeLoading: boolean;
  updateStore: Store | null;
  updateStoreLoading: boolean;
  createStore: Store | null;
  createNewStore: Store | null;
  createStoreLoading: boolean;
  updatingStoreShop: boolean;
  createdSuccess: boolean;
  resyncAllInventoryLoading: boolean;
};

export default (state: StoreState = defaultState, action: StoreAction) => {
  switch (action.type) {
    case GET_STORE:
      return {
        ...state,
        store: action.store,
        storeLoading: action.storeLoading,
      };
    case UPDATE_STORE:
      return {
        ...state,
        updateStore: action.updateStore,
        updateStoreLoading: action.updateStoreLoading,
      };
    case UPDATE_STORE_SHOP:
      return {
        ...state,
        updatingStoreShop: action.updatingStoreShop,
      };
    case CREATE_STORE:
      return {
        ...state,
        createStore: action.createStore,
        createStoreLoading: action.createStoreLoading,
        createdSuccess: action.createdSuccess,
      };
    case CREATE_NEW_STORE:
      return {
        ...state,
        createNewStore: action.createNewStore,
        createStoreLoading: action.createStoreLoading,
        createdSuccess: action.createdSuccess,
      };
    case GET_STORE_FOR_DOMAIN:
      return {
        ...state,
        storeLoading: action.storeLoading,
        store: action.store,
      };
    case RESYNC_ALL_INVENTORY:
      return {
        ...state,
        resyncAllInventoryLoading: action.resyncAllInventoryLoading,
      };
    default:
      return state;
  }
};
