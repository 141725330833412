import { api } from "../../api";
import { MembershipPayment } from "../reducers/MembershipPaymentReducer";
import { AppDispatch } from "../store";
import { GET_MEMBERSHIP_PAYMENTS } from "./types";

const getMembershipPaymentsLoading = () => ({
  type: GET_MEMBERSHIP_PAYMENTS,
  membershipPayments: [],
  membershipPaymentsLoading: true,
});

const getMembershipPaymentsError = () => ({
  type: GET_MEMBERSHIP_PAYMENTS,
  membershipPayments: [],
  membershipPaymentsLoading: false,
});

const getMembershipPaymentsSuccess = (
  membershipPayments: MembershipPayment[]
) => ({
  type: GET_MEMBERSHIP_PAYMENTS,
  membershipPayments,
  membershipPaymentsLoading: false,
});

const getMembershipPaymentsFromAPI = async (filter: any) => {
  const { data, error } = await api.provide("get", "/api/membershipPayments", {
    ...filter,
  });
  if (error) throw error;
  return (data?.membershipPayments || []) as any;
};

export const getMemmbershipPayments = (filter: any) => {
  return async (dispatch: AppDispatch) => {
    dispatch(getMembershipPaymentsLoading());
    try {
      dispatch(
        getMembershipPaymentsSuccess(await getMembershipPaymentsFromAPI(filter))
      );
    } catch (e) {
      dispatch(getMembershipPaymentsError());
    }
  };
};
