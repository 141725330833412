import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Spin, Typography, message } from "antd";
import UserInfo from "./userInfo";
import StoreInfo from "./storeInfo";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  Container,
  LeftContainer,
  RightContainer,
  ResponsiveCenter,
  CardContainer,
  TraceText,
  TextHeading,
  TextDescription,
  NavigationItem,
  ColorPTagFirst,
  ColorStoreTag,
  BarsDiv,
  SignUpTag,
  ResponsiveLayout,
  MobileTitle,
  ResponsiveText,
} from "../../../styles/formStyles";
import { createNewStore } from "../../../redux/actions/storeActions";
import { StoreState } from "../../../redux/reducers/StoreReducer";
import AuthService from "../../../services/auth";
import Cookies from "js-cookie";
import { getStoreForDomain } from "../../../redux/actions/storeActions";
import axios from "axios";

const { Content } = Layout;
const { Text } = Typography;

interface FormData {
  email?: string;
  password?: string;
  shop?: null;
  accessToken?: null;
  platform?: null;
  [key: string]: any;
}
const SignUp = () => {
  const auth = AuthService();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [step, setStep] = useState<number>(1);
  const [formData, setFormData] = useState<FormData>({});
  const onSumbit = (data: object, step: number) => {
    setFormData((prev) => ({
      ...prev,
      ...data,
      shop: null,
      accessToken: null,
      platform: null,
    }));
    setStep(step);
  };

  const loginStore = async (handle: string) => {
    if (handle !== "") {
      Cookies.remove("domain");
      window.sessionStorage.setItem("setClick", "clicked");
      if (formData.storeType === "luxury") {
        const response = await axios.get(
          `https://lux-staging.onrender.com/open/stores/${handle}.` +
            "stagingfulltrace.co"
        );
      } else {
        await dispatch(
          getStoreForDomain(
            `${handle}.` + process.env.REACT_APP_HOST ?? "fulltrace.co"
          )
        );
      }
    } else {
      message.error("Please enter a valid handle");
    }
  };
  useEffect(() => {
    const handleStoreCreation = async () => {
      if (step === 3) {
        try {
          if (formData.email && formData.password) {
            // const storeCreated = await dispatch(createNewStore(formData));
            let storeCreated;

            if (formData.storeType === "luxury") {
              // If store type is 'luxury', make an API call using axios
              const baseURL = process.env.REACT_APP_LUX_API;
              const response = await axios.post(
                "https://lux-staging.onrender.com/open/stores/createNewStore",
                formData
              );
              console.log("===response====", response);

              storeCreated = response.data;
            } else {
              // Otherwise, use the existing createNewStore function
              storeCreated = await dispatch(createNewStore(formData));
            }

            if (storeCreated && formData.storeType !== "luxury") {
              const signupSuccessful = await auth.signup(
                formData.email,
                formData.password
              );
              if (signupSuccessful) {
                loginStore(formData?.handle);
                await auth.login(formData.email, formData.password);
                message.success("Email Sent");
                await redirectToDashboard(formData.handle);
              }
            } else if (formData.storeType === "luxury") {
              loginStore(formData?.handle);
              message.success("Email Sent");
              await redirectToDashboard(formData.handle);
            } else {
              message.error("Store creation failed.");
            }
          } else {
            message.error("Email and password are required.");
          }
        } catch (error) {
          message.error("An error occurred while checking availability");
        }
      }
    };
    handleStoreCreation();
  }, [step, formData, dispatch]);

  const redirectToDashboard = (handle: string) => {
    message.success("Loading...");
    if (formData.storeType === "luxury") {
      setTimeout(() => {
        window.open(
          `https://lux-staging-admin.onrender.com/login?domain=${handle}.${
            process.env.REACT_APP_HOST ?? "fulltrace.co"
          }&string1=${formData.email}&string2=${formData.password}`,
          "_self"
        );
      }, 1500);
    } else {
      setTimeout(() => {
        window.open(
          `${process.env.REACT_APP_APP_URL}/login?domain=${handle}.${
            process.env.REACT_APP_HOST ?? "fulltrace.co"
          }`,
          "_self"
        );
      }, 1500);
    }
  };
  return (
    <Container>
      <LeftContainer>
        <CardContainer>
          <TraceText>trace</TraceText>
          <TextHeading>
            Free up your time to focus on what matters. Trace handles the rest.
          </TextHeading>
          <TextDescription>
            Figma ipsum component variant main layer. Editor <br /> boolean bold
            flatten effect. Strikethrough overflow <br /> draft arrange frame
            pen polygon select. Mask flatten.
          </TextDescription>
        </CardContainer>
      </LeftContainer>
      <RightContainer>
        <SignUpTag>Sign up</SignUpTag>
        <ResponsiveLayout>
          <Content>
            <div>
              <MobileTitle>trace</MobileTitle>
              <BarsDiv>
                <div>
                  <Text
                    style={{
                      color: step === 1 ? "#685FF9" : "#685FF9",
                    }}
                  >
                    <ColorPTagFirst>1. Personal Info</ColorPTagFirst>
                  </Text>
                  <NavigationItem
                    style={{
                      background: step === 1 ? "#685ff9" : "#685FF9",
                    }}
                  />
                </div>
                <div>
                  <Text
                    style={{
                      color: step >= 2 ? "blue" : "#685ff9",
                    }}
                  >
                    <ColorStoreTag>2. Store Info</ColorStoreTag>
                  </Text>
                  <NavigationItem
                    style={{
                      background: step >= 2 ? "#685ff9" : "#E3E2FB",
                    }}
                  />
                </div>
              </BarsDiv>
            </div>
          </Content>
        </ResponsiveLayout>
        <ResponsiveCenter>
          <ResponsiveText>
            Figma ipsum component variant main layer. Select community frame
            frame flows line.
          </ResponsiveText>
          {step === 1 ? (
            <UserInfo onFinish={onSumbit} />
          ) : (
            <StoreInfo onFinish={onSumbit} />
          )}
        </ResponsiveCenter>
      </RightContainer>
    </Container>
  );
};

export default SignUp;
