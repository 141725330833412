import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
  useParams,
} from "react-router-dom";
import firebase from "firebase";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import markerSDK from "@marker.io/browser";

//Redux Stuff
import { useAppSelector } from "../redux/hooks";
import {
  navRoutes,
  authenticatedRoutes,
  authenticatedNavItems,
  consignerAuthenticatedRoutes,
  consignerNavRoutes,
  consignerAuthenticatedNavItems,
  appRoutes,
  authenticatedNavItemsForBuying,
  navItem,
} from "./navData";
import AuthService from "../services/auth";
import { useAppDispatch } from "../redux/hooks";
import SidebarLayout from "../components/Layout/SidebarLayout";
import { Spin } from "antd";
import styled from "styled-components";
import { UserState } from "../redux/reducers/UserReducer";
import {
  getStoreForDomain,
  updateStoreShop,
} from "../redux/actions/storeActions";
import { StoreState } from "../redux/reducers/StoreReducer";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { updateConsigner } from "../redux/actions/consignerActions";
import { Consigner } from "../redux/reducers/ConsignerReducer";
import { Employee } from "../redux/reducers/EmployeeReducer";
import ConsignerNavLayout from "../components/Layout/ConsignerNavLayout";
import { setIsMobile } from "../redux/actions/appActions";
import InStore from "../instore/InStore";
import Login from "../screens/Auth/Login";
import DisabeldAccount from "../screens/Auth/DisabledAccount";
import { ScanOutlined } from "@ant-design/icons";
/*
Main Navigation Component
Two states for the component: is logged in and is not logged in
This state is determined by the isLoggedIn variable in the User Reducer (./redux/reducers/UserReducer.ts)
When logged in it will render the authenticated routes and navigation bar (found in ./nav/navData.tsx)
When not logged in it will render the routes and navigation bar (found in ./nav/navData.ts)
If you go to a authenicated route while logged off, it will redirect to /login
*/
const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
interface Props {}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  display: "flex",
  flexDirection: "column",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
};

const auth = AuthService();
export const Routes = ({}: Props): JSX.Element => {
  //global state / redux
  const { isLoggedIn, fbUser, dbUser }: UserState = useAppSelector(
    (state) => state.UserReducer
  );
  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const [authNav] = useState<navItem[]>(
    store.isBuyingStore ? authenticatedNavItemsForBuying : authenticatedNavItems
  );

  //shopify url
  const shopQuery = queryParams.get("shop");

  const [shop, setShop] = useState(shopQuery || "");

  const installShopifyApp = () => {
    if (shop && shop !== "") {
      dispatch(updateStoreShop(shop));
    } else {
      alert("Please enter your Shopify URL");
    }
  };

  const employee = { ...(dbUser as Employee) };

  return (
    <>
      {/* {isLoggedIn && !["/storeSignup"].includes(window.location.pathname) && (
        <Modal
          open={!store || !store.shop || !store.shop.accessToken}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Connect Shopify
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2, mb: 2 }}>
              Enter your shopify URL to continue
            </Typography>
            <TextField
              label="your_shop.myshopify.com"
              onChange={(e) => setShop(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={() => installShopifyApp()} color="primary">
                      Connect
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Modal>
      )} */}
      <Switch>
        {navRoutes.map((route) => {
          if (store && store.maintenanceMode === true) {
            return <Redirect key={route.path} to="/maintenance" />;
          }

          return (
            <Route key={route.path} path={route.path} exact={route.exact}>
              {route.component()}
            </Route>
          );
        })}

        {authenticatedRoutes.map((route) => {
          if (!isLoggedIn) return <Redirect key={route.path} to="/login" />;
          if (fbUser && employee !== null && employee.isActive === false)
            return <Redirect key={route.path} to="/disabledAccount" />;
          return (
            <Route key={route.path} path={route.path} exact={route.exact}>
              <SidebarLayout
                navItems={
                  [2, 8, 9, 101, 102].includes(store.id) // add test stores, trace, and safe only
                    ? [
                        ...authNav,
                        {
                          label: "Scan",
                          path: "/scanItems",
                          icon: <ScanOutlined />,
                        },
                      ]
                    : authNav
                }
                logo={store?.logo!}
                store={store!}
              >
                {route.component()}
              </SidebarLayout>
            </Route>
          );
        })}
      </Switch>
    </>
  );
};
export const InStoreRoutes = ({}: Props): JSX.Element => {
  //global state / redux
  const { isLoggedIn, fbUser, dbUser }: UserState = useAppSelector(
    (state) => state.UserReducer
  );

  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const location = useLocation();
  const dispatch = useDispatch();

  if (!isLoggedIn) return <Login />;

  const employee = { ...(dbUser as Employee) };
  if (fbUser && employee !== null && employee.isActive === false)
    return <DisabeldAccount />;

  return <InStore />;
};
export const ConsignerRoutes = ({}: Props): JSX.Element => {
  //global state / redux
  const { isLoggedIn, fbUser, dbUser }: UserState = useAppSelector(
    (state) => state.UserReducer
  );

  const { store }: StoreState = useAppSelector((state) => state.StoreReducer);
  const location = useLocation();
  const dispatch = useDispatch();

  const handleAcceptStoreTerms = async () => {
    await dispatch(
      updateConsigner(
        dbUser?.id || "",
        {
          ...(dbUser as Consigner),
          acceptedTOS: true,
          isActive: true,
          bankData: {
            accountName: "",
            accountNumber: "",
            swiftCode: "",
            ibanNumber: "",
            routingNumber: "",
            bank: "",
            bankType: "",
            email: "",
            routingType: "",
          },
        },

        false
      )
    );
    window.location.reload();
  };

  const consigner = { ...(dbUser as Consigner) };

  return (
    <>
      {store?.termsOfService &&
        isLoggedIn &&
        !["/signup"].includes(window.location.pathname) &&
        !["/login"].includes(window.location.pathname) && (
          <Dialog
            open={!dbUser?.acceptedTOS || false}
            scroll={"paper"}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            <DialogTitle id="scroll-dialog-title">
              Accept {store.name} Terms
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                {store.termsOfService}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleAcceptStoreTerms()}>
                Accept and continue
              </Button>
            </DialogActions>
          </Dialog>
        )}

      <Switch>
        {consignerNavRoutes.map((route) => {
          return (
            <Route key={route.path} path={route.path} exact={route.exact}>
              {route.component()}
            </Route>
          );
        })}
        {consignerAuthenticatedRoutes.map((route) => {
          if (!fbUser) return <Redirect key={route.path} to="/login" />;
          // if (fbUser && !fbUser.emailVerified)
          //   return <Redirect key={route.path} to="/verify" />;
          if (
            fbUser &&
            fbUser.emailVerified &&
            consigner !== null &&
            consigner.isActive === false
          )
            return <Redirect key={route.path} to="/disabledAccount" />;

          if (store && store.maintenanceMode) {
            return <Redirect key={route.path} to="/maintenance" />;
          }

          return (
            <Route key={route.path} path={route.path} exact={route.exact}>
              <ConsignerNavLayout
                navItems={consignerAuthenticatedNavItems}
                logo={store?.logo!}
                store={store!}
                consigner={consigner}
              >
                {route.component()}
              </ConsignerNavLayout>
            </Route>
          );
        })}
      </Switch>
    </>
  );
};
export default function Nav({}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const auth = AuthService();
  const location = window.location;
  const queryParams = new URLSearchParams(location.search);
  const pageRoutes = location.pathname?.split("/");

  useEffect(() => {
    const handleResize = () => {
      dispatch(setIsMobile(window.innerWidth < 768));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //global state / redux
  const { loginLoading, dbUser }: UserState = useAppSelector(
    (state) => state.UserReducer
  );
  const { storeLoading, store } = useAppSelector((state) => state.StoreReducer);

  const startFirebaseAuthListener = () => {
    //Meant to be used inside a use effect
    //starts listeners for firebase auth changes
    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged((user) => auth.onAuthStateChanged(user, dispatch));
    return () => {
      //clean up
      unsubscribe();
    };
  };

  useEffect(startFirebaseAuthListener, []);

  useEffect(() => {
    if (store && store.tenantId) {
      firebase.auth().tenantId = store.tenantId;
      Cookies.set("supportEmail", store.supportEmail);
    }
  }, [store]);

  useEffect(() => {
    if (store && dbUser) {
      if (store.id !== dbUser.storeId) {
        auth.logout();
      }
    }
  }, [store, dbUser]);

  useEffect(() => {
    //set domain from cookies, if not set from query params
    let domain = queryParams.get("domain") || Cookies.get("domain");
    let host = location.host;
    if (pageRoutes?.[1] === "catalogue") {
      // added for catalogue page
      domain = `${pageRoutes?.[2]}.${process.env.REACT_APP_HOST}`; // new env variable for direct link to store's catalogue
    } else if (domain && domain !== "localhost:3000") {
      Cookies.set("domain", domain);
    } else {
      // check first if this is an instore subdomain
      const instoreCheck = window.location.host.split(".");
      if (instoreCheck[0] === "instore") {
        domain = instoreCheck.slice(1).join(".");
      } else {
        //if params has no cookies, or query params
        domain = window.location.host;
      }
    }
    dispatch(getStoreForDomain(domain));
  }, []);

  useEffect(() => {
    //set the tab title
    const prevTitle = document.title;
    document.title = store?.name! || "Trace";
    return () => {
      document.title = prevTitle;
    };
  }, [store]);

  useEffect(() => {
    //set the tab favicon
    var faviconElement = document.getElementById("favicon");
    faviconElement?.setAttribute("href", store?.logo || "");
  }, [store]);

  //Add marker data
  const loadMarkerWidget = async (dbUser: Consigner | Employee) => {
    const widget = markerSDK.loadWidget({
      destination: "642cbaff8b36b6e14f16589c", // Our markup destination id
      reporter: {
        email: dbUser && dbUser.email ? dbUser.email : "noemail@jointrace.co",
        fullName: `${dbUser.firstName} ${dbUser.lastName}`,
      },
    });
  };
  useEffect(() => {
    if (dbUser) {
      loadMarkerWidget(dbUser);
    }
    // right now, force all instore accounts to the instore app page
  }, [dbUser]);

  if (loginLoading || storeLoading) {
    return (
      <Container data-testid="loading">
        <Spin />{" "}
      </Container>
    );
  }

  if (!store) {
    return (
      <Router>
        <Switch>
          {appRoutes.map((route) => {
            return (
              <Route key={route.path} path={route.path} exact={route.exact}>
                {route.component()}
              </Route>
            );
          })}
          {!["catalogue"].includes(pageRoutes?.[1]) && (
            <Redirect to="/storeSignup" />
          )}
        </Switch>
      </Router>
    );
  }

  return (
    <Router>
      {process.env.REACT_APP_TYPE === "consigner" ? (
        <ConsignerRoutes />
      ) : process.env.REACT_APP_TYPE === "instoreapp" ? (
        <InStoreRoutes />
      ) : (
        <Routes />
      )}
    </Router>
  );
}
