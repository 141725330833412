import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, Collapse, Image, Spin } from "antd";
import { Inventory } from "../../redux/reducers/InventoryReducer";
import { Store } from "../../redux/reducers/StoreReducer";
import styled from "styled-components";
import {
  CaretDownFilled,
  CaretDownOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
} from "@ant-design/icons";
import getSymbolFromCurrency from "currency-symbol-map";
import { inventories } from "../../constants/TestData";
import { ColumnTypes } from "../../consignerScreens/ConsignerInventory";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { getLowestPriceForVariantFromAPI } from "../../redux/actions/inventoryActions";
import { useAppSelector } from "../../redux/hooks";
import { InventoryPageTypes } from "../../screens/Inventory";
import moment from "moment";

// Styled components
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  width: 100%;
  @media (max-width: 768px) {
    border-radius: 4px;
    border: 1px solid #ddd;
    order: 2;
  }
`;

const ProductRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ProductImageContainer = styled.div`
  flex: 0 0 133px;
  width: auto;
  display: flex;
  padding: 10px;
  align-self: stretch;
  justify-content: center;
  align-items: center;
  position: relative;
  .ant-image {
    object-fit: contain;
    max-height: 100%;
    flex: 1;
  }
  .ant-checkbox-wrapper {
    position: absolute;
    flex: 0 0 auto;
    top: 10px;
    left: 0;
    z-index: 2;
  }
  @media (max-width: 768px) {
    flex: 0 0 77px;
  }
`;

const ProductDetails = styled.div`
  flex: 1;
  padding: 13px 16px;
  gap: 18px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  border-right: 1px solid #ddd;
  &.pricing {
    & > div {
      &:not(.Requested) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &.center {
    align-items: center;
  }
  &:first-of-type {
    border-left: 1px solid #ddd;
  }
  b {
    align-self: stretch;
    flex: 1;
    a {
      color: inherit;
      white-space: nowrap;
    }
  }
  & > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 40px;
    justify-content: space-between;
    span {
      width: 90px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end; // space-between;
      p {
        margin: 0 0 3px;
        color: #292d32;
      }
      label {
        color: var(--Text---Grey, #989898);
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 21px */
      }
    }
  }
  @media (max-width: 768px) {
    border-bottom: 1px solid #ddd;
    border-right: none;
    font-size: 10px;
    b {
      font-size: inherit;
    }
    & > div {
      font-size: inherit;
      gap: 16px;
      span {
        justify-content: flex-start;
        font-size: inherit;
        label {
          font-size: inherit;
        }
      }
    }
    &.center {
      align-items: flex-start;
    }
  }
`;

const PricingDetails = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ActionButtons = styled.div`
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  div {
    display: flex;
    gap: 11px;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    // padding: 11px 19px;
    align-self: stretch;
    .ant-btn {
      color: #232323;
    }
  }
  @media (max-width: 768px) {
    flex: 0 0 auto;
    padding: 0 8px;
    justify-content: flex-end;
    // div {
    //   flex-direction: row;
    //   justify-content: space-between;
    // }
    .ant-btn {
      // min-width: 32px;
      max-width: 32px;
      max-height: 32px;
      font-size: 16px;
      height: 32px;
      flex-shrink: 0;
      border-radius: 8px;
      background: #b2c7eb;
      &.ant-btn-ghost {
        font-size: 16px;
      }
      span:not(.anticon) {
        display: none;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 8px;
  width: 130px;
  max-height: 32px;
  flex-shrink: 0;
  flex: 1;
  text-transform: capitalize;
  &.ant-btn-ghost {
    display: flex;
    padding: 11.5px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    flex-grow: 0;
    border-radius: 8px;
    border: 1px solid var(--Line, #ddd);
    background: var(--White, #fff);
    color: var(--Text---Grey, #989898);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &.ant-btn-dangerous {
    background-color: transparent;
    color: initial;
  }
  &:not(.ant-btn-primary) {
    border-radius: 8px;
    // background: var(--Grey---Button-Fill, #f4f4f4);
  }
  @media (max-width: 768px) {
    max-height: 50px;
    padding: 0;
  }
`;

export const ProductRowMobile = styled.div`
  padding-top: 13px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
`;

const ProductRowHidden = styled.span`
  display: none;
  padding: 16px 10px;
  justify-content: space-evenly;
  border-top: 1px solid #ddd;
  &.expanded {
    display: flex;
  }
  &:first-of-type {
    border-bottom: 1px solid #ddd;
  }
  div {
    display: flex;
    gap: 13px;
  }
  b {
    font-weight: 500;
    font-size: 10px;
  }
  label {
    color: var(--Text---Grey, #989898);
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 15px */
  }
`;

export const ProductRowMobileTop = styled.span`
  flex: 1;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px;
  .ant-checkbox-wrapper {
    align-self: start;
    position: absolute;
  }
  & > div {
    display: flex;
    & > b {
      font-weight: 500;
      margin-bottom: 13px;
    }
    span {
      div {
        b {
          font-weight: 500;
        }
        label {
          color: var(--Text---Grey, #989898);
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%; /* 15px */
        }
      }
    }
  }
`;

const InventoryCardMobileView = ({
  dataSource,
  columnSrc,
  priceMap,
  status,
}: {
  dataSource: Inventory[];
  columnSrc: any[];
  priceMap: any[];
  status: any;
}) => {
  const { store } = useAppSelector((state) => state.StoreReducer);
  const [expandedKey, setExpandedKey] = useState(-1);
  console.log("====dataSource====", dataSource);

  return (
    <Wrapper>
      {dataSource.map((inventory, key) => (
        <ProductRowMobile key={key}>
          <ProductRowMobileTop>
            {columnSrc
              .find((col) => col.dataIndex === "id")
              ?.render(inventory.id, inventory)}
            <Image
              src={inventory.product?.image}
              width={77}
              alt=""
              style={{ flex: "0 0 77px" }}
            />
            <div style={{ flex: 1, flexDirection: "column", fontSize: 12 }}>
              <b style={{ flex: 1 }}>{inventory.product?.title}</b>
              <div style={{ display: "flex", flex: 1 }}>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>{inventory.product?.sku}</b>
                    <label>SKU</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>
                      {getSymbolFromCurrency(store.currency)}
                      {inventory.price}
                    </b>
                    {inventory?.requestedPrice && (
                      <b style={{ color: "green", display: "block" }}>
                        {getSymbolFromCurrency(store.currency)}
                        {inventory.requestedPrice}
                      </b>
                    )}
                    <label>Price</label>
                  </div>
                  {status === InventoryPageTypes.Requested && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <b>
                        {priceMap.find(
                          (rec) =>
                            rec.key ===
                            `${inventory.option1Value}-${inventory?.option2Value}-${inventory?.option3Value}-${inventory?.location}-${inventory?.storeId}-${inventory?.product?.id}`.replace(
                              /[\s]/g,
                              ""
                            )
                        )?.qty ?? inventory.qty}
                      </b>
                      <label>Current stock</label>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    {/* <b>
                      {inventory.consigner?.firstName}{" "}
                      {inventory.consigner?.lastName}
                    </b> */}
                    <label>
                      {store.isBuyingStore ? "Seller" : "Consigner"}
                    </label>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <b>{inventory.option2Value}</b>
                    <label>Condition</label>
                  </div> */}
                </span>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 16,
                    flex: 1,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>{inventory.option1Value}</b>
                    <label>Size</label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>
                      {getSymbolFromCurrency(store.currency)}
                      {inventory.payoutAmount
                        ? Number(inventory.payoutAmount).toFixed(1)
                        : "0"}
                    </b>
                    <label>Payout</label>
                  </div>
                  {status === InventoryPageTypes.Requested && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        flex: 1,
                      }}
                    >
                      <b>
                        {getSymbolFromCurrency(store.currency)}
                        {priceMap.find(
                          (rec) =>
                            rec.key ===
                            `${inventory.option1Value}-${inventory?.option2Value}-${inventory?.option3Value}-${inventory?.location}-${inventory?.storeId}-${inventory?.product?.id}`.replace(
                              /[\s]/g,
                              ""
                            )
                        )?.price ?? inventory.price}
                      </b>
                      <label>Current lowest price</label>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      flex: 1,
                    }}
                  >
                    <b>{inventory.location ?? inventory.option3Value}</b>
                    <label>Location</label>
                  </div>
                </span>
              </div>
            </div>
            <ActionButtons>
              {columnSrc
                .find((col) => col.dataIndex === "operation")
                ?.render("", inventory)}
            </ActionButtons>
          </ProductRowMobileTop>
        </ProductRowMobile>
      ))}
    </Wrapper>
  );
};

interface InventoryCardViewProps {
  inventories: Inventory[];
  store: Store;
  loading: boolean;
  columnSrc: any[];
  nestedView?: boolean;
  groupedData?: any[];
  priceMap: { price: number; qty: number; key: string }[];
  setPriceMap: any;
  setShowAcceptInventoryModal?: any;
  setSelectedRowKeys?: any;
  setShowRequestedInventoryModal?: any;
  status?: number;
  supplyOnly?: boolean;
}

const InventoryCardView = (data: InventoryCardViewProps) => {
  const {
    inventories: dataSource,
    loading,
    store,
    columnSrc,
    nestedView,
    groupedData,
    priceMap,
    setPriceMap,
    status,
    setSelectedRowKeys,
    setShowAcceptInventoryModal,
    setShowRequestedInventoryModal,
    supplyOnly,
  } = data;
  const [activeKey, setActiveKey] = useState("");
  const { isMobile } = useAppSelector((state) => state.AppReducer);
  const [fetchOngoing, setFetchOngoing] = useState(false);
  const [dataSourceLoading, setDataSourceLoading] = useState(false);

  const fetchLowestPricesAndQtyOfVariants = async (data: Inventory[]) => {
    setDataSourceLoading(true);
    setFetchOngoing(true);
    let prcMap = [...priceMap];
    const products: any = [];
    for (const inventoryToReturn of data) {
      var key = `${inventoryToReturn.option1Value}-${
        inventoryToReturn?.option2Value
      }-${inventoryToReturn?.option3Value}-${inventoryToReturn?.location}-${
        inventoryToReturn?.storeId
      }-${
        inventoryToReturn?.productId ?? inventoryToReturn?.product.id
      }`.replace(/[\s]/g, "");
      const item = {
        option1Value: inventoryToReturn?.option1Value ?? "",
        option2Value: inventoryToReturn?.option2Value ?? undefined,
        option3Value: inventoryToReturn?.option3Value ?? undefined,
        location: inventoryToReturn?.location ?? "",
        productId: Number(inventoryToReturn?.product?.id) ?? 0,
        key,
      };
      products.push(item);
    }
    const dataFound = prcMap.find((data) => data.key === key);
    if (dataFound === undefined || !dataFound) {
      const lowestPriceData = await getLowestPriceForVariantFromAPI({
        products,
      });
      prcMap = [...prcMap, ...lowestPriceData.result];
    }
    dataSource.forEach((inventory) => {
      const foundRecord = priceMap.find((rec) => rec.key === key);
      inventory.priceMapQty = foundRecord?.qty ?? inventory.qty;
    });

    setPriceMap(prcMap);
    setFetchOngoing(false);
    setDataSourceLoading(false);
  };

  useEffect(() => {}, [priceMap]);

  useEffect(() => {
    if (
      !dataSource.length ||
      nestedView ||
      status !== InventoryPageTypes.Requested ||
      fetchOngoing
    ) {
      return;
    } else fetchLowestPricesAndQtyOfVariants(dataSource);
  }, [dataSource, nestedView, status, fetchOngoing]);

  const CollapseHeader = (record: any) => {
    const {
      activeKey,
      image,
      title,
      keyItem,
      quantity,
      sku,
      inventories,
      consignerName,
      consigner_buy,
    } = record;
    const expandIcon =
      activeKey === keyItem ? (
        <CaretDownOutlined style={{ flex: "0 0 30px" }} />
      ) : (
        <CaretRightOutlined style={{ flex: "0 0 30px" }} />
      );
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {expandIcon}
        {status === InventoryPageTypes.Find && (
          <img
            height={52.5}
            src={image}
            width={52.5}
            style={{ flex: 1, objectFit: "contain" }}
          />
        )}
        <span
          style={{
            flex: 3,
            fontSize: 14,
            fontWeight: 600,
            lineHeight: "150%",
          }}
        >
          {supplyOnly ? (
            <>
              {consigner_buy ? (
                <>
                  {`Batch # ${keyItem}`}
                  <span style={{ margin: "0 20px" }}>{consignerName}</span>
                </>
              ) : (
                <>
                  {`Batch # ${keyItem}`}
                  <span style={{ margin: "0 20px" }}>Trace</span>
                </>
              )}
            </>
          ) : (
            title
          )}
        </span>
        {status === InventoryPageTypes.Find && (
          <span
            style={{
              flex: 2,
              fontSize: 14,
              fontWeight: 600,
              lineHeight: "150%",
            }}
          >
            {sku}
          </span>
        )}
        <span
          style={{
            flex: "0 0 auto",
            padding: "4px 8px 4px 0",
            fontWeight: 600,
            fontSize: 12,
          }}
        >
          {quantity} Units
        </span>
        <span>
          {status &&
          [InventoryPageTypes.Incoming, InventoryPageTypes.Requested].includes(
            status
          ) ? (
            <StyledButton
              onClick={() => {
                setSelectedRowKeys(
                  inventories.map((inventory) => inventory.id)
                );
                if (status === InventoryPageTypes.Incoming)
                  setShowAcceptInventoryModal(true);
                else setShowRequestedInventoryModal(true);
              }}
            >
              Accept All
            </StyledButton>
          ) : (
            ""
          )}
        </span>
      </div>
    );
  };

  const sortSizes = (sizes: Inventory[]) => {
    const sizeOrder = ["XXS", "XS", "S", "M", "L", "XL", "XXL"];
    return sizes.sort((a, b) => {
      if (
        Number(a.option1Value.replace(/[^0-9.]/g, "")) ||
        Number(b.option1Value.replace(/[^0-9.]/g, ""))
      ) {
        return (
          a.option1Value.replace(/[^0-9.]/g, "") -
          b.option1Value.replace(/[^0-9.]/g, "")
        );
      }
      // Remove any country prefix and trim whitespace
      const sizeA = a.option1Value.split(" ").pop();
      const sizeB = b.option1Value.split(" ").pop();

      // Get the index of sizes from the size order
      const indexA = sizeOrder.indexOf(sizeA);
      const indexB = sizeOrder.indexOf(sizeB);

      // Compare the index to sort
      return indexA - indexB;
    });
  };

  const handleCollapseChange = (key) => {
    // console.log(key);
    const dataSrc = dataSource.find((data) => data.key === key);
    if (status == InventoryPageTypes.Requested) {
      fetchLowestPricesAndQtyOfVariants(dataSrc?.inventories);
    }
    setActiveKey(key);
  };
  if (loading || dataSourceLoading) return <Spin />;
  else if (nestedView && groupedData)
    return (
      <Wrapper>
        <Collapse
          bordered={false}
          accordion
          expandIcon={() => null}
          style={{ display: "flex", flexDirection: "column", gap: 16 }}
          onChange={handleCollapseChange}
        >
          {groupedData?.map((data, key) => {
            1;
            const { inventories } = data;
            return (
              <CollapsePanel
                style={{
                  borderRadius: 8,
                  border: "0.5px solid var(--Line, #DDD)",
                }}
                key={`${data.key}`}
                header={
                  <CollapseHeader
                    keyItem={`${data?.productId}`}
                    activeKey={activeKey}
                    image={data?.productImage}
                    title={data?.productTitle}
                    sku={data?.productSku}
                    consigner_buy={data?.supplyDetails?.consigner_buy}
                    inventories={inventories}
                    consignerName={
                      data?.consigner?.firstName +
                      " " +
                      data?.consigner?.lastName
                    }
                    quantity={inventories?.length ?? 1}
                  />
                }
              >
                {sortSizes(inventories).map((inventory, recordKey) => {
                  let rangeUsed = false;
                  const { storeRequestPriceRange } = inventory;
                  if (
                    storeRequestPriceRange &&
                    storeRequestPriceRange[1] &&
                    storeRequestPriceRange[0]
                  ) {
                    rangeUsed = true;
                  }
                  return (
                    <ProductRow key={inventory.id}>
                      <ProductImageContainer>
                        {columnSrc
                          .find((col) => col.dataIndex === "id")
                          ?.render(inventory.id, inventory)}
                        <Image src={inventory.product.image} alt="" />
                      </ProductImageContainer>
                      <ProductDetails style={{ flex: 3 }}>
                        <b>
                          {inventory.product.title}{" "}
                          <a style={{ display: "block" }}>{inventory.code}</a>
                        </b>
                        <div>
                          <span>
                            <p>
                              {inventory.product.sku?.slice(0, 12)}
                              {inventory.product?.sku.length > 12
                                ? "..."
                                : null}
                            </p>
                            <label>SKU</label>
                          </span>
                          <span>
                            <p>{inventory.option2Value}</p>
                            <label>Condition</label>
                          </span>
                          <span>
                            <p>
                              {inventory.location ?? inventory.option3Value}
                            </p>
                            <label>Location</label>
                          </span>
                          <span>
                            <p>{inventory.option1Value}</p>
                            <label>Size</label>
                          </span>
                        </div>
                      </ProductDetails>
                      <ProductDetails
                        className="pricing"
                        style={{ flex: "0 0 auto" }}
                      >
                        {["Paid", "Sold"].includes(inventory.status) && (
                          <div className="Requested">
                            <span>
                              <p>{inventory.orderId}</p>
                              <label>Order ID</label>
                            </span>
                            <span>
                              <p>
                                {moment(inventory?.soldOn).format("YY/MM/DD")}
                              </p>
                              <label>Sold Date</label>
                            </span>
                          </div>
                        )}
                        {status === InventoryPageTypes.Requested ? (
                          <div className="Requested">
                            <span>
                              <p>
                                {priceMap.find(
                                  (rec) =>
                                    rec.key ===
                                    `${inventory.option1Value}-${inventory?.option2Value}-${inventory?.option3Value}-${inventory?.location}-${inventory?.storeId}-${inventory?.product.id}`.replace(
                                      /[\s]/g,
                                      ""
                                    )
                                )?.qty ?? inventory.qty}
                              </p>
                              <label>In Stock</label>
                            </span>
                            <span>
                              <p>
                                {getSymbolFromCurrency(store.currency)}
                                {priceMap.find(
                                  (rec) =>
                                    rec.key ===
                                    `${inventory.option1Value}-${inventory?.option2Value}-${inventory?.option3Value}-${inventory?.location}-${inventory?.storeId}-${inventory?.product.id}`.replace(
                                      /[\s]/g,
                                      ""
                                    )
                                )?.price ?? inventory.price}
                              </p>
                              <label>Lowest Price</label>
                            </span>
                          </div>
                        ) : null}
                        <div
                          className={
                            status === InventoryPageTypes.Requested
                              ? "Requested"
                              : ""
                          }
                        >
                          <span>
                            <p>
                              {getSymbolFromCurrency(store.currency)}
                              {rangeUsed
                                ? `${storeRequestPriceRange[0]} - ${storeRequestPriceRange[1]}`
                                : inventory.price}
                              {inventory?.requestedPrice && (
                                <b style={{ color: "green", display: "block" }}>
                                  {getSymbolFromCurrency(store.currency)}
                                  {inventory.requestedPrice}
                                </b>
                              )}
                            </p>
                            <label>Price</label>
                          </span>
                          <span>
                            <p>
                              {getSymbolFromCurrency(store.currency)}
                              {inventory.payoutAmount
                                ? Number(inventory.payoutAmount).toFixed(1)
                                : "0"}
                            </p>
                            <label>Payout</label>
                          </span>
                        </div>
                      </ProductDetails>
                      <ProductDetails className="center">
                        <div>
                          <span>
                            <p>
                              {inventory?.consigner?.firstName}{" "}
                              {inventory?.consigner?.lastName}
                            </p>
                            <label>
                              {store.isBuyingStore ? "Seller" : "Consigner"}
                            </label>
                          </span>
                        </div>
                        <div>
                          <span>
                            <p>
                              {["Buying", "Consignment"].includes(
                                inventory?.status
                              ) ||
                              (store.isBuyingStore && inventory.buyingId)
                                ? inventory.status === "Consignment"
                                  ? "Consignment"
                                  : "Buying"
                                : ""}
                            </p>
                            <p>{inventory.status}</p>
                          </span>
                        </div>
                      </ProductDetails>
                      {columnSrc.find(
                        (col) => col.dataIndex === "operation"
                      ) && (
                        <ActionButtons>
                          {columnSrc
                            .find((col) => col.dataIndex === "operation")
                            ?.render("", inventory)}
                        </ActionButtons>
                      )}
                    </ProductRow>
                  );
                })}
              </CollapsePanel>
            );
          })}
        </Collapse>
      </Wrapper>
    );
  return isMobile ? (
    <InventoryCardMobileView
      dataSource={dataSource}
      columnSrc={columnSrc}
      priceMap={priceMap}
      status={status}
    />
  ) : (
    <Wrapper>
      {dataSource.map((inventory, key) => {
        let rangeUsed = false;
        const { storeRequestPriceRange } = inventory;
        if (
          storeRequestPriceRange &&
          storeRequestPriceRange[1] &&
          storeRequestPriceRange[0]
        ) {
          rangeUsed = true;
        }
        const acceptedOnDate = moment(inventory?.acceptedOn ?? new Date());
        let current = moment();
        const daysSinceAccept = current.diff(acceptedOnDate, "days");
        return (
          <ProductRow key={key}>
            <ProductImageContainer>
              {columnSrc
                .find((col) => col.dataIndex === "id")
                ?.render(inventory.id, inventory)}
              <Image src={inventory.product?.image} alt="" />
            </ProductImageContainer>
            <ProductDetails style={{ flex: 3 }}>
              <b>
                {inventory.product?.title}{" "}
                <a style={{ display: "block" }}>{inventory.code}</a>
              </b>
              <div>
                <span>
                  <p>
                    {inventory.product?.sku?.slice(0, 12)}
                    {inventory.product?.sku.length > 12 ? "..." : null}
                  </p>
                  <label>SKU</label>
                </span>
                <span>
                  <p>{inventory.option2Value}</p>
                  <label>Condition</label>
                </span>
                <span>
                  <p>{inventory.location ?? inventory.option3Value}</p>
                  <label>Location</label>
                </span>
                <span>
                  <p>{inventory.option1Value}</p>
                  <label>Size</label>
                </span>
                {daysSinceAccept >= 0 && inventory.status === "Active" && (
                  <span>
                    <p>
                      {inventory.acceptedOn ? daysSinceAccept + " Days" : ""}
                    </p>
                    <label>Days Active</label>
                  </span>
                )}
              </div>
            </ProductDetails>
            <ProductDetails className="pricing" style={{ flex: "0 0 auto" }}>
              {["Paid", "Sold"].includes(inventory.status) && (
                <div className="Requested">
                  <span>
                    <p>{inventory.orderId}</p>
                    <label>Order ID</label>
                  </span>
                  <span>
                    <p>{moment(inventory?.soldOn).format("YY/MM/DD")}</p>
                    <label>Sold Date</label>
                  </span>
                </div>
              )}
              {status === InventoryPageTypes.Requested ? (
                <div className="Requested">
                  <span>
                    <p>
                      {priceMap.find(
                        (rec) =>
                          rec.key ===
                          `${inventory.option1Value}-${inventory?.option2Value}-${inventory?.option3Value}-${inventory?.location}-${inventory?.storeId}-${inventory?.product?.id}`.replace(
                            /[\s]/g,
                            ""
                          )
                      )?.qty ?? inventory.qty}
                      {/* {inventory.priceMapQty ?? inventory.qty} */}
                    </p>
                    <label>In Stock</label>
                  </span>
                  <span>
                    <p>
                      {getSymbolFromCurrency(store.currency)}
                      {priceMap.find(
                        (rec) =>
                          rec.key ===
                          `${inventory.option1Value}-${inventory?.option2Value}-${inventory?.option3Value}-${inventory?.location}-${inventory?.storeId}-${inventory?.product?.id}`.replace(
                            /[\s]/g,
                            ""
                          )
                      )?.price ?? inventory.price}
                    </p>
                    <label>Lowest Price</label>
                  </span>
                </div>
              ) : null}
              <div
                className={
                  status === InventoryPageTypes.Requested ||
                  ["Paid", "Sold"].includes(inventory.status)
                    ? "Requested"
                    : ""
                }
              >
                <span>
                  <p>
                    {getSymbolFromCurrency(store.currency)}
                    {rangeUsed
                      ? `${storeRequestPriceRange[0]} - ${storeRequestPriceRange[1]}`
                      : inventory.price}
                    {inventory?.requestedPrice && (
                      <b style={{ color: "green", display: "block" }}>
                        {getSymbolFromCurrency(store.currency)}
                        {inventory.requestedPrice}
                      </b>
                    )}
                  </p>
                  <label>Price</label>
                </span>
                <span>
                  <p>
                    {getSymbolFromCurrency(store.currency)}
                    {inventory.payoutAmount
                      ? Number(inventory.payoutAmount).toFixed(1)
                      : "0"}
                  </p>
                  <label>Payout</label>
                </span>
              </div>
            </ProductDetails>
            <ProductDetails className="center">
              <div>
                <span>
                  <p>
                    {inventory?.consigner?.firstName}{" "}
                    {inventory?.consigner?.lastName}
                  </p>
                  <label>{store.isBuyingStore ? "Owner" : "Consigner"}</label>
                </span>
              </div>
              <div>
                <span>
                  <p>
                    {["Buying", "Consignment", "Requested", "Pending"].includes(
                      inventory?.status
                    ) ||
                    (store.isBuyingStore && inventory.buyingId)
                      ? inventory.status === "Consignment"
                        ? "Consignment"
                        : "Buying"
                      : ""}
                  </p>
                  <p>{inventory.status}</p>
                </span>
              </div>
            </ProductDetails>
            {columnSrc.find((col) => col.dataIndex === "operation") && (
              <ActionButtons>
                {columnSrc
                  .find((col) => col.dataIndex === "operation")
                  ?.render("", inventory)}
              </ActionButtons>
            )}
          </ProductRow>
        );
      })}
    </Wrapper>
  );
};

export default InventoryCardView;
