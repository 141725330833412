import React, { useEffect, useState } from "react";
import { useHistory, RouteComponentProps } from "react-router";
import Cookies from "js-cookie";

import styled from "styled-components";
import { Redirect, useParams } from "react-router";
import { Button, Card, Spin } from "antd";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Consigner, ConsignerState } from "../redux/reducers/ConsignerReducer";
import { AppDispatch } from "../redux/store";
import Colors from "../constants/Colors";
import AuthService, { Auth } from "../services/auth";
import { BankData } from "../screens/ConsignerItem";
import logo from "../assets/images/logo.png";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import ConsignerSignupForm from "./ConsignerSignupForm";
import Fonts from "../constants/Fonts";
import RedesignStyling from "../constants/RedesignStyling";

/**
 * Inventory Item Screen
 * renders a screen where user can edit a singular inventory item
 * TODO Tests:
 *  -
 * @returns
 */

//styles
const Container = styled.div`
  font-family: ${Fonts.CONSIGNER};
  background: ${Colors.CONSIGNER_BG};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // height: 100vh;
  padding: ${RedesignStyling.PAGE_PADDING};
  margin-top: 60px;
  @media (max-width: 768px) {
    padding: ${RedesignStyling.PAGE_PADDING_MOBILE};
  }
`;
const SignupContainer = styled.div`
  width: 472px;
  max-width: 100%;
`;

const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
`;

const TOSContainer = styled.div`
  color: #2e2e2e;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
`;

const TopBarGeneric = styled.div`
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${Colors.WHITE};
  position: fixed;
  top: 0;
`;
const Logo = styled.img`
  height: 40px;
  width: auto;
`;
const Header = styled.div`
  color: var(--heading-color, #2e2e2e);
  text-align: center;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  line-height: 62px;
  letter-spacing: -2px;
  width: 100%;
`;
const Subheader = styled.div`
  color: ${Colors.CONSIGNER_GREY};
  text-align: center;
  font-family: ${Fonts.CONSIGNER};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  width: 100%;
`;

export interface NewConsignerFormValues {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  phone: string;
  idNumber: string;
  accountName: string;
  bank: string | undefined;
  accountNumber: string;
  branchCode: string;
  bankType: string | undefined;
  bankData: BankData;
  fbUserId: string;
  accountType: string;
  payoutData: string;
  payoutType: string;
  storeId: string;
  isActive?: boolean;
}

export const signupConsigner = async (
  dispatch: AppDispatch,
  auth: Auth,
  consigner: Consigner
) => {
  //signup
  Cookies.set("newConsigner", JSON.stringify(consigner));
  await auth.signup(consigner.email, consigner.password || "");
};

const Signup = () => {
  const dispatch = useAppDispatch();
  const auth = AuthService();

  //state
  const { isLoggedIn } = useAppSelector((state) => state.UserReducer);
  const { store } = useAppSelector((state) => state.StoreReducer);
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [storeId, setStoreId] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [showTOSModal, setShowTOSModal] = useState(false);
  if (isLoggedIn) return <Redirect data-testid="dashboardRedirect" to="/" />;

  useEffect(() => {
    if (store!.id) {
      setStoreId(store!.id);
    }
  }, []);

  return (
    <Container>
      <TopBarGeneric>
        <Logo
          src={store.logo}
          alt=""
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = logo;
          }}
        />
      </TopBarGeneric>
      <SignupContainer>
        <FormContainer>
          <Header>Create Free Account</Header>
          <Subheader>
            Sign up with trace to start managing your inventory in a go with our
            easy to use dashboard
          </Subheader>
          <ConsignerSignupForm
            showPasswordInput={true}
            storeId={store!.id}
            email={email}
            password={password}
            confirm={confirm}
            firstName={firstName}
            lastName={lastName}
            phone={phone}
            idNumber={idNumber}
            setEmail={setEmail}
            setPassword={setPassword}
            setConfirm={setConfirm}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setPhone={setPhone}
            setIdNumber={setIdNumber}
            setStoreId={setStoreId}
          />
          <TOSContainer>
            I agree with the{" "}
            <a onClick={() => setShowTOSModal(true)}>Terms & Conditions</a> of
            Trace
          </TOSContainer>
          <Button
            style={{
              display: "flex",
              width: "100%",
              height: 65,
              padding: 16,
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
              marginBottom: 10,
              textAlign: "center",
              fontSize: 16,
              fontWeight: 600,
              lineHeight: 28,
            }}
            type="primary"
            disabled={confirm !== password || password === ""}
            onClick={() =>
              signupConsigner(dispatch, auth, {
                email,
                firstName,
                lastName,
                phone,
                id: "",
                password,
                storeId,
              })
            }
          >
            Sign Up
          </Button>
        </FormContainer>
      </SignupContainer>
      <Dialog
        open={store?.termsOfService && showTOSModal}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          Accept {store.name} Terms
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
            {store.termsOfService}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowTOSModal(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Signup;
